import React from 'react';
import { Redirect, Route } from 'react-router';

export default function IsomorphicRedirect(props: { to: string }) {
  if (process.env.BROWSER) {
    return <Redirect to={props.to} />;
  }
  return (
    <Route
      render={({ staticContext }: any) => {
        if (staticContext) {
          staticContext.status = 301;
          staticContext.redirect = props.to;
        }
        return null;
      }}
    />
  );
}
