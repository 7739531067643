import React from 'react';
import withStyles from 'utils/withStyles';
import cx from 'classnames';

import s from './Card.css';

const Card = (props: React.ComponentProps<'div'>) => {
  const { className, children, ...otherProps } = props;
  return (
    <div className={cx(s.container, className)} {...otherProps}>
      {children}
    </div>
  );
};

export default withStyles(s)(Card);
