import React from 'react';
import useReactRouter from 'use-react-router';
import Footer from 'components/common/Footer/Footer';

import { useUser } from 'hooks';
import PreloaderScreen from '../components/common/PreloaderScreen';
import IsomorphicRedirect from '../components/common/IsomorphicRedirect/IsomorphicRedirect';

export default function withAuth(Cmp: any) {
  function WithAuthCmp(props: any) {
    const { location } = useReactRouter();
    const user = useUser();

    if (user === false) {
      return <PreloaderScreen />;
    }

    if (user === null) {
      const search = `?backUrl=${encodeURIComponent(location.pathname)}`;

      return <IsomorphicRedirect to={`/auth/signin?${search}`} />;
    }

    return (
      <>
        <div>
          <Cmp {...props} />
        </div>
        <Footer />
      </>
    );
  }
  return WithAuthCmp;
}
