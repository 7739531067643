import React from 'react';
import styled from 'ui/styled';

export const fontSizes = {
  xxl: '1.8rem',
  xl: '1.5rem',
  l: '1.25rem',
  m: '1.15rem',
  s: '1.1rem',
  xs: '1rem',
  xss: '0.9rem',
};

export const colorMappings = {
  darkest: '#11253B',
  dark: '#203449',
  slightlyDark: '#2D455D',
  middle: '#3F5A76',
  midMiddle: '#57728D',
  light: '#778FA8',
  lighter: '#95AAC1',
  white: '#B4C5D7',
  whiter: '#D4DEE9',
  whitest: '#EEF2F7',
};

export type TextProps = {
  className?: string;
  color?: keyof typeof colorMappings;
  size?: keyof typeof fontSizes;
  weight?: number;
  children?: any;
};

const Root = styled('span', {
  shouldForwardProp: propName => !['textColor', 'fontSize'].includes(propName),
})<{ textColor: string; fontSize: string; weight: number }>`
  color: ${props => props.textColor};
  font-size: ${props => props.fontSize || 'inherit'};
  line-height: 1.3;
  ${({ weight }) => (weight ? `font-weight: ${weight};` : '')}
`;

export function Text({
  color, size, weight, ...otherProps
}: TextProps) {
  const outerColor = color ? colorMappings[color] : colorMappings.dark;
  const fontSize = size ? fontSizes[size] : 'inherit';

  return (
    <Root
      weight={weight}
      textColor={outerColor}
      fontSize={fontSize}
      {...otherProps}
    />
  );
}
