// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DO7bq{box-sizing:border-box;max-width:1160px;max-width:var(--footer-width);width:100%;padding:56px 20px 56px 15px;margin:0 auto}.jNrnG{font-size:1.2rem;line-height:1.5;display:flex;flex-direction:column;}.jNrnG li+li{margin-top:5px}@media (max-width: 767.98px){.jNrnG{flex-direction:column;}}.qYLcW{-webkit-text-decoration:none;-webkit-text-decoration:none;text-decoration:none;opacity:.8;color:#203449;color:var(--body-text-color)}.qYLcW:hover{opacity:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `DO7bq`,
	"links": `jNrnG`,
	"link": `qYLcW`
};
export default ___CSS_LOADER_EXPORT___;
