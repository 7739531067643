import React from 'react';
import withStyles from 'utils/withStyles';
import cx from 'classnames';

import s from './Content.css';

interface Props {
  children?: any;
  className?: string;
}

const Content = (props: Props) => (
  <div className={cx(s.container, props.className)}>{props.children}</div>
);

export default withStyles(s)(Content);
