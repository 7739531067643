import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import loadable from '@loadable/component';
import { useIntercom } from 'react-use-intercom';
import useRouter from 'use-react-router';

import Header from 'components/common/Header/Header';
import Notifications from 'components/common/Notifications/Notifications';
import withAuth from 'hocs/withAuth';
import useI18n from 'hooks/useI18n';
import { notInitialRender } from 'utils/initialRender';
import PreloaderScreen from 'components/common/PreloaderScreen';
import Card from 'components/common/Card';
import ErrorBoundary from 'components/common/ErrorBoundary';
import ChangePassword from 'components/auth/ChangePassword/ChangePassword';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Terms, Privacy, PrivacyAgreement } from 'components/common/LegalPage';
import { pageview } from '../../tracking/gtm';
import sentryStore from '../../utils/sentryStore';
import s from './AppRouter.css';
import useUser from '../../hooks/useUser';
import {
  useOnAccountUpdatedSubscription,
  useOnAppUpdatedSubscription,
  useOnUserUpdatedSubscription, UserState,
} from '../../queries/queries';
import EmailNotice from '../../components/common/EmailNotice/EmailNotice';
import Sso from '../Sso/Sso';
import { PayoutsLoader } from '../../components/payouts/route';
import { ReportsLoader } from '../../components/reports/route';
import { TasksLoader } from '../../components/tasks/route';
import { DisqualificationLoader } from '../../components/disqualification/route';
import { SecondFlowLoader } from '../../components/auth-ru/SecondFlow/route';
import { CupliResearchLoader } from '../../components/cupli-research/route';

function wrapPreloader(Cp) {
  return (props) => <Cp fallback={<PreloaderScreen />} {...props} />;
}

const Auth = wrapPreloader(
  loadable(() => import(/* webpackChunkName: Auth */ 'containers/Auth/Auth')),
);

const Home = wrapPreloader(
  loadable(() => import(/* webpackChunkName: 'Home' */ 'components/common/Home/Home')),
);

const Category = wrapPreloader(
  withAuth(
    loadable(() => import(
      /* webpackChunkName: 'Category' */ 'components/category/Category/Category',
    )),
  ),
);

const Profile = wrapPreloader(
  withAuth(
    loadable(() => import(
      /* webpackChunkName: 'Profile' */ 'components/profile/Profile/Profile',
    )),
  ),
);

const ReferralProgram = wrapPreloader(
  loadable(() => import(
    /* webpackChunkName: 'ReferralProgram' */ 'components/common/ReferralProgram/ReferralProgram',
  )),
);

const FAQ = wrapPreloader(
  loadable(() => import(/* webpackChunkName: 'FAQ' */ 'components/common/FAQ/FAQ')),
);

const ConfirmEmailForm = wrapPreloader(
  withAuth(
    loadable(() => import(
      /* webpackChunkName: 'ConfirmEmailForm' */ 'components/common/ConfirmEmailForm/ConfirmEmailForm',
    )),
  ),
);

const Contact = wrapPreloader(
  loadable(() => import(/* webpackChunkName: 'Contact' */ 'containers/Contact/Contact')),
);

const freeRoutes = ['/terms', '/privacy', '/contact', '/legal/ru/privacy_agreement'];

function Status({ code, children }) {
  return (
    <Route
      render={({ staticContext }: any) => {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
}

const AppRouter = () => {
  const [loading] = useI18n();
  const { i18n } = useTranslation();

  const currentUser = useUser();

  if (process.env.BROWSER) {
    const intercom = useIntercom();

    const { location, history } = useRouter();

    useEffect(() => history.listen(() => notInitialRender()), []);

    useEffect(() => {
      if (location?.pathname) {
        pageview(location.pathname);
      }
    }, [location?.pathname]);

    useOnAppUpdatedSubscription({
      onSubscriptionData: (msg) => {
        if (typeof window !== 'undefined') {
          window.location.reload();
        }
      },
    });

    useOnAccountUpdatedSubscription({
      skip: !currentUser,
    });

    useOnUserUpdatedSubscription({
      skip: !currentUser,
    });

    useEffect(() => {
      sentryStore.onceReady(async (sentry) => {
        sentry.configureScope((scope) => {
          if (currentUser) {
            scope.setUser({
              userId: currentUser.id,
              userEmail: currentUser.email,
              location: location.pathname,
            });
          } else {
            scope.clear();
          }
        });
      });
    }, [currentUser]);

    useEffect(() => {
      if (currentUser) {
        if (
          currentUser.state === UserState.ConfirmingEmail
          && location.pathname !== '/confirm-email'
          && !freeRoutes.includes(location.pathname)
        ) {
          history.replace('/confirm-email');
        } else if (
          currentUser.state !== UserState.ConfirmingEmail
          && location.pathname === '/confirm-email'
        ) {
          history.replace('/tasks');
        }
      }
    }, [currentUser, location]);

    useEffect(() => {
      if (currentUser) {
        const isSecondFlowAwaiting = [UserState.SecondFlowAwaiting, UserState.Declined].includes(currentUser.state);

        if (isSecondFlowAwaiting && location.pathname !== '/second-flow' && !freeRoutes.includes(location.pathname)) {
          history.replace('/second-flow');
        }
      }
    }, [currentUser, location]);

    useEffect(() => {
      if (currentUser) {
        intercom.boot({
          userId: currentUser.id,
          email: currentUser.email,
          name: `${currentUser.firstName || ''} ${currentUser.lastName || ''}`,
          languageOverride: 'ru',
          avatar: {
            type: 'avatar',
            imageUrl: currentUser.avatar,
          },
          customAttributes: {
            balance: currentUser.accounts[0]?.balance,
            state: currentUser.state,
            confirmed: currentUser.isAccountConfirmed,
          },
        });
      }
    }, [!!currentUser]);
  }

  if (currentUser && currentUser.state === UserState.Blocked) {
    return (
      <div style={{ padding: 20 }}>
        Sorry, your YouThink account has been terminated (blocked). Please contact help@youthink.io for further details.
      </div>
    );
  }

  return (
    <div className={s.container}>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <meta charSet="utf-8" />
        <title>YouThink</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="theme-color" content="#36B588" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#36b588" />
        <meta name="msapplication-TileColor" content="#36b588" />
        <meta name="theme-color" content="#36b588" />
      </Helmet>
      <div style={{ display: 'none' }}>
        <Card />
      </div>
      <EmailNotice />
      <Header />
      <ErrorBoundary user={currentUser}>
        <div className={s.inner}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/auth" component={Auth} />
            <Route
              path="/restorePassword/:userId/:restoreToken"
              exact
              component={ChangePassword}
            />
            <Route path="/confirm-email" component={ConfirmEmailForm} />
            <Route path="/second-flow" component={SecondFlowLoader} />
            <Route path="/cupli-research" component={CupliResearchLoader} />
            <Route path="/contact" component={Contact} />
            <Route path="/tasks" component={TasksLoader} />
            <Route path="/terminated" component={DisqualificationLoader} />
            <Route path="/reports" component={ReportsLoader} />
            <Route path="/category/:id" component={Category} />
            <Route path="/profile" component={Profile} />
            <Route path="/referral" component={ReferralProgram} />
            <Route path="/payments" component={PayoutsLoader} />
            <Route path="/faq" component={FAQ} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/legal/ru/privacy_agreement" component={PrivacyAgreement} />
            <Route path="/sso" component={Sso} />
            {process.env.BROWSER ? (
              <Redirect from="*" to="/" />
            ) : (
              <Route component={() => <Status code={404}>Not found</Status>} />
            )}
          </Switch>
        </div>
      </ErrorBoundary>
      <Notifications />
    </div>
  );
};

export default AppRouter;
