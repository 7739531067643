import React from 'react';
import withStyles from 'utils/withStyles';
import cx from 'classnames';

import useRouter from 'use-react-router';
import s from './Link.css';
import { importPayouts } from '../../payouts/route';
import { importSecondFlow } from '../../auth-ru/SecondFlow/route';
import { importReports } from '../../reports/route';
import { importTasks } from '../../tasks/route';
import { importDisqualification } from '../../disqualification/route';

export type InternalLinkProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export type InternalProps = {
  activeClassName?: string;
  replace?: boolean;
};

export type LinkProps = {
  href?: string;
  to?: string;
  link?: string;
  colored?: boolean;
  className?: string;
  pure?: boolean;
} & InternalProps & InternalLinkProps;

const routesLoaders = {
  '/payments': importPayouts,
  '/reports': importReports,
  '/tasks': importTasks,
  '/terminated': importDisqualification,
  '/second-flow': importSecondFlow,
};

function InternalLink(props: LinkProps) {
  const {
    replace, activeClassName, href, className, ...otherProps
  } = props;
  const { history, location } = useRouter();

  const isCurrentRoute = location.pathname === href;
  const classNames = cx(className, isCurrentRoute ? activeClassName : '');

  const onLinkClicked = event => {
    event.preventDefault();

    (async () => {
      if (routesLoaders[href]) {
        await routesLoaders[href]();
      }

      const method = replace ? history.replace : history.push;
      method(href);
    })();
  };

  return (
    <a
      {...otherProps}
      className={classNames}
      href={href}
      onClick={onLinkClicked}
      aria-current={isCurrentRoute ? 'page' : null}
    />
  );
}

function Link(props: LinkProps) {
  const { pure, colored, ...otherProps } = props;

  const link = props.href || props.to || props.link;

  const isIntenal = link.startsWith('/');
  const classNames = cx(
    pure ? '' : s.root,
    {
      [s.colored]: colored,
    },
    props.className,
  );

  if (isIntenal) {
    return <InternalLink {...otherProps} href={link} className={classNames} />;
  }

  return (
    <a
      {...otherProps}
      className={classNames}
      rel="noreferrer noopener nofollow"
      href={link}
    />
  );
}

export default withStyles(s)(Link);
