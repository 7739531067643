import React, { useState, useEffect } from 'react';
import useLocalStorage from './useLocalStorage';

export type FieldReturnType<T> = {
  value: T;
  error: string | null;
  isValid: boolean;
  showError: boolean;
  setError(error: string): void;
  changeValue(value: T): void;
  inputFields: any;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLInputElement>, any>;

export type FormReturner<T> = { [key in keyof T]: string };

export function makeForm<T = { [key: string]: FieldReturnType<any> }>(
  fields: T,
): {
    isValid: boolean;
    serialize: () => FormReturner<T>;
    setError(field: keyof T, message: string);
  } {
  const values = Object.values(fields);

  return {
    isValid: values.filter(d => !d.isValid).length === 0,
    serialize: () => {
      const outerObject = {};

      Object.keys(fields).forEach(key => {
        outerObject[key] = fields[key].value;
      });

      return outerObject as any;
    },
    setError: (field: keyof T, message: string) => (fields as any)[field].setError(message),
  };
}

export default function useFormField<T extends string>(
  initialValue: T = '' as any,
  validator: (value: T) => any,
  formatValue?: (value: T) => T,
  doUseLocalStorage: string | boolean = false,
): FieldReturnType<T> {
  const [value, setValue] = doUseLocalStorage
    ? useLocalStorage(doUseLocalStorage as string, initialValue)
    : useState(initialValue);
  const [error, setError] = useState<string | null>(null);
  const [doesShowError, showError] = useState(false);
  const [everFocused, setHasEverFocused] = useState(true);

  const validate = async () => {
    if (validator) {
      try {
        await validator(String(value) as any);

        setError(null);
      } catch (e) {
        setError(e.message);
      }
    }
  };

  // That might be not the best idea
  useEffect(() => {
    if (!everFocused) return () => {};

    const timeout = setTimeout(() => showError(true), 3000);

    return () => clearTimeout(timeout);
  }, [value]);

  useEffect(() => {
    validate();
  }, [value]);

  async function changeValue(newValue: T) {
    setValue(newValue);
  }

  async function onChange(event: any) {
    const { value: newValue } = event.target;

    setValue(formatValue ? formatValue(newValue) : newValue);
  }

  const inputFields = {
    onChange: event => onChange(event),
    onFocus: () => {
      showError(false);

      if (!everFocused) {
        setHasEverFocused(true);
      }
    },
    onBlur: () => showError(true),
    value,
    setError: errorToSet => {
      showError(true);
      setError(errorToSet);
    },
  } as any;

  return {
    ...inputFields,
    inputFields,
    changeValue,
    error: everFocused ? error : null,
    isValid: error === null,
    showError: doesShowError,
  };
}
