import React, { Dispatch, useEffect } from 'react';

export default function useLocalStorage(
  key: string,
  initialValue: string = '',
): [string, Dispatch<string>] {
  const [item, setValue] = React.useState('');

  useEffect(() => {
    if (process.env.BROWSER) {
      const value = localStorage.getItem(key) || initialValue;
      localStorage.setItem(key, value);
      setValue(value);
    }
  }, []);

  const setItem = (newValue: string) => {
    setValue(newValue);
    window.localStorage.setItem(key, newValue);
  };

  return [item, setItem];
}
