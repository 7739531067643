/* global __DEV__ */
import React from 'react';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';
import { createRoot, hydrateRoot } from 'react-dom/client';
import sentryStore from './utils/sentryStore';
import { getToken } from './utils/token';
import ClientApp, { AppContext } from './containers/ClientApp/ClientApp';
import createApolloClient from './apollo';
import history from './clientHistory';
import { initializeMiddleware } from './tracking/middleware';

window.onunhandledrejection = (e) => {
  window.location.reload();
};

function isWindows() {
  return navigator.platform.indexOf('Win') > -1;
}

sentryStore.load();

const isMobile = 'ontouchstart' in document.documentElement;

if (process.env.ES5_BUILD) {
  document.body.classList.add('es5');
}

document.body.classList.add(isMobile ? 'is-mobile' : 'is-desktop');
if (isWindows()) {
  document.body.classList.add('is-windows');
}

const container = document.getElementById('app');

const insertCss = (...styles) => {
  // eslint-disable-next-line no-underscore-dangle
  const removeCss = styles.map((style) => style._insertCss());
  return () => removeCss.forEach((dispose) => dispose());
};

initializeMiddleware(history);

const jwtToken = getToken();
const context: AppContext = {
  client: createApolloClient(),
  insertCss,
  token: jwtToken,
};

const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<ClientApp context={context} />);
