import useNotificationsManager from 'hooks/useNotificationsManager';
import { useEffect } from 'react';
import { NotificationType } from '../../../store/notifications';

export default function UIError({ error }) {
  const { addNotification } = useNotificationsManager();

  let formatted = error && error.message ? error.message : String(error);

  if (formatted.includes('failed to fetch')) {
    formatted = 'Не получается подключиться к серверу. Но мы будем пытаться';
  }

  useEffect(() => {
    addNotification(
      formatted,
      NotificationType.Fail,
      5000,
    );
  }, [error]);

  return null;
}
