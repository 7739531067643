import React from 'react';
import withStyles from 'utils/withStyles';
import cx from 'classnames';

import s from './ReadableText.css';

const ReadableText = (props: React.ComponentProps<'div'>) => {
  const { children, className, ...otherProps } = props;

  return (
    <div {...otherProps} className={cx(s.root, className)}>
      {children}
    </div>
  );
};

export default withStyles(s)(ReadableText);
