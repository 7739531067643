/* eslint-disable */
// OMG. This should really be refactored
export function cutText(text: string, width: number) {
  const maxCharsAtLine = width / 10.2 - 1;
  const lines = text.split('\n');
  return lines.reduce((acc, line) => {
    const prepared = line.replace(/\s\s+/g, ' '); // remove multiply spaces
    const words = prepared.split(' ');
    const generatedLines = [];
    let tempStr = '';
    words.forEach(word => {
      const currentStr = `${tempStr} ${word}`;
      if (currentStr.length > maxCharsAtLine) {
        generatedLines.push(tempStr);
        tempStr = word;
      } else {
        tempStr = currentStr;
      }
    });
    generatedLines.push(tempStr);
    return acc.concat(generatedLines);
  }, []);
}

const cases = [2, 0, 1, 1, 1, 2];
export function declOfNum(number: number, titles: string[]) {
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]
  ];
}

export function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected = document.getSelection().rangeCount > 0
    ? document.getSelection().getRangeAt(0) : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function isEmail(email: string = ''): boolean {
  return emailRegex.test(email.toLowerCase());
}

const urlRegex = '^((https|http|ftp|rtsp|mms)?://)'
  + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" // ftp的user@
  + '(([0-9]{1,3}\.){3}[0-9]{1,3}' // IP形式的URL- 199.194.52.184
  + '|' // 允许IP和DOMAIN（域名）
  + "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
  + '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\.' // 二级域名
  + '[a-z]{2,6})' // first level domain- .com or .museum
  + '(:[0-9]{1,4})?' // 端口- :80
  + '((/?)|' // a slash isn't required if there is no file name
  + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";

export function isURL(url: string = ''): boolean {
  const re = new RegExp(urlRegex);
  return re.test(url.toLowerCase());
}
