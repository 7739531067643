import { SyntheticEvent, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';

import mutations from 'schemas/mutations.graphql';
import { TaskSpecialActionType } from 'models/task';
import useNotificationsManager from 'hooks/useNotificationsManager';
import { NotificationType } from 'store/notifications';

export default function useTaskHandler(
  task: { specialActionType?: string; buttonLink?: string },
): [(event?: SyntheticEvent<HTMLButtonElement>) => void, boolean] {
  const [isLoading, setLoading] = useState(false);

  const client = useApolloClient();

  const { history } = useReactRouter();

  const { addNotification } = useNotificationsManager();
  const { t } = useTranslation();

  async function clickHandler(event?: SyntheticEvent<HTMLButtonElement>) {
    setLoading(true);
    if (event) {
      event.preventDefault();
    }
    if (task.specialActionType) {
      switch (task.specialActionType) {
        case TaskSpecialActionType.confirmEmail:
          try {
            const {
              data: { resendValidationEmail: res },
            } = await client.mutate({
              mutation: mutations.ResendValidationEmailMutation,
            });
            if (res.isSuccess) {
              addNotification(
                t('Письмо с подтверждением отправлено на ваш почтовый ящик'),
                NotificationType.Success,
              );
            } else {
              addNotification(res.error.message, NotificationType.Fail);
            }
          } catch (e) {
            addNotification(e.message, NotificationType.Fail);
          }
          setLoading(false);
          break;
        case TaskSpecialActionType.fillProfile:
          history.push('/category/1');
          setLoading(false);
          break;
        default:
          console.warn('Unknown action: ', task.specialActionType);
          setLoading(false);
          break;
      }
    } else {
      window.open(task.buttonLink);
      setLoading(false);
    }
  }
  return [clickHandler, isLoading];
}
