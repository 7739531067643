// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GLa_u{max-width:1130px;max-width:var(--default-content-width);margin-left:auto;margin-right:auto;padding:50px 12px 20px;display:flex;flex-direction:column;}@media (max-width: 767.98px){.GLa_u{padding-left:0;padding-bottom:20px;padding-right:0;padding-top:30px;}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GLa_u`
};
export default ___CSS_LOADER_EXPORT___;
