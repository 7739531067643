// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vXGYa{position:fixed;top:0;left:0;width:100%;z-index:1;height:100%;background:hsla(0,0%,100%,.6);display:flex;align-items:center;justify-content:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `vXGYa`
};
export default ___CSS_LOADER_EXPORT___;
