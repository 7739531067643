import StyleContext from 'isomorphic-style-loader/dist/StyleContext';
import { Router } from 'react-router';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hoc';
import { ApolloProvider } from '@apollo/react-common';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ApolloClient from 'apollo-client';
import { IntercomProvider } from 'react-use-intercom';
import { StyledThemeProvider } from 'ui';
import App from '../AppRouter/AppRouter';
import NotificationStore from '../../store/notifications';
import history from '../../clientHistory';
import { TokenProvider } from '../../store/tokenManager';

export type AppContext = {
  client: ApolloClient<any>;
  insertCss: any;
  routerContext?: any;
  path?: any;
  token?: string;
};

const helmetContext = {};
const INTERCOM_APP_ID = window?.yt?.intercomConfig?.appId ?? '<none>';

export default function ClientApp(props: { context: AppContext }) {
  const {
    client, insertCss, token,
  } = props.context;

  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <HelmetProvider context={helmetContext}>
        <TokenProvider token={token}>
          <ApolloProvider client={client}>
            <ApolloHooksProvider client={client}>
              <StyleContext.Provider value={{ insertCss }}>
                <StyledThemeProvider>
                  <NotificationStore.StoreProvider>
                    <Router history={history}>
                      <App />
                    </Router>
                  </NotificationStore.StoreProvider>
                </StyledThemeProvider>
              </StyleContext.Provider>
            </ApolloHooksProvider>
          </ApolloProvider>
        </TokenProvider>
      </HelmetProvider>
    </IntercomProvider>

  );
}
