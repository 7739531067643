import React, { useState, useEffect } from 'react';
import withStyles from 'utils/withStyles';
import { useTranslation } from 'react-i18next';
import useRouter from 'use-react-router';
import cx from 'classnames';

import Link from 'components/common/Link';

import LogoIcon from 'assets/logo.svg';
import MenuIcon from 'assets/menu.svg';
import UserMenu from 'components/common/UserMenu/UserMenu';
import { useUser } from 'hooks';
import s from './Header.css';
import { UserState } from '../../../queries';

const Header = () => {
  const currentUser = useUser();

  const { location } = useRouter();
  const { t } = useTranslation();

  const [menuVisible, setMenuVisibility] = useState(false);

  useEffect(() => {
    setMenuVisibility(false);
  }, [location.pathname]);

  useEffect(() => {
    if (menuVisible) {
      document.querySelector('html').classList.add('with-menu');
    } else {
      document.querySelector('html').classList.remove('with-menu');
    }
  }, [menuVisible]);

  const onToggleMenu = () => setMenuVisibility(!menuVisible);
  const shouldShowTransparentHeader = currentUser ? currentUser.state === UserState.SecondFlowAwaiting : false;

  return (
    <header className={cx(s.header, shouldShowTransparentHeader && s.transparent)}>
      <div className={s.overlay} onClick={onToggleMenu} />
      <div className={s.container}>
        <div className={s.logo}>
          <Link pure to="/" aria-label="Перейти на главную YouThink" title="YouThink">
            <LogoIcon />
          </Link>
        </div>
        {shouldShowTransparentHeader ? null : (
          <>
            <div className={s.right}>
              {currentUser && currentUser.accounts && currentUser.accounts[0] ? (
                <UserMenu />
              ) : (
                <ul className={s.auth}>
                  <li className={s.menuItem}>
                    <Link
                      pure
                      to="/auth/signin"
                      className={s.menuItemLink}
                    >
                      {t('Войти')}
                    </Link>
                  </li>
                  <li className={s.menuItem}>
                    <Link
                      pure
                      to="/auth/signup"
                      className={cx(s.menuItemLink, s.next)}
                    >
                      {t('Зарегистироваться')}
                    </Link>
                  </li>
                </ul>
              )}
            </div>
            <ul className={s.menu}>
              {currentUser ? (
                <>
                  <li className={s.menuItem}>
                    <Link
                      pure
                      activeClassName={s.activeMenuItemLink}
                      className={s.menuItemLink}
                      to="/tasks"
                    >
                      {t('Опросы')}
                    </Link>
                  </li>
                  <li className={s.menuItem}>
                    <Link
                      pure
                      activeClassName={s.activeMenuItemLink}
                      className={s.menuItemLink}
                      href="/payments"
                    >
                      {t('Выплаты')}
                    </Link>
                  </li>
                  <li className={s.menuItem}>
                    <Link
                      pure
                      activeClassName={s.activeMenuItemLink}
                      className={s.menuItemLink}
                      to="/reports"
                    >
                      {t('История')}
                    </Link>
                  </li>
                  <li className={s.menuItem}>
                    <Link
                      pure
                      activeClassName={s.activeMenuItemLink}
                      className={s.menuItemLink}
                      to="/profile"
                    >
                      {t('Профиль')}
                    </Link>
                  </li>
                  <li className={s.menuItem}>
                    <Link
                      pure
                      activeClassName={s.activeMenuItemLink}
                      className={s.menuItemLink}
                      to="/referral"
                    >
                      {t('Партнерам')}
                    </Link>
                  </li>
                  <li className={s.menuItem}>
                    <Link
                      pure
                      activeClassName={s.activeMenuItemLink}
                      className={s.menuItemLink}
                      to="/faq"
                    >
                      {t('FAQ')}
                    </Link>
                  </li>
                  <li className={s.menuItem}>
                    <a
                      className={s.menuItemLink}
                      target="_blank"
                      href="http://bit.ly/youthink-fb"
                      rel="nofollow noopener noreferrer"
                    >
                      {t('Отзывы')}
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li className={s.menuItem}>
                    <Link
                      pure
                      activeClassName={s.activeMenuItemLink}
                      className={s.menuItemLink}
                      to="/faq"
                    >
                      {t('Узнать больше')}
                    </Link>
                  </li>
                  <li className={s.menuItem}>
                    <Link
                      pure
                      activeClassName={s.activeMenuItemLink}
                      className={s.menuItemLink}
                      to="/contact"
                    >
                      {t('Поддержка')}
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </>
        )}
      </div>
      <div className={s.mobile}>
        <MenuIcon className={s.menuIcon} onClick={onToggleMenu} />
        <Link pure to="/">
          <LogoIcon />
        </Link>
      </div>
    </header>
  );
};

export default withStyles(s)(Header);
