export interface ITask {
  name: {
    message: string;
  };
  description: {
    message: string;
  };
  isImportant: boolean;
  type: string;
  reward?: {
    value: number;
  };
  buttonText: string;
  buttonLink?: string;
  specialActionType?: TaskSpecialActionType;
  averageTime?: number;
}

export enum TaskSpecialActionType {
  confirmEmail = 'confirmEmail',
  fillProfile = 'fillProfile',
  hide = 'hide',
}
