import { useContext } from 'react';
import { TokenStore } from '../store/tokenManager';

export type StoreSignature = {
  token: string | null;
  setToken(token: string | null, setTokenInAppContext?: boolean): void;
  removeToken(): void;
  hasToken: boolean;
};

export default function useTokenManager() {
  return useContext(TokenStore) as StoreSignature;
}
