import React from 'react';
import LegalPage from "./LegalPage";

export function Privacy() {
  return <LegalPage page="privacy" title="Политика конфиденциальности" />
}

export function Terms() {
  return <LegalPage page="terms" title="Политика конфиденциальности" />
}

export function PrivacyAgreement() {
  return <LegalPage page="privacy_agreement" title="Согласие на обработку персональных данных" />
}