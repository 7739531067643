export const defaultTheme = {
  primary: {
    middle: '#756FF4',
  },
  secondary: {
    dark: '#188968',
    primary: '#60bb85',
  },
  typography: {
    primary: '#11253B',
    top: '#203449',
    middle: '#2D455D',
    low: '#778FA8',

    background: '#EEF2F7',
  },
  supporting: {
    warning: '#E06D10',
    error: '#ef3e6e',
  },
};
