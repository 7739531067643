import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef } from 'react';
import loadable from '@loadable/component';
import Preloader from '../components/common/Preloader/Preloader';

export type PageTypes = 'referral';

export default function Page(props: { page: PageTypes } & any) {
  const { page, ...otherProps } = props;
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const importedPage = useMemo(() => require(`./${page}/index.ts`).default, [
    page,
  ]);
  const PageCp = importedPage[locale] || importedPage.fallback;

  useEffect(() => {
    if (PageCp && PageCp.isMDXComponent && props.onLoad) {
      props.onLoad();
    }
  }, [PageCp]);

  return <PageCp fallback={<Preloader />} {...otherProps} />;
}
