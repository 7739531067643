import styled from 'ui/styled';

const H1 = styled.h1<{}>`
  font-size: 2.5rem;
  line-height: 1.3;
  font-weight: 500;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.typography.primary};
`;

export { H1 };
