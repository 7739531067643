import React, { useMemo, useState } from 'react';
import withStyles from 'utils/withStyles';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import Input from 'components/form/Input/Input';
import Label from 'components/form/Label/Label';
import NextButton from 'components/form/NextButton/NextButton';
import {
  useTranslation,
  WithTranslation,
} from 'react-i18next';
import { ILocalizedString } from 'models/common';
import useRouter from 'use-react-router/use-react-router';
import useTokenManager from 'hooks/useTokenManager';
import s from './ChangePassword.css';
import Form from '../Form/Form';
import {
  useChangePasswordMutationMutation,
  useRestoreTokenQueryQuery,
} from '../../../queries/queries';
import PreloaderScreen from '../../common/PreloaderScreen';
import UIError from '../../common/UIError';
import useFormField from '../../../hooks/useFormField';
import useNotificationsManager from '../../../hooks/useNotificationsManager';
import { NotificationType } from '../../../store/notifications';
import IsomorphicRedirect from '../../common/IsomorphicRedirect/IsomorphicRedirect';
import PageTitle from '../../common/PageTitle/PageTitle';
import {
  Text, TextInput, Trans, WithSpinner,
} from '../../../ui';
import locales from '../../auth-ru/SignUp/SignUp.strings';
import { SignButton } from '../../auth-ru/Button/Button';

interface State {
  password: string;
  passwordError?: string;
  repeatPassword: string;
  repeatPasswordError?: string;
  isSending: boolean;
  recaptchaToken: string;
  error: string;
}

interface MatchParams {
  userId: string;
  restoreToken: string;
}

interface Props extends RouteComponentProps<MatchParams>, WithTranslation {}
interface LoginResult {
  data: {
    changePassword: {
      errorCode?: string;
      errorMessage: ILocalizedString;
      isSuccess: boolean;
      jwtToken: string;
    };
  };
}

export function ChangePasswordHooks() {
  const {
    match: {
      params: { userId, restoreToken },
    },
    history,
  } = useRouter<MatchParams>();
  const { t } = useTranslation();
  const tokenManager = useTokenManager();

  const { addNotification } = useNotificationsManager();
  const tokenQuery = useRestoreTokenQueryQuery({
    variables: { restoreToken, userId },
  });

  const [mutation, { loading }] = useChangePasswordMutationMutation();

  const password = useFormField('', (v: string) => {
    if (!v || v.length < 5) {
      throw new Error('Должен быть длиннее 5 символов');
    }
  });

  const updatePassword = (event) => {
    console.log('updating password');
    if (event) {
      event.preventDefault();
    }

    if (!password.isValid) {
      return;
    }

    try {
      mutation({
        variables: { restoreToken, userId, newPassword: password.value },
      }).then((result) => {
        const {
          data: {
            changePassword: { isSuccess, errorMessage, jwtToken },
          },
        } = result;

        if (!isSuccess) {
          addNotification(errorMessage.message, NotificationType.Fail);
        } else {
          addNotification(t('Вы поменяли свой пароль!'));
          tokenManager.setToken(jwtToken);
          history.push('/tasks');
        }
      });
    } catch (e) {
      addNotification(e.message, NotificationType.Fail);
    }
  };

  if (tokenQuery.loading) {
    return <PreloaderScreen />;
  }

  if (tokenQuery.error) {
    return (
      <>
        <UIError error={tokenQuery.error} />
        <IsomorphicRedirect to="/" />
      </>
    );
  }

  const { isSuccess, error } = tokenQuery.data.restoreToken;

  if (!isSuccess) {
    return (
      <>
        <UIError error={error || 'Не удалось получить информацию с сервера'} />
        <IsomorphicRedirect to="/" />
      </>
    );
  }

  return (
    <Form className={s.change} onSubmit={updatePassword}>
      <PageTitle>{t('Изменение пароля')}</PageTitle>
      <Text weight={300} size="xxl">
        Изменить пароль
      </Text>
      <div className={s.row}>
        <TextInput
          id="password"
          minLength={5}
          required
          label="Новый пароль"
          type="password"
          autoComplete="new-password"
          {...password}
        />
      </div>
      <div className={s.buttons}>
        <SignButton type="submit" disabled={loading}>
          <WithSpinner show={loading}>Изменить</WithSpinner>
        </SignButton>
      </div>
    </Form>
  );
}

export default withStyles(s)(ChangePasswordHooks);
