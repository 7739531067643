import React, { useEffect, useRef, useState } from 'react';
import MaskedInput from 'react-text-mask';
import cx from 'classnames';
import styled from '../styled';
import { Notch } from './Notch';

export interface InputInternalProps {
  label?: string;
  right?: any;
  error?: string | null;
  mask?: any;
}

export type InputProps = React.DetailedHTMLProps<
React.InputHTMLAttributes<HTMLInputElement>,
HTMLInputElement
> &
InputInternalProps;

export function makeForwardedEvents(map: any, props: InputProps) {
  const newMap = {};

  Object.keys(map).forEach(key => {
    newMap[key] = event => {
      const handler = props[key];
      if (handler && typeof handler === 'function') {
        handler(event);
      }

      if (!event.defaultPrevented) {
        return map[key](event);
      }
    };
  });

  return newMap;
}

const Container = styled.div`
  height: 65px;
  position: relative;
  border: none;
  outline: none;
  overflow: visible;
  display: inline-flex;
  box-sizing: border-box;
  width: 100%;
`;

const Input = styled.input`
  padding-left: 21px;
  padding-right: 20px;
  padding-top: 6px;
  border: none;
  outline: none;
  background: none;
  margin: 0;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.typography.primary};
  font: inherit;
  font-family: var(--system-font);
  letter-spacing: 0.3px;
  z-index: 1;
  border-radius: 4px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    font: inherit;
  }

  &::placeholder {
    opacity: 0;
    transition: 0.1s opacity ease-out;
  }

  .is-focused &::placeholder {
    opacity: 1;
  }
`;

const AlignByRight = styled.div`
  position: relative;
  left: auto;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function TextInput(props: InputProps) {
  const {
    label, id, right, error, mask, ...inputProps
  } = props;

  const ref = useRef<HTMLInputElement>();

  const hasEverFocused = useRef<boolean>();

  const [isFocused, setFocused] = useState(false);
  const [hasAnyValue, setHasAnyValue] = useState(false);
  const [showError, setShowError] = useState(false);

  const onChange = ({ target: { value } }) => {
    setHasAnyValue(value && value.length > 0);
  };

  useEffect(() => {
    if (error && !showError && hasEverFocused.current) {
      setShowError(true);
    }

    return () => setShowError(false);
  }, [error, hasEverFocused.current]);

  const events = makeForwardedEvents(
    {
      onFocus: () => {
        setFocused(true);
      },
      onBlur: () => {
        setFocused(false);
        hasEverFocused.current = true;
      },
      onChange,
    },
    inputProps,
  );

  const rlyHasValue = hasAnyValue
    || (inputProps.value
      && (typeof inputProps.value === 'number'
        ? inputProps.value > 0
        : inputProps.value.length > 0));

  const isNotchOpened = isFocused || rlyHasValue;
  const allInputProps = {
    id,
    'aria-invalid': !!error,
    'aria-label': label,
    ...inputProps,
    ...events,
  };

  const renderedInput = mask ? (
    <MaskedInput
      mask={mask}
      {...allInputProps}
      render={(ref, props) => <Input ref={ref} {...props} />}
    />
  ) : (
    <Input {...allInputProps} />
  );

  return (
    <Container
      className={cx({ 'is-focused': isFocused, 'has-value': rlyHasValue })}
    >
      {renderedInput}
      <Notch
        label={label}
        isOpened={isNotchOpened}
        isFocused={isFocused}
        id={id}
        error={showError ? error : null}
      />
      {right ? <AlignByRight>{right}</AlignByRight> : null}
    </Container>
  );
}
