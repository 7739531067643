import * as React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { defaultTheme } from './defaultTheme';

export function StyledThemeProvider({
  children,
}: {
  children: React.ReactChild;
}) {
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
}
