import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import cx from 'classnames';
import withStyles from 'utils/withStyles';

import s from './Input.css';

type Props = {
  fullWidth?: boolean;
  className?: string;
  withBorder?: boolean;
  withNotch?: boolean;
  outlined?: boolean;
  long?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  placeholder?: string;
} & React.ComponentProps<'input'>;

const Input = (props: Props) => {
  const ref = useRef<any>();

  const {
    fullWidth,
    className,
    withBorder,
    withNotch,
    isSuccess,
    isError,
    autoFocus,
    outlined,
    long,
    ...otherProps
  } = props;

  const classNames = cx(
    s.input,
    {
      [s.full]: fullWidth,
      [s.withBorder]: withBorder,
      [s.success]: isSuccess,
      [s.error]: isError,
      [s.outlined]: outlined,
      [s.long]: long,
    },
    className,
  );

  useEffect(() => autoFocus && ref.current.focus(), [autoFocus]);


  /* if (withNotch) {
    return (
      <div className={s.root}>
        <div className={s.notch}>
          <div className={s.notchContainer} />
        </div>
      </div>
    )
  } */
  if (long) {
    return (
      <textarea
        {...otherProps as any}
        ref={ref}
        className={classNames}
      />
    );
  }

  return (
    <input
      ref={ref}
      className={classNames}
      {...otherProps}
    />
  );
};

export default withStyles(s)(Input);
