import styled from 'ui/styled';

export const SignButton = styled.button`
  font-family: var(--system-font);
  background: ${({ theme }) => theme.primary.middle};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  font-size: 17px;
  opacity: 1;
  transition: 0.3s opacity cubic-bezier(0.075, 0.82, 0.165, 1), 0.1s background cubic-bezier(0.075, 0.82, 0.165, 1), 0.3s transform cubic-bezier(0.075, 0.82, 0.165, 1);
  
  ${({ disabled }) => (disabled ? `
    pointer-events: none;
    background: #9e9bf8;
  ` : '')}
  
  &:hover, &:focus {
    background: #6862f1;
  }
  
  &:active {
    transform: scale(0.99);
  }
`;
