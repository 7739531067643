import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function PageTitle(props: { children: string; hideSuffix?: boolean }) {
  const { hideSuffix, children } = props;

  const suffix = !hideSuffix ? '  — YouThink' : '';

  return (
    <Helmet>
      <title>
        {children + suffix}
      </title>
    </Helmet>
  );
}
