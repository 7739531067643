import React, { useState } from 'react';
import withStyles from 'utils/withStyles';
import useUser from 'hooks/useUser';

import { Trans } from 'react-i18next';
import Button from 'components/form/Button';
import useTaskHandler from 'hooks/useTaskHandler';
import s from './EmailNotice.css';
import ReadableText from '../ReadableText';
import Popup from '../Popup/Popup';
import ChangeEmailPopup from '../ChangeEmailPopup/ChangeEmailPopup';
import { UserState } from '../../../queries';

const EmailNotice: React.FC<{}> = () => {
  const user = useUser();
  const [click, isLoading] = useTaskHandler({
    specialActionType: 'confirmEmail',
  });
  const [isMounted, setMounted] = useState(false);

  if (user && !user.isAccountConfirmed && user.state === UserState.Activated) {
    return (
      <div className={s.root}>
        <div className={s.container}>
          <ReadableText className={s.lead}>
            <Trans>
              Подтвердите email, иначе мы не сможем отправлять вам опросы
            </Trans>
          </ReadableText>
          <Button
            kind={Button.Types.White}
            onClick={click}
            disabled={isLoading}
          >
            <Trans>Выслать письмо еще раз</Trans>
          </Button>
          <Button kind={Button.Types.White} onClick={() => setMounted(true)}>
            <Trans>Изменить Email</Trans>
          </Button>
        </div>
        {isMounted && (
          <Popup onlyChildren onClose={() => setMounted(false)}>
            {(onClose) => <ChangeEmailPopup onClose={onClose} />}
          </Popup>
        )}
      </div>
    );
  }

  return null;
};

export default withStyles(s)(EmailNotice);
