import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import sentryStore from 'utils/sentryStore';

let lastEventId = '';

class ErrorBoundary extends React.Component<
Partial<WithTranslation & { user: { firstName?: string, email?: string } | null | false}>,
{ hasError: boolean; eventId: string }
> {
  state = {
    hasError: false,
    eventId: '',
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, eventId: lastEventId };
  }

  async componentDidCatch(
    error: Error,
    errorInfo: React.ErrorInfo,
  ): Promise<void> {
    const { withScope, captureException, showReportDialog } = await sentryStore.donePromise;
    if (!__DEV__) {
      withScope((scope) => {
        scope.setExtras(errorInfo);

        const eventId = captureException(error, {
          contexts: { react: { componentStack: errorInfo.componentStack } },
        });

        lastEventId = eventId;
        this.setState({ hasError: true, eventId });
        showReportDialog({
          eventId,
          lang: 'ru',
          user: this.props.user ? {
            email: this.props.user.email,
            name: this.props.user.firstName,
          } : null,
        });
      });
    }

    console.error(error, errorInfo);
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      return (
        <div>
          <p>
            {t('Произошла ошибка — мы уже знаем об этом и спешим на помощь')}
          </p>
          <p>
            {t(
              'Попробуйте просто обновить страницу и возможно все заработает. Или нет, но тогда напишите нам на help@youthink.io вместе с кодом {{code}}, исправим.',
              { replace: { code: this.state.eventId || 0 } },
            )}
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
