// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dHlam{font-size:1rem;font-size:var(--default-size);cursor:inherit;color:#11253B;color:var(--color-typo-primary);opacity:.9}.rLe0M{color:#FF4A47;color:var(--danger-color);margin-left:12px;font-size:.9em}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `dHlam`,
	"error": `rLe0M`
};
export default ___CSS_LOADER_EXPORT___;
