import {
  ApolloLink, Operation, FetchResult, Observable, empty,
} from 'apollo-link';

import { SubscriptionClient } from 'subscriptions-transport-ws';

export class WebSocketDynamicLink extends ApolloLink {
  private readonly subscriptionClient: () => SubscriptionClient;

  constructor(
    clientResolver: () => SubscriptionClient,
  ) {
    super();

    this.subscriptionClient = clientResolver;
  }

  public request(operation: Operation): Observable<FetchResult> | null {
    const currentClient = this.subscriptionClient();

    if (!currentClient) {
      return empty().request(operation);
    }

    return currentClient.request(operation) as Observable<
    FetchResult
    >;
  }
}
