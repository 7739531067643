import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { Transition } from 'react-transition-group';
import withStyles from 'utils/withStyles';

import Button from 'components/form/Button/Button';
import { useTranslation } from 'react-i18next';
import s from './Popup.css';
import useWindowSize from '../../../hooks/useWindowSize';

interface Props {
  title?: string;
  buttons?: any[];
  onClose?: any;
  closeText?: string;
  children: any;
  className?: string;
  onlyChildren?: boolean;
  tagData?: any;
  lockScroll?: boolean;

  width?: string;
  height?: string;
}

export function PopupRow(
  props: React.DetailedHTMLProps<
  React.HTMLProps<HTMLDivElement>,
  HTMLDivElement
  >,
) {
  return <div className={s.row} {...props} />;
}

export function PopupTitle(
  props: React.DetailedHTMLProps<
  React.HTMLProps<HTMLHeadingElement>,
  HTMLHeadingElement
  >,
) {
  return <div className={s.title} {...props} />;
}

export function PopupActions(props: any) {
  const { t } = useTranslation();

  return (
    <div className={s.buttons}>
      {props.children}

      <Button
        kind={Button.Types.Simple}
        tabIndex={-1}
        onClick={(event) => {
          event.preventDefault();
          props.onClose();
        }}
      >
        {props.closeText || t('Отменить')}
      </Button>
    </div>
  );
}

const Popup = (props: Props) => {
  const { t } = useTranslation();
  const {
    onClose,
    closeText,
    buttons,
    title,
    lockScroll,
    children,
    className,
    onlyChildren,
    tagData,
  } = props;

  useEffect(() => {
    if (!lockScroll) {
      return;
    }

    document.body.classList.add('lockScroll');
    return () => document.body.classList.remove('lockScroll');
  }, [lockScroll]);

  const [width, height] = useWindowSize();
  const usedRef = useRef<HTMLDivElement>();
  const [gonnaClose, setGonnaClose] = useState(false);
  const [styles, setStyles] = useState<any>(false);

  function onceClosed() {
    setGonnaClose(true);
    setTimeout(() => onClose(), 150);
  }

  const formattedChildren = typeof children === 'function' ? children(onceClosed) : children;
  const passedChild = onlyChildren ? (
    formattedChildren
  ) : (
    <>
      {title && <div className={s.title}>{title}</div>}
      <div className={cx(s.innerContent, className)}>{formattedChildren}</div>
      <div className={s.buttons}>
        <Button kind={Button.Types.Simple} onClick={onceClosed}>
          {closeText || t('Отменить')}
          {buttons}
        </Button>
      </div>
    </>
  );

  useEffect(() => {
    if (!usedRef.current) return;

    setTimeout(() => {
      const { offsetWidth, offsetHeight } = usedRef.current;

      const left = `${(width - offsetWidth) / 2}px`;
      const top = `${(height - offsetHeight) / 2}px`;

      setStyles({ top, left });
    }, 10);
  }, [usedRef.current, width, height, tagData]);

  useEffect(() => {
    const listener = (event) => event.keyCode === 27 && onceClosed();
    document.addEventListener('keydown', listener);

    return () => document.removeEventListener('keydown', listener);
  }, []);

  const outerStyles = {
    ...(styles || {}),
    minHeight: props.height,
    maxWidth: props.width,
    overflowY: 'auto',
  };

  return (
    <Transition timeout={150} in={!gonnaClose && !!styles}>
      {(state) => (
        <div>
          <div
            className={cx(s.bg, !gonnaClose && !!styles && s.bgShown, state)}
            onClick={onceClosed}
          />
          <div
            style={outerStyles}
            ref={usedRef}
            className={cx(s.popup, s[`state-${state}`])}
          >
            {passedChild}
          </div>
        </div>
      )}
    </Transition>
  );
};

export default withStyles(s)(Popup);
