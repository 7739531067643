// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/.pnpm/postcss-loader@8.1.1_postcss@8.4.35_typescript@3.9.10_webpack@5.90.3/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!../../../containers/Auth/Auth.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LWr6R{}.Z20Jy{}.Vnv5a{}.VXmxK{}.UVDko{}.rVr8j{}.uAAOu{}.XIosK{}.zqfZl{}.R_FGr{}.gSVCD{}.lg2tX{}.tA4iI{}.AN4c6{font-size:1.25rem;margin-top:1.25rem;line-height:1.3;font-weight:300}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"change": `LWr6R ${___CSS_LOADER_ICSS_IMPORT_0___.locals["container"]}`,
	"title": `Z20Jy ${___CSS_LOADER_ICSS_IMPORT_0___.locals["title"]}`,
	"form": `Vnv5a ${___CSS_LOADER_ICSS_IMPORT_0___.locals["form"]}`,
	"row": `VXmxK ${___CSS_LOADER_ICSS_IMPORT_0___.locals["row"]}`,
	"responseError": `UVDko ${___CSS_LOADER_ICSS_IMPORT_0___.locals["responseError"]}`,
	"input": `rVr8j ${___CSS_LOADER_ICSS_IMPORT_0___.locals["input"]}`,
	"buttons": `uAAOu ${___CSS_LOADER_ICSS_IMPORT_0___.locals["buttons"]}`,
	"checkbox": `XIosK ${___CSS_LOADER_ICSS_IMPORT_0___.locals["checkbox"]}`,
	"socials": `zqfZl ${___CSS_LOADER_ICSS_IMPORT_0___.locals["socials"]}`,
	"socialsIcons": `R_FGr ${___CSS_LOADER_ICSS_IMPORT_0___.locals["socialsIcons"]}`,
	"socialsIcon": `gSVCD ${___CSS_LOADER_ICSS_IMPORT_0___.locals["socialsIcon"]}`,
	"socialsTitle": `lg2tX ${___CSS_LOADER_ICSS_IMPORT_0___.locals["socialsTitle"]}`,
	"back": `tA4iI ${___CSS_LOADER_ICSS_IMPORT_0___.locals["link"]}`,
	"successMessage": `AN4c6`
};
export default ___CSS_LOADER_EXPORT___;
