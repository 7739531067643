import React, { useEffect, useState } from 'react';
// todo use lodash-es
import withStyles from 'utils/withStyles';
import cx from 'classnames';

import SuccessIcon from 'assets/success.svg';
import FailIcon from 'assets/fail.svg';
import { NotificationType } from 'store/notifications';
import s from './Notification.css';
import useManagedTimeout from '../../../hooks/useManagedTimeout';

interface Props {
  notification: any;
}

export function Notification(props: Props) {
  const { notification } = props;
  const {
    type, duration, close, text, buttons, isClosing,
  } = notification;
  const isSuccess = type === NotificationType.Success;
  const icon = isSuccess ? (
    <SuccessIcon className={s.icon} />
  ) : (
    <FailIcon className={s.icon} />
  );

  const managedTimeout = useManagedTimeout(() => close(), duration);

  return (
    <div
      className={cx(s.container, {
        [s.success]: isSuccess,
        [s.fail]: !isSuccess,
        [s.closing]: isClosing,
      })}
      // When mouse is moving around notification, we don't close it.
      onMouseEnter={() => {
        // console.log('enter');
        managedTimeout.pause();
      }}
      onMouseLeave={() => {
        // console.log('out');
        managedTimeout.resume();
      }}
    >
      {icon}
      <div className={s.text}>{text}</div>
      {buttons && <div className={s.buttons}>{buttons}</div>}
      <div className={s.close} onClick={() => close()} />
      <div
        className={cx(!managedTimeout.paused && s.progress)}
        style={{ '--animation-duration': `${duration}ms` } as any}
      />
    </div>
  );
}

export default withStyles(s)(Notification);
