import cookie from 'js-cookie';

const token = 'token';
const cbs = [];

export function getToken() {
  return cookie.get(token);
}

export function onceTokenUpdated(cb: (token: string | null) => void) {
  cbs.push(cb);
}

class Token {
  private token: string = null;

  constructor() {
    this.token = cookie.get(token);
  }

  getToken(): string {
    return this.token || '';
  }

  setToken(value: string) {
    cookie.set(token, value);
    this.token = value;

    cbs.forEach(c => c(this.token));
  }

  removeToken() {
    cookie.remove(token);
    this.token = null;
  }

  get isEmpty() {
    return !this.token;
  }
}

export default new Token();
