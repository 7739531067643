// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F8TFU{margin-top:20px;padding:0 15px;font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;font-family:var(--system-font)}.F8TFU h1,h2,h3{margin-top:40px;margin-bottom:20px}.F8TFU h2{font-weight:500}.F8TFU p{line-height:1.7}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `F8TFU`
};
export default ___CSS_LOADER_EXPORT___;
