import React from 'react';
import { Trans as ReactTrans, useTranslation } from 'react-i18next';

export type Locale = {
  id: string;
  defaultMessage?: string;
  children?: any;
};

export function useTrans() {
  const { t, ...otherProps } = useTranslation();

  return {
    t: (locale: Locale) => t(locale.id, { defaultValue: locale.defaultMessage }),
    ...otherProps,
  };
}

export function Trans(props: Locale) {
  const { id, defaultMessage, children } = props;

  return (
    <ReactTrans i18nKey={id} {...props}>{defaultMessage || children}</ReactTrans>
  );
}
