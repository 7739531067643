import React from 'react';
import withStyles from 'utils/withStyles';

import s from './Form.css';

function Form(props: React.ComponentProps<'form'>) {
  const { children, onSubmit } = props;
  return (
    <div className={s.parentForm}><form className={s.form} onSubmit={onSubmit}>{children}</form></div>
  );
}

export default withStyles(s)(Form);
