import { useApolloClient } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import queries from 'schemas/queries.graphql';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
  GetLocaleDataQueryQuery,
  ReportMissingKeyDocument,
} from 'queries';
import { apolloClient } from 'apollo';

const glob = typeof window !== 'undefined' ? (window as any) : {};
const initialResources = process.env.BROWSER && glob.yt && glob.yt.i18n ? glob.yt.i18n : null;
const usedLocale = process.env.BROWSER && glob.yt && glob.yt.locale ? glob.yt.locale : null;

i18n.use(initReactI18next).init({
  resources: initialResources || {},
  lng: usedLocale || 'ru',
  keySeparator: '::',
  nsSeparator: '>>',
  fallbackLng: ['ru', 'en'],
  saveMissing: true,
  interpolation: {
    escapeValue: false,
    formatSeparator: ';',
  },
  missingKeyHandler: (lngs, ns, key, fallback) => {
    if (!__DEV__ || !process.env.BROWSER || true) {
      return;
    }

    apolloClient.mutate({
      mutation: ReportMissingKeyDocument,
      variables: {
        key,
        stack: `stack:\n${new Error().stack}\nlocation:${
          process.env.BROWSER ? document.location : ''
        }`,
        fallback,
      },
    });
  },
});

// wip: think about migrating to fbt https://github.com/facebookincubator/fbt or https://lingui.js.org/index.html
export default function useI18n(): [boolean, any] {
  const [isLoading, setLoading] = useState(
    process.env.BROWSER ? !initialResources : false,
  );
  const [data, setData] = useState({});

  const client = useApolloClient();

  async function onEffect() {
    const locale = navigator.language.split('-')[0];
    // Already has been renderred, so nothing to worry about.
    if (initialResources) {
      return;
    }

    const result = await client.query<GetLocaleDataQueryQuery>({
      query: queries.GetLocaleDataQuery,
      variables: { locale },
    });

    const localeData = result.data.getLocaleData;

    const outerData = { [locale]: { translation: {} } };

    localeData.forEach(entity => {
      outerData[locale].translation[entity.key] = entity.value;
    });

    await i18n.use(initReactI18next).init({
      resources: outerData,
      lng: locale,
      keySeparator: '::',
      nsSeparator: '>>',
      fallbackLng: ['ru', 'en'],
      saveMissing: false,
      /*      missingKeyHandler: (lngs, ns, key, fallback) => {
              if (!__DEV__ || true) {
                return;
              }

              submitKey({
                variables: {
                  key,
                  stack: `stack:\n${new Error().stack}\nlocation:${
                    process.env.BROWSER ? document.location : ''
                  }`,
                  fallback,
                },
              });
            }, */
      interpolation: {
        escapeValue: false,
        formatSeparator: ';',
      },
    });

    setLoading(false);
    setData(localeData);
  }

  useEffect(() => {
    onEffect();
  }, []);

  if (isLoading) {
    return [true, null];
  }

  return [false, data];
}
