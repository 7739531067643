/* eslint-disable */
const gtmId = 'GTM-W64GWJ3M'

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}

declare const window: WindowWithDataLayer

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || []
}

export async function loadGtm() {
  return new Promise(resolve => {
    (function (w, d, s: 'script', l, i) {
      w[l] = w[l] || []
      w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js',
      })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.onload = resolve
      j.src =
        'https://gtm.youthink.dev/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', gtmId)
  })
}

export function pushDataLayer(event: string | Record<string, string>, params?: any) {
  if (typeof event === 'object') {
    try {
      window.dataLayer.push({
        ...event,
      })
    } catch (e) {
      console.warn(e)
    }
  }
  try {
    window.dataLayer.push({
      event,
      ...params,
    })
  } catch (e) {
    console.warn(e)
  }
}

export const pageview = (url: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "pageview",
      page: url,
    })
  } else {
    console.log({
      event: "pageview",
      page: url,
    })
  }
}