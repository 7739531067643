import React from 'react';
import { Link } from 'react-router-dom';
import withStyles from 'utils/withStyles';
import cx from 'classnames';

import { withOptionalRipple } from 'decorators/withOptionalRipple';
import s from './Button.css';

export type NativeButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

enum Type {
  Default = 'Default',
  Simple = 'simple',
  White = 'white',
}

interface Props extends NativeButtonProps {
  children?: any;
  kind?: Type;
  upper?: boolean;
  className?: string;
  onClick?: any;
  isLink?: boolean;
  disabled?: boolean;
  to?: string;
  fullWidth?: boolean;
  id?: string;
  initRipple?: any;
}

const Button = (props: Props) => {
  const {
    className,
    kind,
    upper,
    fullWidth,
    isLink,
    children,
    initRipple,
    to,
    ...other
  } = props;

  const classNames = cx(
    s.button,
    className,
    {
      [s.simple]: kind === Type.Simple,
      [s.white]: kind === Type.White,
      [s.upper]: upper,
      [s.full]: fullWidth,
    },
    'mdc-ripple-surface',
    'mdc-ripple-surface--primary',
  );
  if (isLink) {
    if (to.startsWith('/')) {
      return (
        <Link
          innerRef={initRipple}
          className={classNames}
          to={to}
          {...other as any}
        >
          {children}
        </Link>
      );
    }

    return (
      <a
        ref={initRipple}
        className={classNames}
        href={to}
        {...other as any}
      >
        {children}
      </a>
    );
  }

  return (
    <button ref={initRipple} className={classNames} {...other}>
      {children}
    </button>
  );
};

const decorated: React.ComponentType<Props & NativeButtonProps> & { Types?: typeof Type } = withStyles(s)(withOptionalRipple(Button));

decorated.Types = Type;

export default decorated;
