// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bm6WG{padding:12px 4px;font-size:1.15rem;font-size:var(--medium-size);font-family:inherit;border:none;background:none;border-bottom:1.5px solid rgba(0,0,0,.12);border-bottom:1.5px solid var(--input-border-color);border-radius:3px;box-sizing:border-box;transition:border .15s ease;color:#11253B;color:var(--color-typo-primary);}@media (max-width: 767.98px){.bm6WG{padding-left:16px;padding-bottom:12px;padding-right:16px;padding-top:12px;}}.X3P4O{border:1px solid rgba(0,0,0,.12);border:1px solid var(--input-border-color);padding:18px}.bm6WG::-webkit-inner-spin-button,.bm6WG::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}.eyGRF{padding:8px;font-size:16px;background:#fff;background:var(--white-background);border:1px solid #E9EAEA;border:1px solid var(--input-border-color-1);color:#11253B;color:var(--color-typo-primary)}.bm6WG:hover{border-color:rgba(0,0,0,.87);border-color:var(--input-border-color-hover);transition:border-color .15s linear}.bm6WG:focus{outline:none;border-color:#506cf0;border-color:var(--primary-color)}.bm6WG.HTN7B{border-color:#7DBB91;border-color:var(--success-color)}.bm6WG.Acb34{border-color:#FF4A47;border-color:var(--danger-color)}.bm6WG::-moz-placeholder{color:rgba(0, 0, 0, 0.5);color:var(--input-color)}.bm6WG::placeholder{color:rgba(0, 0, 0, 0.5);color:var(--input-color)}.N56UI{height:200px;resize:none}.FzUtg{width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `bm6WG`,
	"outlined": `X3P4O`,
	"withBorder": `eyGRF`,
	"success": `HTN7B`,
	"error": `Acb34`,
	"long": `N56UI`,
	"full": `FzUtg`
};
export default ___CSS_LOADER_EXPORT___;
