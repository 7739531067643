// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sn5vC{max-width:600px;max-width:var(--login-content-width);padding:48px 16px;margin:0 auto;box-sizing:border-box}.abB9r{font-weight:700;font-size:1.8rem;line-height:1.3}.yyEO_{margin-top:32px}.MEFDy{margin-top:22px}.PQppH{color:#FF4A47;color:var(--danger-color);margin-top:12px}.y26fI{margin-top:15px}.TVllD{margin-top:35px;width:100%;}.TVllD>button{width:100%}.HJMTV{font-size:1.06rem;color:rgba(0, 0, 0, 0.65);color:var(--darken-color);margin-top:26px;line-height:1.3}.ZSPNW{margin-right:13px}.b0xAR{margin-top:32px}.KCDve{margin-top:12px}.KCDve svg{cursor:pointer;margin-right:16px}.Fffk9{font-size:1.06rem;font-weight:700}.n9TKW{font-size:1.15rem;font-size:var(--medium-size);margin-top:25px;margin-bottom:15px}.n9TKW a{color:#2D455D;color:var(--color-2);-webkit-text-decoration:none;-webkit-text-decoration:none;text-decoration:none;font-weight:400}.n9TKW a:hover{color:#203449;color:var(--body-text-color)}.u_qdD{position:relative}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sn5vC`,
	"title": `abB9r`,
	"form": `yyEO_`,
	"row": `MEFDy`,
	"responseError": `PQppH`,
	"input": `y26fI`,
	"buttons": `TVllD`,
	"terms": `HJMTV`,
	"checkbox": `ZSPNW`,
	"socials": `b0xAR`,
	"socialsIcons": `KCDve`,
	"socialsTitle": `Fffk9`,
	"link": `n9TKW`,
	"content": `u_qdD`
};
export default ___CSS_LOADER_EXPORT___;
