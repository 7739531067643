import React from 'react';
import withStyles from 'utils/withStyles';

import useNotificationsManager from 'hooks/useNotificationsManager';
import Notification from 'components/common/Notification/Notification';
import s from './Notifications.css';

export function Notifications() {
  const { notifications } = useNotificationsManager();
  return (
    <div className={s.container}>
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          notification={notification}
        />
      ))}
    </div>
  );
}

export default withStyles(s)(Notifications);
