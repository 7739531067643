// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gsuNy{align-items:center;justify-content:center;min-height:calc(100vh - 70px);}@media (max-width: 767.98px){.gsuNy{align-items:flex-start;}}.AU3BJ,.gsuNy{display:flex;width:100%}.AU3BJ{background:#fff;border:1px solid rgba(0,0,0,.01);border-radius:4px;max-width:610px;padding:45px 50px;flex-direction:column;box-shadow:0 4px 4px rgba(101,94,249,.028);}@media (max-width: 767.98px){.AU3BJ{padding-left:20px;padding-bottom:30px;padding-right:20px;padding-top:30px;}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parentForm": `gsuNy`,
	"form": `AU3BJ`
};
export default ___CSS_LOADER_EXPORT___;
