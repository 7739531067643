// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v5h28{display:inline-block;animation:seA5Z 1.5s infinite;animation-direction:alternate;animation-timing-function:ease-in-out}@keyframes seA5Z{0%{transform:rotate(0deg)}to{transform:rotate(750deg)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animating": `v5h28`,
	"a": `seA5Z`
};
export default ___CSS_LOADER_EXPORT___;
