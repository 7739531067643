import { useMemo } from 'react';
import useWindowSize from './useWindowSize';

export default function useBoundingRect(
  ref?: HTMLElement,
): ClientRect | DOMRect {
  const size = useWindowSize();

  return useMemo(() => ref && ref.getBoundingClientRect(), [ref, size]);
}
