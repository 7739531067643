import React from 'react';
import { useIntercom } from 'react-use-intercom';
import withStyles from 'utils/withStyles';
import { useTranslation } from 'react-i18next';

import { NavLink } from 'react-router-dom';
import { useUser } from '../../../hooks';
import s from './Footer.css';
import Link from '../Link';

const Footer = () => {
  const { t } = useTranslation();
  const { show } = process.env.BROWSER ? useIntercom() : {} as any;
  const currentUser = useUser();

  return (
    <footer id="footer" className={s.footer}>
      <ul className={s.links}>
        <li>
          <NavLink className={s.link} activeClassName={s.active} to="/faq">
            {t('FAQ')}
          </NavLink>
        </li>
        <li>
          <NavLink className={s.link} activeClassName={s.active} to="/terms">
            {t('Условия использования')}
          </NavLink>
        </li>
        <li>
          <NavLink className={s.link} activeClassName={s.active} to="/privacy">
            {t('Политика конфиденциальности')}
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={(evt) => {
              if (currentUser) {
                show();
                evt.preventDefault();
              }
            }}
            to="/contact"
            activeClassName={s.active}
            className={s.link}
            href=""
          >
            {t('Написать нам')}
          </NavLink>
        </li>
      </ul>
    </footer>
  );
};

export default withStyles(s)(Footer);
