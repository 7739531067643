const mod = import(/* webpackChunkName: 'box' */ './session');

// That's a little trick to convert Promise<T> into just T.
const extractPromise = <T = typeof mod>(promise: Promise<T>): T => {
  return null as any;
};

const extractedValue = extractPromise(mod);

let instance: typeof extractedValue;

mod.then(d => {
  instance = d;
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function initializeMiddleware(history) {
  return (await mod).initializeTracking();
}

export async function submitFingerprintIfNecessary() {
  return (await mod).submitFingerprintIfNecessary();
}

export async function getSessionId() {
  return (await mod).getOrCreateSessionId();
}
