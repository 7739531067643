import React, { useEffect, useState, useRef } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import withStyles from 'utils/withStyles';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import useReactRouter from 'use-react-router';

import { isURL } from 'utils/text';
import mutations from 'schemas/mutations.graphql';
import Bell from 'assets/bell.svg';
import Tooltip from 'components/common/Tooltip/Tooltip';
import useTokenManager from 'hooks/useTokenManager';
import { useUser } from 'hooks';
import s from './UserMenu.css';
import { CurrencyAmount } from '../../../ui/CurrencyAmount';

const UserMenu = () => {
  const currentUser = useUser();
  const tokenManager = useTokenManager();
  const notifications = [];

  const account = currentUser ? currentUser.accounts[0] : { balance: 0 };
  const { balance } = account;
  const [notificationsVisibility, setNotificationVisibility] = useState(false);
  const [menuShowed, setMenuShowed] = useState(false);

  const { t } = useTranslation();
  const { history } = useReactRouter();
  const { show } = process.env.BROWSER ? useIntercom() : {} as any;

  const el = useRef(null);
  const bellRef = useRef(null);

  const client = useApolloClient();

  const [logoutMutation] = useMutation(mutations.LogoutMutation);

  const logout = async () => {
    tokenManager.removeToken();
    if (navigator && navigator.credentials) {
      try {
        navigator.credentials.preventSilentAccess();
      } catch (e) {}
    }
    history.push('/');

    await logoutMutation();
    await client.resetStore();
    await client.clearStore();
  };

  useEffect(() => {
    if (!menuShowed) {
      return () => {};
    }

    const handler = (event: Event) => {
      if (event && event.target) {
        event.preventDefault();
        if (el.current && !el.current.contains(event.target)) {
          setMenuShowed(false);
        }
      }
    };

    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, [menuShowed]);

  if (!currentUser) {
    return null;
  }

  const avatar = isURL(s.avatar) ? s.avatar : null;

  return (
    <div className={s.container}>
      <div className={s.visible}>
        {notifications && notifications.length ? (
          <div
            ref={bellRef}
            className={s.bell}
            onClick={() => setNotificationVisibility(!notificationsVisibility)}
          >
            <Bell />
            {notifications.filter((n) => !n.red).length ? (
              <span>{notifications.filter((n) => !n.red).length}</span>
            ) : null}
          </div>
        ) : null}
        <div className={s.balance}>
          <Tooltip
            width={300}
            text={t('Сумма доступная для снятия')}
          >
            <span className={s.totalBalance}>
              <CurrencyAmount amount={balance} symbolPosition="right" />
            </span>
          </Tooltip>
        </div>
        <div
          className={s.expand}
          onClick={() => setMenuShowed(!menuShowed)}
          style={{ height: '100%', display: 'flex', alignItems: 'center' }}
        >
          <div
            className={avatar}
            style={{ backgroundImage: `url(${currentUser.avatar})` }}
          />
          <div
            className={s.clickArea}
            ref={el}
          >
            <div className={cx(s.arrow, { [s.arrowShowed]: menuShowed })} />
          </div>
        </div>
      </div>
      <ul
        className={cx(s.menu, { [s.menuHidden]: !menuShowed })}
        aria-expanded={menuShowed}
      >
        <li>
          <Link to="/payments" activeClassName={s.active}>
            {t('Вывести средства')}
          </Link>
        </li>
        <li>
          <Link
            onClick={(evt) => {
              if (currentUser) {
                show();
                evt.preventDefault();
              }
            }}
            to="/contact"
            activeClassName={s.active}
          >
            {t('Написать нам')}
          </Link>
        </li>
        <li>
          <a target="_blank" href="http://bit.ly/youthink-fb" rel="nofollow noopener noreferrer">
            {t('Отзывы')}
          </a>
        </li>
        <li>
          <a onClick={() => logout()}>
            {t('Выйти')}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default withStyles(s)(UserMenu);
