import React from 'react';
import { withRipple } from '@material/react-ripple';

// Edge 15 and earlier doesn't work very well with ripple effect.
// Hence we disable it on old browsers
export function withOptionalRipple<P>(
  WrappedComponent: React.ComponentType<P>,
) {
  if (process.env.ES5_BUILD) {
    return WrappedComponent;
  }

  return withRipple(WrappedComponent);
}
