// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k5a9E{display:inline-flex;position:relative;align-items:center;justify-content:space-between;line-height:inherit;background:transparent;box-sizing:border-box;font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;font-family:var(--system-font);font-weight:600;font-size:1rem;font-size:var(--default-size);letter-spacing:normal;border-radius:5px;background:#506cf0;background:var(--primary-color);color:#EEF2F7;color:var(--white-color);padding:.85rem 1.3rem;cursor:pointer;transition:transform .2s ease, background .2s ease, color .2s ease;outline:none;border:none;-webkit-text-decoration:none;-webkit-text-decoration:none;text-decoration:none;white-space:nowrap;text-align:center;display:inline-block;-webkit-user-select:none;-moz-user-select:none;user-select:none;-webkit-appearance:none;overflow:hidden;vertical-align:middle;--mdc-theme-primary:#fff}.k5a9E:not(:disabled):hover{transform:translateY(-2px);background:#4e69e1;background:var(--primary-color-2)}.k5a9E:not(:disabled):focus{transform:translateY(-2px);background:#4f6bf3;background:var(--one-more-active-color)}.k5a9E:disabled{opacity:.6;cursor:default}.UAnaf{text-transform:capitalize;font-size:1rem;font-size:var(--default-size)}.nErPO{width:100%}.k5a9E.h4Yrr{background:transparent;color:#506cf0;color:var(--primary-color)}.k5a9E.DgVNA{color:#11253B;color:var(--color-typo-primary);font-weight:500;border-radius:3px;box-shadow:0 4px 9px rgba(0,0,0,.142);padding:.6rem 1rem}.k5a9E.DgVNA,.k5a9E.DgVNA:not(:disabled):focus,.k5a9E.DgVNA:not(:disabled):hover{background:#fff;background:var(--white-background)}.k5a9E.h4Yrr:not(:disabled):focus,.k5a9E.h4Yrr:not(:disabled):hover{background:transparent}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `k5a9E`,
	"upper": `UAnaf`,
	"full": `nErPO`,
	"simple": `h4Yrr`,
	"white": `DgVNA`
};
export default ___CSS_LOADER_EXPORT___;
