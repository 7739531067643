import { useEffect } from 'react';

/**
 * A hook to sync internal effects with page focused-blured events.
 * For example, we can stop fetching surveys if we don't have focus.
 * @param inside
 * @param outside
 */
export default function usePageFocusedEffect({ inside, outside }: { inside: any; outside: any; }) {
  useEffect(() => {
    const fn = () => {
      if (document.hidden) {
        outside();
      } else {
        inside();
      }
    };

    document.addEventListener('visibilitychange', fn, false);
    fn();

    return () => document.removeEventListener('visibilitychange', fn);
  }, []);
}
