import React from 'react';
import styled from './styled';

export type LeftRight = 'left' | 'right';

export type Props = {
  currency?: 'RUB';
  amount: number;
  precision?: number;
  symbolPosition?: LeftRight;
};

const defaultOptions = {
  currency: 'RUB',
  precision: 2,
  symbolPosition: 'left' as LeftRight,
};

const Currency = styled.ins<{ position: LeftRight }>`
  font-family: 'Rouble PTSans', Arial, sans-serif;
  opacity: 0.9;
  font-weight: bold;
  text-decoration: none;

  margin-${({ position }) => (position === 'right' ? 'left' : 'right')}: 0.3em;
  margin-${({ position }) => (position === 'right' ? 'left' : 'right')}: 5px;
`;

export function CurrencyAmount(props: Props) {
  const {
    currency, amount, precision, symbolPosition,
  } = { ...defaultOptions, ...props };
  const precisionDegree = 10 ** precision;
  const formatted = (Math.floor(amount * precisionDegree) / precisionDegree).toLocaleString('ru');

  const currencySymbol = (
    <Currency
      data-currency={currency}
      position={symbolPosition}
    >
руб.
    </Currency>
  );

  return (
    <span>
      {symbolPosition === 'left' ? currencySymbol : ''}
      <span data-amount={amount}>{formatted}</span>
      {symbolPosition === 'right' ? currencySymbol : ''}
    </span>
  );
}
