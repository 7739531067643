import { Trans, useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import useFormField from '../../../hooks/useFormField';
import useUser from '../../../hooks/useUser';
import { isEmail } from '../../../utils/text';
import { PopupActions, PopupRow, PopupTitle } from '../Popup/Popup';
import Label from '../../form/Label/Label';
import Input from '../../form/Input/Input';
import Button from '../../form/Button';
import { useChangeEmailMutation } from '../../../queries/queries';
import useNotificationsManager from '../../../hooks/useNotificationsManager';
import { NotificationType } from '../../../store/notifications';

export default function ChangeEmailPopup(props: { onClose: any }) {
  const user = useUser();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { addNotification } = useNotificationsManager();

  const { value, inputFields, isValid } = useFormField(
    user ? user.email : '',
    email => {
      if (!isEmail(email)) {
        throw new Error('Это не похоже на email');
      }
    },
  );

  const [submitter] = useChangeEmailMutation();

  async function submit(event) {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);
    try {
      const {
        data: { changeEmail },
      } = await submitter({
        variables: { newEmail: value },
      });

      if (!changeEmail.isSuccess) {
        addNotification(changeEmail.error.message, NotificationType.Fail);
      } else {
        addNotification(
          t('Мы отправили письмо с подтверждением на новый ящик'),
        );

        props.onClose();
      }
    } catch (e) {
      addNotification(e.error, NotificationType.Fail);
    }

    setLoading(false);
  }

  return (
    <>
      <PopupTitle>
        <Trans>Изменить Email</Trans>
      </PopupTitle>
      <form onSubmit={submit}>
        <PopupRow>
          <Label htmlFor="new-email">
            <Trans>Новый email</Trans>
          </Label>
          <Input
            {...inputFields}
            id="new-email"
            placeholder="me@example.com"
            fullWidth
          />
        </PopupRow>
      </form>
      <PopupActions onClose={props.onClose}>
        <Button disabled={!isValid || isLoading} onClick={submit}>
          <Trans>Изменить</Trans>
        </Button>
      </PopupActions>
    </>
  );
}
