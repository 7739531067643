// Returns width and height
import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

const defaultValues: [number, number] = process.env.BROWSER ? [document.body.offsetWidth, window.innerHeight] : [1000, 1000];

export default function useWindowSize(): [number, number] {
  const [size, setSize] = useState<[number, number]>(defaultValues);

  if (process.env.BROWSER) {
    useEffect(() => {
      const listener = debounce(
        () => setSize([document.body.offsetWidth, window.innerHeight]),
        200,
      );

      window.addEventListener('resize', listener);

      setSize([document.body.offsetWidth, window.innerHeight]);
      return () => window.removeEventListener('resize', listener);
    }, []);
  }

  return size;
}
