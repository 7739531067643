import React from 'react';
import withStyles from 'utils/withStyles';

import s from './PreloaderScreen.css';
import Preloader from '../Preloader/Preloader';

const PreloaderScreen = props => {
  const { size } = props;

  return (
    <div className={s.root}>
      <Preloader size={size} />
    </div>
  );
};

export default withStyles(s)(PreloaderScreen);
