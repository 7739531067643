import React from 'react';
import withStyles from 'utils/withStyles';

import s from './Preloader.css';

const Preloader = (props: { size?: number; color?: number }) => {
  const size = props.size || 50;
  const stroke = props.color || '#000';

  return (
    <svg
      className={s.animating}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 10C19 5.02944 14.9706 1 10 1M1 10C1 14.9706 5.02944 19 10 19"
        stroke={String(stroke)}
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default withStyles(s)(Preloader);
