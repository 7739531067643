// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--initial-point:50px}@keyframes wv8Ye{0%{transform:translateY(50px);transform:translateY(var(--initial-point))}to{transform:0}}@keyframes T3moU{0%{width:0}to{width:100%}}.ac1wY{padding:0 16px;display:flex;align-items:center;height:50px;color:#EEF2F7;color:var(--white-color);box-sizing:border-box;width:100%;margin-top:10px;transition:transform .15s ease-in-out;transform:translateY(0);animation:wv8Ye .2s}.lTE8n{background:#7DBB91;background:var(--success-color)}.lTE8n:hover{background:#51a071;background:var(--success-color-2)}.VPLe4{background:#E06D10;background:var(--danger-color-2)}.VPLe4:hover{background:#c9620e;background:var(--danger-color-3)}.fRRCI{fill:currentColor;margin-right:14px}.Jt8YQ{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-size:1.15rem;font-size:var(--medium-size);margin-right:26px}.r19CP a,.r19CP button{margin-right:26px;font-size:.95rem}.r7_bD{position:absolute;right:16px;top:14px;width:23px;height:24px;color:#fff;cursor:pointer}.r7_bD:hover{opacity:.7}.r7_bD:after,.r7_bD:before{position:absolute;left:12px;content:" ";height:24px;width:1px;background-color:#fff;background-color:var(--white-background)}.r7_bD:before{transform:rotate(45deg)}.r7_bD:after{transform:rotate(-45deg)}.XpVPN{transform:translateY(50px);transform:translateY(var(--initial-point))}.xEQpT{position:absolute;left:0;top:0;height:100%;width:30px;background:rgba(0,0,0,.1);animation:T3moU var(--animation-duration) linear}@media (max-width: 767.98px){.r19CP{display:none}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ac1wY`,
	"a": `wv8Ye`,
	"success": `lTE8n`,
	"fail": `VPLe4`,
	"icon": `fRRCI`,
	"text": `Jt8YQ`,
	"buttons": `r19CP`,
	"close": `r7_bD`,
	"closing": `XpVPN`,
	"progress": `xEQpT`,
	"b": `T3moU`
};
export default ___CSS_LOADER_EXPORT___;
