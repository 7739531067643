let isit = true;

// Requires other solution when in server
export default function isInitialRender() {
  return isit;
}

export function notInitialRender() {
  isit = false;
}
