// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sWcks{padding:10px 30px;background:#46a46c;color:#EEF2F7;color:var(--white-color);}@media (max-width: 767.98px){.sWcks{padding-left:10px;padding-bottom:10px;padding-right:10px;padding-top:10px;}}.jZFBl{max-width:1130px;max-width:var(--default-content-width);margin:0 auto;display:flex;align-items:center;flex-wrap:wrap;font-weight:500;}.jZFBl>*+*{margin-left:20px;}@media (max-width: 767.98px){.jZFBl>*+*{margin-left:0;margin-top:5px;}}@media (max-width: 767.98px){.a6C9Q{margin-bottom:5px}button+button{margin-left:5px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `sWcks`,
	"container": `jZFBl`,
	"lead": `a6C9Q`
};
export default ___CSS_LOADER_EXPORT___;
