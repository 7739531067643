import React from 'react';
import { css } from '@emotion/core';
import styled from '../styled';

const baseNotchWidth = '20px';
const notchRadius = '5px';

export type NotchProps = {
  hasError: boolean;
};

const baseNotch = ({ theme, hasError }) => css`
  border-top: 1px solid;
  border-bottom: 1px solid;
  width: ${baseNotchWidth};
  border-color: ${(hasError ? theme.supporting.error : '#EEF2F7')};
  background: #EEF2F7;
  transition: 0.3s background cubic-bezier(0.075, 0.82, 0.165, 1);
  
  .is-focused & {
    background: #f6faff;
  }
`;

const NotchContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  text-align: left;
  pointer-events: none;
`;

const LeadingNotch = styled.div<NotchProps>`
  border-radius: ${notchRadius} 0 0 ${notchRadius};
  border-left: 1px solid;
  border-right: none;
  
  ${props => baseNotch(props)}
`;

const TrailingNotch = styled.div<NotchProps>`
  border-radius: 0px ${notchRadius} ${notchRadius} 0px;
  border-left: none;
  border-right: 1px solid;
  flex-grow: 1;
  
  ${props => baseNotch(props)}
`;

const NotchItself = styled.div<NotchProps>`
  max-width: calc(100% - ${baseNotchWidth} * 2);
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${props => baseNotch(props)}
  
  width: auto;
`;

const NotchLabel = styled.label<{ isFocused: boolean; hasError: boolean }>`
  color: ${({ theme, hasError }) => (hasError ? theme.supporting.error : theme.typography.top)};
  
  transform-origin: 0 50%;
  transition: 0.3s transform cubic-bezier(0.075, 0.82, 0.165, 1);
  will-change: transform;
  transform: none;
  z-index: 1;
  
  .is-focused &, .has-value & {
    transform: translate3d(0, -90%, 0) scale(0.8);
  }
`;

const ErrorText = styled.div`
  position: absolute;
  bottom: -22px;
  left: 0;
  color: ${({ theme }) => theme.supporting.error};
  letter-spacing: 0.4px;
  font-weight: 400;
  text-transform: lowercase;
`;

export function Notch(props: { label: string; isFocused: boolean; isOpened: boolean; id?: string; error?: string }) {
  const {
    label, isFocused, isOpened, id, error,
  } = props;

  return (
    <NotchContainer>
      <LeadingNotch hasError={!!error} />
      <NotchItself hasError={!!error}>
        <NotchLabel htmlFor={id} isFocused={isOpened} hasError={!!error}>{label}</NotchLabel>
      </NotchItself>
      <TrailingNotch hasError={!!error} />
      {error ? <ErrorText>{error}</ErrorText> : null}
    </NotchContainer>
  );
}
