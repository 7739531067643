import React from 'react';
import withStyles from 'utils/withStyles';

import Content from 'components/common/Content/Content';
import { useTranslation } from 'react-i18next';
import s from './LegalPage.css';
import Page from '../../../pages';
import ReadableText from '../ReadableText';
import Footer from '../Footer/Footer';
import PageTitle from '../PageTitle/PageTitle';

interface Props {
  page: string;
  title: string;
}

function Privacy({ page, title }: Props) {
  const { t } = useTranslation();

  return (
    <Content className={s.container}>
      <PageTitle>{t(title)}</PageTitle>
      <div className={s.content}>
        <ReadableText>
          <Page page={page} />
        </ReadableText>
      </div>
      <Footer />
    </Content>
  );
}

export default withStyles(s)(Privacy);
