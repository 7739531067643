import React, {
  useContext,
  useEffect,
  ComponentType,
  useMemo,
} from 'react';
import StyleContext from 'isomorphic-style-loader/dist/StyleContext';

export default function withStyles(...styles):
  <P extends object>(WrappedComponent: ComponentType<P>) => ComponentType<P> {
  return <P extends object>(WrappedComponent: React.ComponentType<P>)
  : ComponentType<P> => (props: P) => {
    const context = useContext<any>(StyleContext);
    const canceller = useMemo(() => context.insertCss(...styles), []);

    useEffect(() => () => canceller(), []);

    return <WrappedComponent {...props} />;
  };
}
