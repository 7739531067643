// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.S36JK{position:relative;font-size:1.15rem;font-size:var(--medium-size);line-height:1.6;word-break:break-word;}.S36JK h1{font-weight:600;font-size:1.7rem}.S36JK div,.S36JK p,.S36JK ul{margin-bottom:20px}.S36JK ul{list-style-type:disc;margin-left:20px;}.S36JK ul li{margin-bottom:10px}.S36JK table{border-collapse:separate;border-spacing:0 5px;font-size:15px;table-layout:auto;width:100%}.S36JK thead,.S36JK thead tr{border-bottom:1px solid #000}.S36JK tbody td:first-child,.S36JK tbody td:nth-child(3),.S36JK thead th:first-child,.S36JK thead th:nth-child(3){white-space:nowrap}.S36JK tbody tr{border-bottom:1px solid #000}.S36JK tbody tr td{padding:5px;text-align:left}.S36JK th{text-align:left;border-bottom:1px solid #000;font-weight:500;opacity:.8}.S36JK div:has(table){overflow:auto;}.S36JK div:has(table) table{min-width:800px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `S36JK`
};
export default ___CSS_LOADER_EXPORT___;
