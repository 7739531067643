import styled from 'ui/styled';

const H2 = styled.h2<{}>`
  font-size: 1.7rem;
  line-height: 1.3;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.typography.top};
`;

export { H2 };
