
    var refs = 0;
    var getCss = (css) => css.default;
    var css = getCss(require("!!../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/.pnpm/postcss-loader@8.1.1_postcss@8.4.35_typescript@3.9.10_webpack@5.90.3/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!./Notification.css"));
    var insertCss = require("!../../../../node_modules/.pnpm/github.com+Blitss+isomorphic-style-loader@a067004df8fc1f1e0137cfaab69ddc34dfc5dfb9_react-dom@_jjpfouvyexmhgllsv7ut35trqa/node_modules/isomorphic-style-loader/dist/insertCss.js");
    var content = typeof css === 'string' ? [[module.id, css, '']] : css;

    exports = module.exports = css.locals || {};
    exports._getContent = function() { return content; };
    exports._getCss = function() { return '' + css; };
    exports._insertCss = function(options) { return insertCss(content, options) };

    // Hot Module Replacement
    // https://webpack.github.io/docs/hot-module-replacement
    // Only activated in browser context
    if (module.hot && typeof window !== 'undefined' && window.document) {
      var removeCss = function() {};
      module.hot.accept("!!../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/.pnpm/postcss-loader@8.1.1_postcss@8.4.35_typescript@3.9.10_webpack@5.90.3/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!./Notification.css", function() {
        css = require("!!../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/.pnpm/postcss-loader@8.1.1_postcss@8.4.35_typescript@3.9.10_webpack@5.90.3/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!./Notification.css");
        content = typeof css === 'string' ? [[module.id, css, '']] : css;
        removeCss = insertCss(content, { replace: true });
      });
      module.hot.dispose(function() { removeCss(); });
    }
  