// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RixV2{border-bottom:1px solid #1a65f8;-webkit-text-decoration:none;-webkit-text-decoration:none;text-decoration:none;color:inherit}.wPFH1{color:#1a65f8}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `RixV2`,
	"colored": `wPFH1`
};
export default ___CSS_LOADER_EXPORT___;
