import React, { useEffect, useState } from 'react';
import useRouter from 'use-react-router';
import useTokenManager from 'hooks/useTokenManager';
import IsomorphicRedirect from 'components/common/IsomorphicRedirect/IsomorphicRedirect';
import PreloaderScreen from 'components/common/PreloaderScreen';
import queryString from 'query-string';

export default function Sso() {
  const [isDone, setDone] = useState(false);
  const { location: { search }, history } = useRouter();
  const tokenManager = useTokenManager();

  useEffect(() => {
    if (!process.env.BROWSER || !search) {
      return;
    }

    const args = queryString.parse(search.slice(1));

    if (!args.id_token) {
      setDone(true);

      return;
    }

    tokenManager.setToken(args.id_token);
    history.push('/tasks');

    setDone(true);
  }, []);

  if (isDone) {
    return (
      <IsomorphicRedirect to="/tasks" />
    );
  }

  return (
    <PreloaderScreen />
  );
}
