// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amBW5{position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,.2);z-index:1;cursor:pointer;opacity:0;transition:opacity .15s ease-in-out}.rYe2c{opacity:1}.zS5Kb{max-width:785px;width:100%;position:fixed;background:#fff;background:var(--white-background);box-shadow:1px 1px 0.9375rem rgba(0, 0, 0, 0.03);box-shadow:var(--box-shadow);z-index:2;transition:transform .4s cubic-bezier(0,.99,0,.99), opacity .15s cubic-bezier(0,.99,0,.99);max-height:100vh;color:#203449;color:var(--body-text-color);display:flex;flex-direction:column;justify-content:space-between;}@media (max-width: 767.98px){.zS5Kb{height:100%;overflow:auto;}}._qfJH{padding:36px 40px;font-size:1.5rem;line-height:1.3}.FjXiQ{padding:0 40px}._7ZxH,.FjXiQ{margin-bottom:20px}.oLH1o{background:rgba(0,0,0,.04);padding:20px 44px;display:flex;justify-content:flex-end}.Mfw9O,.hy0c5{pointer-events:auto;transform:translateZ(0);opacity:1}.Mfw9O{transform:none}.wEeR7,.FanhB{transform:translate3d(0,60px,0);opacity:0}.wEeR7{pointer-events:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": `amBW5`,
	"bgShown": `rYe2c`,
	"popup": `zS5Kb`,
	"title": `_qfJH`,
	"row": `FjXiQ`,
	"innerContent": `_7ZxH`,
	"buttons": `oLH1o`,
	"state-entered": `Mfw9O`,
	"state-entering": `hy0c5`,
	"state-exited": `wEeR7`,
	"state-exiting": `FanhB`
};
export default ___CSS_LOADER_EXPORT___;
