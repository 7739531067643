import styled from 'ui/styled';

const H3 = styled.h3<{}>`
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: ${props => props.theme.typography.middle};
`;

export { H3 };
