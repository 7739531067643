import history from '../clientHistory';

export class SentryStore {
  sentry: any;

  donePromise: Promise<any> = Promise.resolve();

  async load() {
    this.donePromise = new Promise(async (resolve) => {
      if (!process.env.BROWSER) {
        return;
      }

      this.sentry = await import(
        /* webpackChunkName: 'sentry' */ '@sentry/react',
      );

      this.sentry.init({
        dsn: 'https://511395859f12ffed83fbb9188efed5ed@sentry.youthink.dev/4',
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        integrations: [
          this.sentry.browserTracingIntegration(),
          this.sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
          this.sentry.reactRouterV5BrowserTracingIntegration({ history }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/backend\.youthink\.io\/graphql/,
          /^https:\/\/backend\.youthink\.io/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });

      resolve(this.sentry);
    });
  }

  async onceReady<T = any | void>(
    fn: (sentry: any) => any,
  ): Promise<any> {
    await this.donePromise;

    return fn(this.sentry);
  }

  async configureScope(...args: any) {
    await this.donePromise;

    this.sentry.configureScope(...args);
  }
}

const sentryStore = new SentryStore();

export default sentryStore;
