import React from 'react';
import withStyles from 'utils/withStyles';
import cx from 'classnames';

import s from './Label.css';

const Label = (props: React.ComponentProps<'label'> & { error?: string }) => {
  const {
    children, className, error, ...otherProps
  } = props;
  return (
    <label className={cx(s.label, className)} {...otherProps}>
      {children}
      {error && <span className={s.error}>{error}</span>}
    </label>
  );
};

export default withStyles(s)(Label);
