import React, { useState } from 'react';
import tokenManager from 'utils/token';

export const TokenStore = React.createContext(null);

export function TokenProvider(props: { token?: string; children: any }) {
  const [token, setRealToken] = useState<null | string>(props.token || null);

  function setToken(newToken: string | null, setTokenInAppContext = true) {
    if (setTokenInAppContext) {
      setRealToken(newToken);
    }

    // This sets token inside cookies
    if (process.env.BROWSER) {
      tokenManager.setToken(newToken);
    }
  }

  function removeToken() {
    setRealToken(null);

    if (process.env.BROWSER) {
      tokenManager.removeToken();
    }
  }

  const context = {
    token, setToken, hasToken: !!token, removeToken,
  };

  return (
    <TokenStore.Provider value={context}>{props.children}</TokenStore.Provider>
  );
}

export default {
  TokenProvider,
  Store: TokenStore,
};
