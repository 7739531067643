import React, { useState, useRef } from 'react';
import { Transition } from 'react-transition-group';
import withStyles from 'utils/withStyles';
import * as cx from 'classnames';

import useBoundingRect from 'hooks/useBoundingRect';
import useWindowSize from 'hooks/useWindowSize';
import s from './Tooltip.css';

interface Props {
  children?: any;
  text: string;
  width?: number;
  background?: string;
  style?: any;
}

const PAGE_PADDING = 20;

const Tooltip = (props: Props) => {
  const {
    text, children, width, background, style = {},
  } = props;

  const [show, setShow] = useState(false);

  const el = useRef(null);
  const tooltip = useRef(null);

  const [windowWidth, windowHeight] = useWindowSize();

  const bounds = useBoundingRect(el.current);
  const tooltipBounds = useBoundingRect(el.current);

  const tooltipStyles: any = {
    ...style,
    width,
    '--extra-transform': 'translateX(-50%)',
    '--x-after-padding': '0px',
  };

  if (background) {
    tooltipStyles.background = background;
    tooltipStyles['--tooltip-background'] = background;
  }

  if (tooltipBounds && bounds && tooltip.current) {
    const totalWidth = bounds.left + tooltip.current.offsetWidth / 2;
    const isOverflowing = totalWidth > windowWidth - PAGE_PADDING;

    if (isOverflowing) {
      const difference = totalWidth - windowWidth + PAGE_PADDING;

      tooltipStyles['--extra-transform'] = `translateX(calc(-50% + -${Math.abs(
        difference,
      )}px))`;
      tooltipStyles['--x-after-padding'] = `${difference / 3}px`;
    }
  } else {
    tooltipStyles['--extra-transform'] = 'translateX(-50%)';
    tooltipStyles['--x-after-padding'] = 0;
  }

  return (
    <div className={s.container}>
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        ref={el}
      >
        {children}
      </div>
      <Transition timeout={150} in={show} unmountOnExit>
        {state => {
          console.log('state: ', state);
          return (
            <div
              ref={tooltip}
              className={cx(s.tooltip, s[`state-${state}`])}
              style={tooltipStyles}
            >
              {text}
            </div>
          );
        }}
      </Transition>
    </div>
  );
};

export default withStyles(s)(Tooltip);
