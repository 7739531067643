import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  UUID: any,
  Date: any,
  JSON: any,
};


export type AcceptanceResult = {
   __typename?: 'AcceptanceResult',
  inputErrors?: Maybe<Array<Maybe<AcceptanceResultError>>>,
  isValid: Scalars['Boolean'],
  isAccepted: Scalars['Boolean'],
  declineReason?: Maybe<LocalizedString>,
  declineReasonCode?: Maybe<Scalars['String']>,
};

export type AcceptanceResultError = {
   __typename?: 'AcceptanceResultError',
  field?: Maybe<Scalars['String']>,
  errorMessage?: Maybe<LocalizedString>,
  errorCode?: Maybe<Scalars['String']>,
};

export type Account = {
   __typename?: 'Account',
  id?: Maybe<Scalars['UUID']>,
  accountCurrency?: Maybe<Currency>,
  balance?: Maybe<Scalars['Float']>,
  frozen?: Maybe<Scalars['Float']>,
};

export type AddReportResult = {
   __typename?: 'AddReportResult',
  isSuccess?: Maybe<Scalars['Boolean']>,
  error?: Maybe<LocalizedString>,
  errorCode?: Maybe<Scalars['String']>,
  report?: Maybe<ReportNode>,
};

export type AddWalletResult = {
   __typename?: 'AddWalletResult',
  error?: Maybe<LocalizedString>,
  errorCode?: Maybe<Scalars['String']>,
  isSuccess?: Maybe<Scalars['Boolean']>,
  wallet?: Maybe<PayoutWallet>,
};

export type AffiliateStats = {
   __typename?: 'AffiliateStats',
  surveysDone?: Maybe<Scalars['Int']>,
  clicks?: Maybe<Scalars['Int']>,
  registrations?: Maybe<Scalars['Int']>,
  profitOverTime?: Maybe<CurrencyValue>,
};

export type Answer = {
   __typename?: 'Answer',
  id?: Maybe<Scalars['UUID']>,
  text?: Maybe<LocalizedString>,
};

export type CardInfo = {
   __typename?: 'CardInfo',
  isAllowed?: Maybe<Scalars['Boolean']>,
  isKnown?: Maybe<Scalars['Boolean']>,
  cardType?: Maybe<CardTypes>,
  bank?: Maybe<Scalars['String']>,
  baseColor?: Maybe<Scalars['String']>,
  country?: Maybe<CardOrigines>,
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
};

export enum CardOrigines {
  Ru = 'RU',
  Ukr = 'UKR'
}

export enum CardTypes {
  Visa = 'visa',
  Mastercard = 'mastercard',
  Mir = 'mir'
}

export type Coordinates = {
   __typename?: 'Coordinates',
  x: Scalars['Float'],
  y: Scalars['Float'],
};

export type Currency = {
   __typename?: 'Currency',
  type?: Maybe<CurrencyTypes>,
};

export enum CurrencyTypes {
  Rub = 'rub'
}

export type CurrencyValue = {
   __typename?: 'CurrencyValue',
  currencyType?: Maybe<Currency>,
  value?: Maybe<Scalars['Float']>,
};


export type Formatter = {
   __typename?: 'Formatter',
  type?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
};

export type GeoIp = {
   __typename?: 'GeoIp',
  country?: Maybe<LocalizedString>,
  countryIsoCode?: Maybe<Scalars['String']>,
  city?: Maybe<LocalizedString>,
  isAvailableToRegister?: Maybe<Scalars['Boolean']>,
  locale?: Maybe<Scalars['String']>,
  isLocaleEnforced?: Maybe<Scalars['Boolean']>,
};

export type GivenAnswer = {
   __typename?: 'GivenAnswer',
  question?: Maybe<Question>,
  answer?: Maybe<Answer>,
  answers?: Maybe<Array<Maybe<Answer>>>,
  text?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['Date']>,
};

export enum GroupableFields {
  Date = 'date'
}

export type HelpRequest = {
  email: Scalars['String'],
  firstName: Scalars['String'],
  request: Scalars['String'],
};


export enum Language {
  En = 'en',
  Ru = 'ru'
}

export type LocaleString = {
   __typename?: 'LocaleString',
  id?: Maybe<Scalars['Float']>,
  key?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
};

export type LocalizedString = {
   __typename?: 'LocalizedString',
  id?: Maybe<Scalars['UUID']>,
  message?: Maybe<Scalars['String']>,
};

export enum LoginErrorCodes {
  InvalidCaptchaResponse = 'invalidCaptchaResponse',
  RequestLimitted = 'requestLimitted',
  InvalidEmail = 'invalidEmail',
  InvalidLoginPassword = 'invalidLoginPassword',
  BlockedUser = 'blockedUser',
  InternalError = 'internalError',
  NoPasswordSet = 'noPasswordSet',
  RestoreTokenInvalid = 'restoreTokenInvalid'
}

export type LoginResult = {
   __typename?: 'LoginResult',
  errorCode?: Maybe<LoginErrorCodes>,
  errorMessage?: Maybe<LocalizedString>,
  isSuccess?: Maybe<Scalars['Boolean']>,
  jwtToken?: Maybe<Scalars['String']>,
};

export type LookupCountryResult = {
   __typename?: 'LookupCountryResult',
  id: Scalars['Float'],
  name_ru: Scalars['String'],
  name_en: Scalars['String'],
  geonameId: Scalars['Float'],
  countryIsoCode: Scalars['String'],
};

export type LookupResult = {
   __typename?: 'LookupResult',
  location?: Maybe<Coordinates>,
  country?: Maybe<LookupCountryResult>,
  subdivision?: Maybe<PlaceNode>,
  city?: Maybe<PlaceNode>,
};

export type MainCurrency = {
   __typename?: 'MainCurrency',
  id: Scalars['ID'],
  plurals?: Maybe<Array<Scalars['String']>>,
  symbol?: Maybe<Scalars['String']>,
  emoji?: Maybe<Scalars['String']>,
  rubleExchangeRate: Scalars['Float'],
  dollarExchangeRate: Scalars['Float'],
};

export type MakeLookup = {
   __typename?: 'MakeLookup',
  getZipCode?: Maybe<PostalNode>,
  isValidZipCode: Scalars['Boolean'],
  suggestions?: Maybe<Array<Suggestion>>,
};

export type MicroTransaction = {
   __typename?: 'MicroTransaction',
  id?: Maybe<Scalars['UUID']>,
  credited?: Maybe<Scalars['Boolean']>,
  isRevertedReport?: Maybe<Scalars['Boolean']>,
};

export type Mutation = {
   __typename?: 'Mutation',
  updateNotificationPreferences?: Maybe<PureAction>,
  updateNotificationKey?: Maybe<PureAction>,
  addNotificationChannel: PureAction,
  removeNotificationChannel: PureAction,
  createReport?: Maybe<AddReportResult>,
  removePayoutWallet?: Maybe<PureAction>,
  addPayoutWallet?: Maybe<AddWalletResult>,
  validatePayoutWallet?: Maybe<AddWalletResult>,
  requestPayout?: Maybe<RequestPayoutResult>,
  setAnswerToQuestion?: Maybe<PureAction>,
  submitMissingLocaleKey?: Maybe<PureAction>,
  resendValidationEmail?: Maybe<PureAction>,
  activateSecondFlow?: Maybe<PureAction>,
  signIn?: Maybe<LoginResult>,
  register?: Maybe<RegisterResult>,
  restorePassword?: Maybe<RestoreResult>,
  submitSecondFlow: AcceptanceResult,
  changePassword?: Maybe<LoginResult>,
  logout?: Maybe<PureAction>,
  confirmEmail: PureAction,
  changeEmail: PureAction,
  trackEvent?: Maybe<PureAction>,
  removeAccount: PureAction,
  reverseAccountRemoval: PureAction,
  submitHelpRequest: PureAction,
};


export type MutationUpdateNotificationPreferencesArgs = {
  id: Scalars['String'],
  channel: NotifyChannels,
  isEnabled: Scalars['Boolean']
};


export type MutationUpdateNotificationKeyArgs = {
  subscription: Scalars['JSON']
};


export type MutationAddNotificationChannelArgs = {
  type: NotifyChannels,
  data: Scalars['String']
};


export type MutationRemoveNotificationChannelArgs = {
  type: NotifyChannels,
  data: Scalars['String']
};


export type MutationCreateReportArgs = {
  report?: Maybe<ReportNodeInput>
};


export type MutationRemovePayoutWalletArgs = {
  id: Scalars['UUID']
};


export type MutationAddPayoutWalletArgs = {
  id: Scalars['String'],
  data: Scalars['JSON']
};


export type MutationValidatePayoutWalletArgs = {
  id: Scalars['String'],
  data: Scalars['JSON']
};


export type MutationRequestPayoutArgs = {
  payout: PayoutInput
};


export type MutationSetAnswerToQuestionArgs = {
  question: Scalars['Float'],
  answer: Scalars['JSON']
};


export type MutationSubmitMissingLocaleKeyArgs = {
  key: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  fallback?: Maybe<Scalars['String']>
};


export type MutationSignInArgs = {
  email: Scalars['String'],
  password: Scalars['String'],
  recaptchaResponse?: Maybe<Scalars['String']>
};


export type MutationRegisterArgs = {
  request: RegisterRequest
};


export type MutationRestorePasswordArgs = {
  email: Scalars['String'],
  recaptchaResponse?: Maybe<Scalars['String']>
};


export type MutationSubmitSecondFlowArgs = {
  data: SecondFlowInput
};


export type MutationChangePasswordArgs = {
  userId?: Maybe<Scalars['String']>,
  restoreToken: Scalars['String'],
  newPassword?: Maybe<Scalars['String']>
};


export type MutationConfirmEmailArgs = {
  email: Scalars['String']
};


export type MutationChangeEmailArgs = {
  newEmail: Scalars['String']
};


export type MutationTrackEventArgs = {
  event: Scalars['String']
};


export type MutationRemoveAccountArgs = {
  reason: Array<Maybe<Scalars['String']>>
};


export type MutationSubmitHelpRequestArgs = {
  request: HelpRequest
};

export type NameFormatResult = {
   __typename?: 'NameFormatResult',
  name?: Maybe<Scalars['String']>,
  gender?: Maybe<Scalars['String']>,
  surname?: Maybe<Scalars['String']>,
};

export type Notification = {
   __typename?: 'Notification',
  id?: Maybe<Scalars['String']>,
  name?: Maybe<LocalizedString>,
  value?: Maybe<LocalizedString>,
  details?: Maybe<Scalars['JSON']>,
  red?: Maybe<Scalars['Boolean']>,
  type?: Maybe<NotificationTypes>,
};

export enum NotificationTypes {
  Danger = 'danger',
  Success = 'success',
  Neutral = 'neutral'
}

export enum NotifyChannels {
  Push = 'push',
  Email = 'email',
  Vk = 'vk'
}

export type NotifySections = {
   __typename?: 'NotifySections',
  id?: Maybe<Scalars['String']>,
  when?: Maybe<Scalars['String']>,
  name?: Maybe<LocalizedString>,
  isSubscribedWithPushes?: Maybe<Scalars['Boolean']>,
  isSubscribedWithEmail?: Maybe<Scalars['Boolean']>,
};

export enum OperationType {
  Referral = 'referral',
  Survey = 'survey',
  Other = 'other',
  Payout = 'payout'
}

export type Payout = {
   __typename?: 'Payout',
  id?: Maybe<Scalars['UUID']>,
  createdAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['String']>,
  destination?: Maybe<PayoutWallet>,
  source?: Maybe<Account>,
  amount?: Maybe<Scalars['Float']>,
  amountDue?: Maybe<Scalars['Float']>,
  status?: Maybe<PayoutStatuses>,
  errorReason?: Maybe<Scalars['String']>,
};

export type PayoutInput = {
  payoutWallet: Scalars['UUID'],
  amount: Scalars['Float'],
};

export type PayoutMethod = {
   __typename?: 'PayoutMethod',
  id?: Maybe<Scalars['String']>,
  name?: Maybe<LocalizedString>,
  minimalPayout?: Maybe<CurrencyValue>,
  isDisabled?: Maybe<Scalars['Boolean']>,
  fee?: Maybe<Scalars['Float']>,
  ui?: Maybe<PayoutUiProps>,
  currentWallet?: Maybe<PayoutWallet>,
  config?: Maybe<Scalars['JSON']>,
};

export enum PayoutStatuses {
  Pending = 'pending',
  Error = 'error',
  Success = 'success',
  Suspended = 'suspended',
  Cancelled = 'cancelled'
}

export type PayoutUiProps = {
   __typename?: 'PayoutUiProps',
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  label?: Maybe<Scalars['String']>,
  placeholder?: Maybe<Scalars['String']>,
  icon?: Maybe<Scalars['String']>,
  formatter?: Maybe<Formatter>,
};

export type PayoutWallet = {
   __typename?: 'PayoutWallet',
  id?: Maybe<Scalars['UUID']>,
  method?: Maybe<PayoutMethod>,
  createdAt?: Maybe<Scalars['Date']>,
  data?: Maybe<Scalars['JSON']>,
  isConfirmed?: Maybe<Scalars['Boolean']>,
  cardInfo?: Maybe<Scalars['JSON']>,
};

export type PlaceNode = {
   __typename?: 'PlaceNode',
  id: Scalars['ID'],
  type: Scalars['String'],
  kind?: Maybe<Scalars['String']>,
  name_ru?: Maybe<Scalars['String']>,
  name_en?: Maybe<Scalars['String']>,
  population?: Maybe<Scalars['Float']>,
  official_status?: Maybe<Scalars['String']>,
  ref?: Maybe<Scalars['String']>,
  aliases?: Maybe<Array<Scalars['String']>>,
  osm_id: Scalars['String'],
  city_name?: Maybe<Scalars['String']>,
  country_id?: Maybe<Scalars['Float']>,
  federal_district_id?: Maybe<Scalars['Float']>,
  subdivision_id?: Maybe<Scalars['Float']>,
  division_id?: Maybe<Scalars['Float']>,
  city_id?: Maybe<Scalars['Float']>,
  parent?: Maybe<Scalars['Float']>,
  economic_district_id?: Maybe<Scalars['Float']>,
  admin_level?: Maybe<Scalars['Float']>,
};

export type PostalNode = {
   __typename?: 'PostalNode',
  id: Scalars['ID'],
  country: Scalars['String'],
  referenced_ids: Array<Scalars['Float']>,
  places: Array<PlaceNode>,
  preferredName: Scalars['String'],
};

export type Project = {
   __typename?: 'Project',
  name?: Maybe<LocalizedString>,
  id?: Maybe<Scalars['String']>,
  taskName?: Maybe<Scalars['String']>,
};

export type PureAction = {
   __typename?: 'PureAction',
  errorCode?: Maybe<Scalars['String']>,
  error?: Maybe<LocalizedString>,
  isSuccess?: Maybe<Scalars['Boolean']>,
};

export type Qa = {
   __typename?: 'QA',
  id?: Maybe<Scalars['Float']>,
  name?: Maybe<LocalizedString>,
  text?: Maybe<LocalizedString>,
};

export type Query = {
   __typename?: 'Query',
  vapidKey?: Maybe<Scalars['String']>,
  payoutMethods: Array<PayoutMethod>,
  brandByBin?: Maybe<CardInfo>,
  questionCategories?: Maybe<Array<Maybe<QuestionCategory>>>,
  questionCategory?: Maybe<QuestionCategory>,
  question?: Maybe<Question>,
  questionSuggestion?: Maybe<Array<Maybe<Answer>>>,
  currentUser?: Maybe<User>,
  faq: Array<Qa>,
  searchAddress: MakeLookup,
  locationInfo?: Maybe<GeoIp>,
  task?: Maybe<Task>,
  termById?: Maybe<Term>,
  getLocaleData?: Maybe<Array<Maybe<LocaleString>>>,
  isEmailAvailable: PureAction,
  restoreToken: PureAction,
  appConfig?: Maybe<Scalars['JSON']>,
  removalReasons: Array<RemovalReason>,
};


export type QueryBrandByBinArgs = {
  bin: Scalars['String']
};


export type QueryQuestionCategoryArgs = {
  id: Scalars['Float']
};


export type QueryQuestionArgs = {
  id: Scalars['Float']
};


export type QueryQuestionSuggestionArgs = {
  question: Scalars['Float'],
  answer: Scalars['JSON']
};


export type QuerySearchAddressArgs = {
  search: Scalars['String']
};


export type QueryTaskArgs = {
  userId: Scalars['String']
};


export type QueryTermByIdArgs = {
  id: Scalars['Float']
};


export type QueryGetLocaleDataArgs = {
  locale?: Maybe<Language>
};


export type QueryIsEmailAvailableArgs = {
  email: Scalars['String']
};


export type QueryRestoreTokenArgs = {
  userId?: Maybe<Scalars['String']>,
  restoreToken: Scalars['String']
};

export type Question = {
   __typename?: 'Question',
  id?: Maybe<Scalars['Float']>,
  code?: Maybe<Scalars['String']>,
  name?: Maybe<LocalizedString>,
  inputProps?: Maybe<Scalars['JSON']>,
  questionType?: Maybe<QuestionTypes>,
  currentAnswer?: Maybe<GivenAnswer>,
  hasAutosuggestion?: Maybe<Scalars['Boolean']>,
  category?: Maybe<QuestionCategory>,
  picks?: Maybe<Array<Maybe<Answer>>>,
};

export type QuestionCategory = {
   __typename?: 'QuestionCategory',
  id?: Maybe<Scalars['Float']>,
  code?: Maybe<Scalars['String']>,
  name?: Maybe<LocalizedString>,
  isRewardAffiliated?: Maybe<Scalars['Boolean']>,
  isRewardGiven?: Maybe<Scalars['Boolean']>,
  reward?: Maybe<CurrencyValue>,
  unansweredQuestionsLength?: Maybe<Scalars['Int']>,
  questionsLength?: Maybe<Scalars['Int']>,
  questions?: Maybe<Array<Maybe<Question>>>,
};

export enum QuestionTypes {
  Select = 'select',
  Multiselect = 'multiselect',
  String = 'string',
  Date = 'date',
  Numeric = 'numeric',
  Pick = 'pick',
  Picks = 'picks'
}

export enum RegisterErrorCodes {
  InvalidCaptchaResponse = 'invalidCaptchaResponse',
  RequestLimitted = 'requestLimitted',
  InvalidEmail = 'invalidEmail',
  LoginTaken = 'loginTaken',
  EmailTaken = 'emailTaken',
  PasswordInsecure = 'passwordInsecure',
  FraudDetection = 'fraudDetection',
  InternalError = 'internalError'
}

export type RegisterRequest = {
  email: Scalars['String'],
  password: Scalars['String'],
  recaptchaResponse?: Maybe<Scalars['String']>,
  session?: Maybe<Scalars['String']>,
  subscribeToSurveys?: Maybe<Scalars['Boolean']>,
  shouldUseNewFlow?: Maybe<Scalars['Boolean']>,
};

export type RegisterResult = {
   __typename?: 'RegisterResult',
  errorCode?: Maybe<Scalars['String']>,
  errorMessage?: Maybe<LocalizedString>,
  isSuccess?: Maybe<Scalars['Boolean']>,
  jwtToken?: Maybe<Scalars['String']>,
};

export type RemovalReason = {
   __typename?: 'RemovalReason',
  id: Scalars['String'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  customReason?: Maybe<Scalars['Boolean']>,
};

export type ReportEdge = {
   __typename?: 'ReportEdge',
  totalAmount?: Maybe<CurrencyValue>,
  totalApproved?: Maybe<Scalars['Int']>,
  total?: Maybe<Scalars['Int']>,
  reports?: Maybe<Array<Maybe<ReportNode>>>,
};

export type ReportNode = {
   __typename?: 'ReportNode',
  id?: Maybe<Scalars['UUID']>,
  type?: Maybe<OperationType>,
  outerOperationName?: Maybe<LocalizedString>,
  createdAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  amount?: Maybe<Scalars['Float']>,
  status?: Maybe<ReportStatuses>,
  rejectionReason?: Maybe<Scalars['String']>,
};

export type ReportNodeInput = {
  type?: Maybe<OperationType>,
  operationName?: Maybe<Scalars['String']>,
  user?: Maybe<Scalars['UUID']>,
  amount?: Maybe<Scalars['Float']>,
  status?: Maybe<ReportStatuses>,
  rejectionReason?: Maybe<Scalars['String']>,
  applyReferral?: Maybe<Scalars['Boolean']>,
};

export enum ReportStatuses {
  Pending = 'pending',
  Approved = 'approved',
  Closed = 'closed',
  Mistarget = 'mistarget',
  Other = 'other',
  Invite = 'invite'
}

export type RequestPayoutResult = {
   __typename?: 'RequestPayoutResult',
  error?: Maybe<LocalizedString>,
  errorCode?: Maybe<Scalars['String']>,
  isSuccess?: Maybe<Scalars['Boolean']>,
  payout?: Maybe<Payout>,
};

export type RestoreResult = {
   __typename?: 'RestoreResult',
  errorCode?: Maybe<LoginErrorCodes>,
  errorMessage?: Maybe<LocalizedString>,
  isSuccess?: Maybe<Scalars['Boolean']>,
  restoreToken?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

export type SecondFlowInput = {
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  zipCode: Scalars['Float'],
  birthDate: Scalars['String'],
  fpOutput?: Maybe<Scalars['JSON']>,
  fpVisitorId?: Maybe<Scalars['String']>,
  specifiedAddress?: Maybe<Scalars['String']>,
  wantToSubscribe: Scalars['Boolean'],
};

export type Subscription = {
   __typename?: 'Subscription',
  onReportUpdated?: Maybe<ReportNode>,
  onAnswerUpdated?: Maybe<GivenAnswer>,
  onAccountUpdated?: Maybe<Account>,
  onTasksUpdated?: Maybe<Array<Maybe<Task>>>,
  onAppUpdated?: Maybe<PureAction>,
  onNewNotification?: Maybe<Notification>,
  onUserUpdated?: Maybe<User>,
};

export type Suggestion = {
   __typename?: 'Suggestion',
  value: Scalars['String'],
  postal_code: Scalars['Float'],
  isPrecise: Scalars['Boolean'],
};

export type Task = {
   __typename?: 'Task',
  id?: Maybe<Scalars['UUID']>,
  name?: Maybe<LocalizedString>,
  description?: Maybe<LocalizedString>,
  isImportant?: Maybe<Scalars['Boolean']>,
  isDailySurvey?: Maybe<Scalars['Boolean']>,
  kind?: Maybe<TaskKind>,
  isVisitedEarly?: Maybe<Scalars['Boolean']>,
  incidenceRate?: Maybe<Scalars['Float']>,
  cr?: Maybe<Scalars['Float']>,
  remainingCompletes?: Maybe<Scalars['Float']>,
  dismissible?: Maybe<Scalars['Boolean']>,
  project?: Maybe<Project>,
  type?: Maybe<TaskTypes>,
  reward?: Maybe<CurrencyValue>,
  buttonText?: Maybe<Scalars['String']>,
  buttonLink?: Maybe<Scalars['String']>,
  specialActionType?: Maybe<Scalars['String']>,
  averageTime?: Maybe<Scalars['Int']>,
};

export type TaskKind = {
   __typename?: 'TaskKind',
  id?: Maybe<TaskKinds>,
  name?: Maybe<LocalizedString>,
  fill?: Maybe<Scalars['String']>,
  helpPopup?: Maybe<Scalars['String']>,
};

export enum TaskKinds {
  SurveyInvite = 'surveyInvite',
  Profile = 'profile',
  DailySurvey = 'dailySurvey',
  Ad = 'ad'
}

export enum TaskTypes {
  Advertisement = 'advertisement',
  Action = 'action',
  Survey = 'survey',
  SurveyPartner = 'surveyPartner'
}

export type Term = {
   __typename?: 'Term',
  id?: Maybe<Scalars['Float']>,
  header?: Maybe<Scalars['String']>,
  subheader?: Maybe<Scalars['String']>,
};

export type TransactionReportAggregate = {
   __typename?: 'TransactionReportAggregate',
  id?: Maybe<Scalars['UUID']>,
  type?: Maybe<OperationType>,
  operationName?: Maybe<Scalars['String']>,
  transactionTime?: Maybe<Scalars['Date']>,
  amount?: Maybe<Scalars['Float']>,
  status?: Maybe<ReportStatuses>,
  transaction?: Maybe<MicroTransaction>,
};

export type TransactionStats = {
   __typename?: 'TransactionStats',
  totalAmount?: Maybe<CurrencyValue>,
  totalApproved?: Maybe<Scalars['Int']>,
  total?: Maybe<Scalars['Int']>,
};

export type User = {
   __typename?: 'User',
  affiliateStats?: Maybe<AffiliateStats>,
  reports?: Maybe<ReportEdge>,
  payouts: Array<Maybe<Payout>>,
  payoutWallets: Array<Maybe<PayoutWallet>>,
  id?: Maybe<Scalars['UUID']>,
  avatar?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  birthDate?: Maybe<Scalars['Date']>,
  zipCode?: Maybe<Scalars['Float']>,
  country?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  state?: Maybe<UserState>,
  referralCode?: Maybe<Scalars['String']>,
  isAccountConfirmed?: Maybe<Scalars['Boolean']>,
  isAwaitingSecondFlow?: Maybe<Scalars['Boolean']>,
  accounts?: Maybe<Array<Maybe<Account>>>,
  tasks?: Maybe<Array<Maybe<Task>>>,
  notificationSections?: Maybe<Array<Maybe<NotifySections>>>,
  notifications?: Maybe<Array<Maybe<Notification>>>,
  unredNotifications?: Maybe<Scalars['Int']>,
  transactionStats?: Maybe<TransactionStats>,
  transactionReports?: Maybe<Array<Maybe<TransactionReportAggregate>>>,
  experiments?: Maybe<Array<Maybe<Scalars['String']>>>,
};


export type UserTransactionReportsArgs = {
  after?: Maybe<Scalars['String']>
};

export enum UserState {
  ConfirmingEmail = 'confirmingEmail',
  Activated = 'activated',
  Inactive = 'inactive',
  ScheduledForRemoval = 'scheduledForRemoval',
  Blocked = 'blocked',
  Removed = 'removed',
  SecondFlowAwaiting = 'secondFlowAwaiting',
  Declined = 'declined'
}


export type PayoutMutFragment = (
  { __typename?: 'Payout' }
  & Pick<Payout, 'id' | 'createdAt' | 'status' | 'amountDue' | 'errorReason'>
  & { destination: Maybe<(
    { __typename?: 'PayoutWallet' }
    & { method: Maybe<(
      { __typename?: 'PayoutMethod' }
      & { name: Maybe<(
        { __typename?: 'LocalizedString' }
        & Pick<LocalizedString, 'message'>
      )> }
    )> }
  )> }
);

export type NewRegisterMutationMutationVariables = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type NewRegisterMutationMutation = (
  { __typename?: 'Mutation' }
  & { result: Maybe<(
    { __typename?: 'RegisterResult' }
    & Pick<RegisterResult, 'errorCode' | 'isSuccess' | 'jwtToken'>
    & { errorMessage: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type RegisterMutationMutationVariables = {
  request: RegisterRequest
};


export type RegisterMutationMutation = (
  { __typename?: 'Mutation' }
  & { register: Maybe<(
    { __typename?: 'RegisterResult' }
    & Pick<RegisterResult, 'errorCode' | 'isSuccess' | 'jwtToken'>
    & { errorMessage: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type ActivateSecondFlowMutationVariables = {};


export type ActivateSecondFlowMutation = (
  { __typename?: 'Mutation' }
  & { activateSecondFlow: Maybe<(
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'isSuccess' | 'errorCode'>
  )> }
);

export type ChangeEmailMutationVariables = {
  newEmail: Scalars['String']
};


export type ChangeEmailMutation = (
  { __typename?: 'Mutation' }
  & { changeEmail: (
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'errorCode' | 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  ) }
);

export type LoginMutationMutationVariables = {
  email: Scalars['String'],
  password: Scalars['String'],
  recaptchaResponse?: Maybe<Scalars['String']>
};


export type LoginMutationMutation = (
  { __typename?: 'Mutation' }
  & { result: Maybe<(
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'errorCode' | 'isSuccess' | 'jwtToken'>
    & { errorMessage: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type RestorePasswordMutationMutationVariables = {
  email: Scalars['String']
};


export type RestorePasswordMutationMutation = (
  { __typename?: 'Mutation' }
  & { restorePassword: Maybe<(
    { __typename?: 'RestoreResult' }
    & Pick<RestoreResult, 'errorCode' | 'isSuccess'>
  )> }
);

export type ChangePasswordMutationMutationVariables = {
  userId?: Maybe<Scalars['String']>,
  restoreToken: Scalars['String'],
  newPassword: Scalars['String']
};


export type ChangePasswordMutationMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: Maybe<(
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'errorCode' | 'isSuccess' | 'jwtToken'>
    & { errorMessage: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type ResendValidationEmailMutationMutationVariables = {};


export type ResendValidationEmailMutationMutation = (
  { __typename?: 'Mutation' }
  & { resendValidationEmail: Maybe<(
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'errorCode' | 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type LogoutMutationMutationVariables = {};


export type LogoutMutationMutation = (
  { __typename?: 'Mutation' }
  & { logout: Maybe<(
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'errorCode' | 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type UpdateNotificationPreferencesMutationVariables = {
  id: Scalars['String'],
  channel: NotifyChannels,
  isEnabled: Scalars['Boolean']
};


export type UpdateNotificationPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationPreferences: Maybe<(
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'errorCode' | 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type SubmitSecondFlowMutationVariables = {
  request: SecondFlowInput
};


export type SubmitSecondFlowMutation = (
  { __typename?: 'Mutation' }
  & { submitSecondFlow: (
    { __typename?: 'AcceptanceResult' }
    & Pick<AcceptanceResult, 'isAccepted' | 'isValid'>
    & { declineReason: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )>, inputErrors: Maybe<Array<Maybe<(
      { __typename?: 'AcceptanceResultError' }
      & Pick<AcceptanceResultError, 'field'>
      & { errorMessage: Maybe<(
        { __typename?: 'LocalizedString' }
        & Pick<LocalizedString, 'message'>
      )> }
    )>>> }
  ) }
);

export type SetAnswerToQuestionMutationVariables = {
  question: Scalars['Float'],
  answer: Scalars['JSON']
};


export type SetAnswerToQuestionMutation = (
  { __typename?: 'Mutation' }
  & { setAnswerToQuestion: Maybe<(
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'errorCode' | 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type ValidatePayoutWalletMutationVariables = {
  id: Scalars['String'],
  data: Scalars['JSON']
};


export type ValidatePayoutWalletMutation = (
  { __typename?: 'Mutation' }
  & { validatePayoutWallet: Maybe<(
    { __typename?: 'AddWalletResult' }
    & Pick<AddWalletResult, 'errorCode' | 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )>, wallet: Maybe<(
      { __typename?: 'PayoutWallet' }
      & Pick<PayoutWallet, 'id' | 'createdAt' | 'data' | 'isConfirmed' | 'cardInfo'>
    )> }
  )> }
);

export type AddPayoutWalletMutationVariables = {
  id: Scalars['String'],
  data: Scalars['JSON']
};


export type AddPayoutWalletMutation = (
  { __typename?: 'Mutation' }
  & { addPayoutWallet: Maybe<(
    { __typename?: 'AddWalletResult' }
    & Pick<AddWalletResult, 'errorCode' | 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )>, wallet: Maybe<(
      { __typename?: 'PayoutWallet' }
      & Pick<PayoutWallet, 'id' | 'createdAt' | 'data' | 'isConfirmed' | 'cardInfo'>
    )> }
  )> }
);

export type RequestPayoutMutationVariables = {
  payout: PayoutInput
};


export type RequestPayoutMutation = (
  { __typename?: 'Mutation' }
  & { requestPayout: Maybe<(
    { __typename?: 'RequestPayoutResult' }
    & Pick<RequestPayoutResult, 'errorCode' | 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )>, payout: Maybe<(
      { __typename?: 'Payout' }
      & PayoutMutFragment
    )> }
  )> }
);

export type UpdateNotificationKeyMutationVariables = {
  subscription: Scalars['JSON']
};


export type UpdateNotificationKeyMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationKey: Maybe<(
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'isSuccess' | 'errorCode'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type CreateReportMutationVariables = {
  report: ReportNodeInput
};


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReport: Maybe<(
    { __typename?: 'AddReportResult' }
    & Pick<AddReportResult, 'isSuccess' | 'errorCode'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type ConfirmEmailMutationVariables = {
  email: Scalars['String']
};


export type ConfirmEmailMutation = (
  { __typename?: 'Mutation' }
  & { confirmEmail: (
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'isSuccess' | 'errorCode'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  ) }
);

export type RemovePayoutWalletMutationVariables = {
  id: Scalars['UUID']
};


export type RemovePayoutWalletMutation = (
  { __typename?: 'Mutation' }
  & { removePayoutWallet: Maybe<(
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type ReportMissingKeyMutationVariables = {
  key: Scalars['String'],
  stack: Scalars['String'],
  fallback?: Maybe<Scalars['String']>
};


export type ReportMissingKeyMutation = (
  { __typename?: 'Mutation' }
  & { submitMissingLocaleKey: Maybe<(
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'isSuccess'>
  )> }
);

export type SubmitEventMutationVariables = {
  data: Scalars['String']
};


export type SubmitEventMutation = (
  { __typename?: 'Mutation' }
  & { trackEvent: Maybe<(
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'isSuccess'>
  )> }
);

export type SubmitHelpRequestMutationVariables = {
  data: HelpRequest
};


export type SubmitHelpRequestMutation = (
  { __typename?: 'Mutation' }
  & { submitHelpRequest: (
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  ) }
);

export type ReverseAccountRemovalMutationVariables = {};


export type ReverseAccountRemovalMutation = (
  { __typename?: 'Mutation' }
  & { reverseAccountRemoval: (
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  ) }
);

export type RemoveAccountMutationVariables = {
  reason: Array<Scalars['String']>
};


export type RemoveAccountMutation = (
  { __typename?: 'Mutation' }
  & { removeAccount: (
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  ) }
);

export type AddVkChannelMutationVariables = {
  data: Scalars['String']
};


export type AddVkChannelMutation = (
  { __typename?: 'Mutation' }
  & { addNotificationChannel: (
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'isSuccess' | 'errorCode'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  ) }
);

export type RemoveVkChannelMutationVariables = {
  data: Scalars['String']
};


export type RemoveVkChannelMutation = (
  { __typename?: 'Mutation' }
  & { removeNotificationChannel: (
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'isSuccess' | 'errorCode'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  ) }
);

export type UserAccountFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'balance' | 'frozen'>
  & { accountCurrency: Maybe<(
    { __typename?: 'Currency' }
    & Pick<Currency, 'type'>
  )> }
);

export type PayoutFragFragment = (
  { __typename?: 'Payout' }
  & Pick<Payout, 'id' | 'createdAt' | 'status' | 'amountDue' | 'errorReason'>
  & { destination: Maybe<(
    { __typename?: 'PayoutWallet' }
    & { method: Maybe<(
      { __typename?: 'PayoutMethod' }
      & { name: Maybe<(
        { __typename?: 'LocalizedString' }
        & Pick<LocalizedString, 'message'>
      )> }
    )> }
  )> }
);

export type CurrentUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'avatar' | 'email' | 'referralCode' | 'firstName' | 'lastName' | 'zipCode' | 'birthDate' | 'country' | 'city' | 'state' | 'isAccountConfirmed' | 'experiments'>
);

export type TaskFragmentFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'cr' | 'incidenceRate' | 'isImportant' | 'type' | 'buttonText' | 'buttonLink' | 'specialActionType' | 'averageTime'>
  & { project: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  )>, kind: Maybe<(
    { __typename?: 'TaskKind' }
    & Pick<TaskKind, 'id' | 'fill' | 'helpPopup'>
    & { name: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )>, name: Maybe<(
    { __typename?: 'LocalizedString' }
    & Pick<LocalizedString, 'message'>
  )>, description: Maybe<(
    { __typename?: 'LocalizedString' }
    & Pick<LocalizedString, 'message'>
  )>, reward: Maybe<(
    { __typename?: 'CurrencyValue' }
    & Pick<CurrencyValue, 'value'>
  )> }
);

export type LookupAddressQueryVariables = {
  search: Scalars['String']
};


export type LookupAddressQuery = (
  { __typename?: 'Query' }
  & { searchAddress: (
    { __typename?: 'MakeLookup' }
    & Pick<MakeLookup, 'isValidZipCode'>
    & { getZipCode: Maybe<(
      { __typename?: 'PostalNode' }
      & Pick<PostalNode, 'id'>
    )>, suggestions: Maybe<Array<(
      { __typename?: 'Suggestion' }
      & Pick<Suggestion, 'value' | 'postal_code' | 'isPrecise'>
    )>> }
  ) }
);

export type CurrentUserQueryQueryVariables = {};


export type CurrentUserQueryQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & { accounts: Maybe<Array<Maybe<(
      { __typename?: 'Account' }
      & UserAccountFragment
    )>>> }
    & CurrentUserFragment
  )> }
);

export type GetNotificationsQueryVariables = {};


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { notifications: Maybe<Array<Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id' | 'details' | 'red' | 'type'>
      & { name: Maybe<(
        { __typename?: 'LocalizedString' }
        & Pick<LocalizedString, 'message'>
      )>, value: Maybe<(
        { __typename?: 'LocalizedString' }
        & Pick<LocalizedString, 'message'>
      )> }
    )>>> }
  )> }
);

export type ReportsQueryQueryVariables = {};


export type ReportsQueryQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { reports: Maybe<(
      { __typename?: 'ReportEdge' }
      & Pick<ReportEdge, 'total' | 'totalApproved'>
      & { totalAmount: Maybe<(
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
        & { currencyType: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'type'>
        )> }
      )>, reports: Maybe<Array<Maybe<(
        { __typename?: 'ReportNode' }
        & Pick<ReportNode, 'id' | 'type' | 'createdAt' | 'updatedAt' | 'amount' | 'status' | 'rejectionReason'>
        & { outerOperationName: Maybe<(
          { __typename?: 'LocalizedString' }
          & Pick<LocalizedString, 'message'>
        )> }
      )>>> }
    )> }
  )> }
);

export type TasksQueryQueryVariables = {};


export type TasksQueryQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { tasks: Maybe<Array<Maybe<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )>>> }
  )> }
);

export type FaqQueryQueryVariables = {};


export type FaqQueryQuery = (
  { __typename?: 'Query' }
  & { faq: Array<(
    { __typename?: 'QA' }
    & Pick<Qa, 'id'>
    & { name: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )>, text: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type GetLocationQueryVariables = {};


export type GetLocationQuery = (
  { __typename?: 'Query' }
  & { locationInfo: Maybe<(
    { __typename?: 'GeoIp' }
    & Pick<GeoIp, 'countryIsoCode' | 'isLocaleEnforced' | 'isAvailableToRegister'>
    & { country: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type PayoutsHistoryQueryVariables = {};


export type PayoutsHistoryQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { payouts: Array<Maybe<(
      { __typename?: 'Payout' }
      & PayoutFragFragment
    )>> }
  )> }
);

export type PayoutsQueryQueryVariables = {};


export type PayoutsQueryQuery = (
  { __typename?: 'Query' }
  & { payoutMethods: Array<(
    { __typename?: 'PayoutMethod' }
    & Pick<PayoutMethod, 'id' | 'isDisabled' | 'fee'>
    & { name: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )>, minimalPayout: Maybe<(
      { __typename?: 'CurrencyValue' }
      & Pick<CurrencyValue, 'value'>
      & { currencyType: Maybe<(
        { __typename?: 'Currency' }
        & Pick<Currency, 'type'>
      )> }
    )>, ui: Maybe<(
      { __typename?: 'PayoutUiProps' }
      & Pick<PayoutUiProps, 'title' | 'icon' | 'label' | 'placeholder' | 'description'>
      & { formatter: Maybe<(
        { __typename?: 'Formatter' }
        & Pick<Formatter, 'type' | 'country'>
      )> }
    )>, currentWallet: Maybe<(
      { __typename?: 'PayoutWallet' }
      & Pick<PayoutWallet, 'id' | 'createdAt' | 'data' | 'isConfirmed' | 'cardInfo'>
    )> }
  )> }
);

export type RestoreTokenQueryQueryVariables = {
  userId: Scalars['String'],
  restoreToken: Scalars['String']
};


export type RestoreTokenQueryQuery = (
  { __typename?: 'Query' }
  & { restoreToken: (
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'errorCode' | 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  ) }
);

export type GetLocaleDataQueryQueryVariables = {
  locale?: Maybe<Language>
};


export type GetLocaleDataQueryQuery = (
  { __typename?: 'Query' }
  & { getLocaleData: Maybe<Array<Maybe<(
    { __typename?: 'LocaleString' }
    & Pick<LocaleString, 'id' | 'key' | 'value'>
  )>>> }
);

export type GetNotificationSectionsQueryVariables = {};


export type GetNotificationSectionsQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { notificationSections: Maybe<Array<Maybe<(
      { __typename?: 'NotifySections' }
      & Pick<NotifySections, 'id' | 'when' | 'isSubscribedWithPushes' | 'isSubscribedWithEmail'>
      & { name: Maybe<(
        { __typename?: 'LocalizedString' }
        & Pick<LocalizedString, 'message'>
      )> }
    )>>> }
  )> }
);

export type ProfileDataQueryQueryVariables = {};


export type ProfileDataQueryQuery = (
  { __typename?: 'Query' }
  & { questionCategories: Maybe<Array<Maybe<(
    { __typename?: 'QuestionCategory' }
    & Pick<QuestionCategory, 'id' | 'code' | 'isRewardAffiliated' | 'isRewardGiven' | 'unansweredQuestionsLength' | 'questionsLength'>
    & { name: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )>, reward: Maybe<(
      { __typename?: 'CurrencyValue' }
      & Pick<CurrencyValue, 'value'>
    )> }
  )>>>, currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { notificationSections: Maybe<Array<Maybe<(
      { __typename?: 'NotifySections' }
      & Pick<NotifySections, 'id' | 'when' | 'isSubscribedWithPushes' | 'isSubscribedWithEmail'>
      & { name: Maybe<(
        { __typename?: 'LocalizedString' }
        & Pick<LocalizedString, 'message'>
      )> }
    )>>> }
  )> }
);

export type VapidKeyQueryVariables = {};


export type VapidKeyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'vapidKey'>
);

export type QuestionCategoriesQueryQueryVariables = {};


export type QuestionCategoriesQueryQuery = (
  { __typename?: 'Query' }
  & { questionCategories: Maybe<Array<Maybe<(
    { __typename?: 'QuestionCategory' }
    & Pick<QuestionCategory, 'id' | 'code' | 'isRewardAffiliated' | 'isRewardGiven' | 'unansweredQuestionsLength' | 'questionsLength'>
    & { name: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )>, reward: Maybe<(
      { __typename?: 'CurrencyValue' }
      & Pick<CurrencyValue, 'value'>
    )> }
  )>>> }
);

export type GivenAnswerFieldsFragment = (
  { __typename?: 'GivenAnswer' }
  & Pick<GivenAnswer, 'text' | 'date'>
  & { answer: Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id'>
    & { text: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )>, answers: Maybe<Array<Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id'>
    & { text: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )>>> }
);

export type QuestionFieldsFragment = (
  { __typename?: 'Question' }
  & Pick<Question, 'id' | 'code' | 'questionType' | 'inputProps' | 'hasAutosuggestion'>
  & { name: Maybe<(
    { __typename?: 'LocalizedString' }
    & Pick<LocalizedString, 'message'>
  )>, currentAnswer: Maybe<(
    { __typename?: 'GivenAnswer' }
    & GivenAnswerFieldsFragment
  )>, picks: Maybe<Array<Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id'>
    & { text: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )>>> }
);

export type QuestionCategoryQueryQueryVariables = {
  id: Scalars['Float']
};


export type QuestionCategoryQueryQuery = (
  { __typename?: 'Query' }
  & { questionCategory: Maybe<(
    { __typename?: 'QuestionCategory' }
    & Pick<QuestionCategory, 'id' | 'code' | 'isRewardAffiliated' | 'isRewardGiven' | 'unansweredQuestionsLength' | 'questionsLength'>
    & { name: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )>, reward: Maybe<(
      { __typename?: 'CurrencyValue' }
      & Pick<CurrencyValue, 'value'>
    )>, questions: Maybe<Array<Maybe<(
      { __typename?: 'Question' }
      & QuestionFieldsFragment
    )>>> }
  )> }
);

export type QuestionSuggestionQueryVariables = {
  question: Scalars['Float'],
  answer: Scalars['JSON']
};


export type QuestionSuggestionQuery = (
  { __typename?: 'Query' }
  & { questionSuggestion: Maybe<Array<Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id'>
    & { text: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )>>> }
);

export type RemovalReasonsQueryVariables = {};


export type RemovalReasonsQuery = (
  { __typename?: 'Query' }
  & { removalReasons: Array<(
    { __typename?: 'RemovalReason' }
    & Pick<RemovalReason, 'customReason' | 'description' | 'name' | 'id'>
  )> }
);

export type ReferralProgrammQueryQueryVariables = {};


export type ReferralProgrammQueryQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'referralCode'>
    & { affiliateStats: Maybe<(
      { __typename?: 'AffiliateStats' }
      & Pick<AffiliateStats, 'surveysDone' | 'clicks' | 'registrations'>
      & { profitOverTime: Maybe<(
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      )> }
    )> }
  )> }
);

export type GetTermQueryVariables = {
  id: Scalars['Float']
};


export type GetTermQuery = (
  { __typename?: 'Query' }
  & { termById: Maybe<(
    { __typename?: 'Term' }
    & Pick<Term, 'id' | 'header' | 'subheader'>
  )> }
);

export type GetTaskQueryVariables = {
  userId: Scalars['String']
};


export type GetTaskQuery = (
  { __typename?: 'Query' }
  & { task: Maybe<(
    { __typename?: 'Task' }
    & TaskFragmentFragment
  )> }
);

export type TransactionHistoryQueryVariables = {
  transactionReportsAfter?: Maybe<Scalars['String']>
};


export type TransactionHistoryQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { transactionReports: Maybe<Array<Maybe<(
      { __typename?: 'TransactionReportAggregate' }
      & Pick<TransactionReportAggregate, 'id' | 'transactionTime' | 'amount' | 'operationName' | 'status' | 'type'>
      & { transaction: Maybe<(
        { __typename?: 'MicroTransaction' }
        & Pick<MicroTransaction, 'id' | 'credited' | 'isRevertedReport'>
      )> }
    )>>> }
  )> }
);

export type TransactionStatsQueryVariables = {};


export type TransactionStatsQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { transactionStats: Maybe<(
      { __typename?: 'TransactionStats' }
      & Pick<TransactionStats, 'total' | 'totalApproved'>
      & { totalAmount: Maybe<(
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
        & { currencyType: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'type'>
        )> }
      )> }
    )> }
  )> }
);

export type OnAppUpdatedSubscriptionVariables = {};


export type OnAppUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { onAppUpdated: Maybe<(
    { __typename?: 'PureAction' }
    & Pick<PureAction, 'errorCode' | 'isSuccess'>
    & { error: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type OnAccountUpdatedSubscriptionVariables = {};


export type OnAccountUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { onAccountUpdated: Maybe<(
    { __typename?: 'Account' }
    & UserAccountFragment
  )> }
);

export type OnNewNotificationSubscriptionVariables = {};


export type OnNewNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { onNewNotification: Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'details' | 'red' | 'type'>
    & { name: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )>, value: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type OnAnswerUpdatedSubscriptionVariables = {};


export type OnAnswerUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { onAnswerUpdated: Maybe<(
    { __typename?: 'GivenAnswer' }
    & Pick<GivenAnswer, 'text' | 'date'>
    & { question: Maybe<(
      { __typename?: 'Question' }
      & Pick<Question, 'id'>
    )>, answer: Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id'>
    )>, answers: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id'>
    )>>> }
  )> }
);

export type OnReportUpdatedSubscriptionVariables = {};


export type OnReportUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { onReportUpdated: Maybe<(
    { __typename?: 'ReportNode' }
    & Pick<ReportNode, 'id' | 'type' | 'createdAt' | 'updatedAt' | 'amount' | 'status' | 'rejectionReason'>
    & { outerOperationName: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )> }
);

export type OnGivenAnswerUpdatedSubscriptionVariables = {};


export type OnGivenAnswerUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { onAnswerUpdated: Maybe<(
    { __typename?: 'GivenAnswer' }
    & GivenAnswerFieldsSubFragment
  )> }
);

export type OnUserUpdatedSubscriptionVariables = {};


export type OnUserUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { onUserUpdated: Maybe<(
    { __typename?: 'User' }
    & CurrentUserFragment
  )> }
);

export type GivenAnswerFieldsSubFragment = (
  { __typename?: 'GivenAnswer' }
  & Pick<GivenAnswer, 'text' | 'date'>
  & { answer: Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id'>
    & { text: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )>, question: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id'>
    & { category: Maybe<(
      { __typename?: 'QuestionCategory' }
      & Pick<QuestionCategory, 'id'>
    )> }
  )>, answers: Maybe<Array<Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id'>
    & { text: Maybe<(
      { __typename?: 'LocalizedString' }
      & Pick<LocalizedString, 'message'>
    )> }
  )>>> }
);

export const PayoutMutFragmentDoc = gql`
    fragment PayoutMut on Payout {
  id
  createdAt
  status
  amountDue
  errorReason
  destination {
    method {
      name {
        message
      }
    }
  }
}
    `;
export const UserAccountFragmentDoc = gql`
    fragment UserAccount on Account {
  id
  accountCurrency {
    type
  }
  balance
  frozen
}
    `;
export const PayoutFragFragmentDoc = gql`
    fragment PayoutFrag on Payout {
  id
  createdAt
  status
  amountDue
  errorReason
  destination {
    method {
      name {
        message
      }
    }
  }
}
    `;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on User {
  id
  avatar
  email
  referralCode
  firstName
  lastName
  zipCode
  birthDate
  country
  city
  state
  isAccountConfirmed
  experiments
}
    `;
export const TaskFragmentFragmentDoc = gql`
    fragment TaskFragment on Task {
  id
  cr
  project {
    id
  }
  incidenceRate
  kind {
    id
    name {
      message
    }
    fill
    helpPopup
  }
  name {
    message
  }
  description {
    message
  }
  isImportant
  type
  reward {
    value
  }
  buttonText
  buttonLink
  specialActionType
  averageTime
}
    `;
export const GivenAnswerFieldsFragmentDoc = gql`
    fragment GivenAnswerFields on GivenAnswer {
  answer {
    id
    text {
      message
    }
  }
  answers {
    id
    text {
      message
    }
  }
  text
  date
}
    `;
export const QuestionFieldsFragmentDoc = gql`
    fragment QuestionFields on Question {
  id
  code
  name {
    message
  }
  questionType
  inputProps
  currentAnswer {
    ...GivenAnswerFields
  }
  hasAutosuggestion
  picks {
    id
    text {
      message
    }
  }
}
    ${GivenAnswerFieldsFragmentDoc}`;
export const GivenAnswerFieldsSubFragmentDoc = gql`
    fragment GivenAnswerFieldsSub on GivenAnswer {
  answer {
    id
    text {
      message
    }
  }
  question {
    id
    category {
      id
    }
  }
  answers {
    id
    text {
      message
    }
  }
  text
  date
}
    `;
export const NewRegisterMutationDocument = gql`
    mutation NewRegisterMutation($email: String!, $password: String!) {
  result: register(request: {email: $email, password: $password}) {
    errorCode
    errorMessage {
      message
    }
    isSuccess
    jwtToken
  }
}
    `;
export type NewRegisterMutationMutationFn = ApolloReactCommon.MutationFunction<NewRegisterMutationMutation, NewRegisterMutationMutationVariables>;
export type NewRegisterMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<NewRegisterMutationMutation, NewRegisterMutationMutationVariables> & TChildProps;
export function withNewRegisterMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NewRegisterMutationMutation,
  NewRegisterMutationMutationVariables,
  NewRegisterMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, NewRegisterMutationMutation, NewRegisterMutationMutationVariables, NewRegisterMutationProps<TChildProps>>(NewRegisterMutationDocument, {
      alias: 'newRegisterMutation',
      ...operationOptions
    });
};

/**
 * __useNewRegisterMutationMutation__
 *
 * To run a mutation, you first call `useNewRegisterMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewRegisterMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newRegisterMutationMutation, { data, loading, error }] = useNewRegisterMutationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useNewRegisterMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewRegisterMutationMutation, NewRegisterMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<NewRegisterMutationMutation, NewRegisterMutationMutationVariables>(NewRegisterMutationDocument, baseOptions);
      }
export type NewRegisterMutationMutationHookResult = ReturnType<typeof useNewRegisterMutationMutation>;
export type NewRegisterMutationMutationResult = ApolloReactCommon.MutationResult<NewRegisterMutationMutation>;
export type NewRegisterMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<NewRegisterMutationMutation, NewRegisterMutationMutationVariables>;
export const RegisterMutationDocument = gql`
    mutation RegisterMutation($request: RegisterRequest!) {
  register(request: $request) {
    errorCode
    errorMessage {
      message
    }
    isSuccess
    jwtToken
  }
}
    `;
export type RegisterMutationMutationFn = ApolloReactCommon.MutationFunction<RegisterMutationMutation, RegisterMutationMutationVariables>;
export type RegisterMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RegisterMutationMutation, RegisterMutationMutationVariables> & TChildProps;
export function withRegisterMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RegisterMutationMutation,
  RegisterMutationMutationVariables,
  RegisterMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RegisterMutationMutation, RegisterMutationMutationVariables, RegisterMutationProps<TChildProps>>(RegisterMutationDocument, {
      alias: 'registerMutation',
      ...operationOptions
    });
};

/**
 * __useRegisterMutationMutation__
 *
 * To run a mutation, you first call `useRegisterMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutationMutation, { data, loading, error }] = useRegisterMutationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRegisterMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterMutationMutation, RegisterMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterMutationMutation, RegisterMutationMutationVariables>(RegisterMutationDocument, baseOptions);
      }
export type RegisterMutationMutationHookResult = ReturnType<typeof useRegisterMutationMutation>;
export type RegisterMutationMutationResult = ApolloReactCommon.MutationResult<RegisterMutationMutation>;
export type RegisterMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterMutationMutation, RegisterMutationMutationVariables>;
export const ActivateSecondFlowDocument = gql`
    mutation ActivateSecondFlow {
  activateSecondFlow {
    isSuccess
    errorCode
  }
}
    `;
export type ActivateSecondFlowMutationFn = ApolloReactCommon.MutationFunction<ActivateSecondFlowMutation, ActivateSecondFlowMutationVariables>;
export type ActivateSecondFlowProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ActivateSecondFlowMutation, ActivateSecondFlowMutationVariables> & TChildProps;
export function withActivateSecondFlow<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ActivateSecondFlowMutation,
  ActivateSecondFlowMutationVariables,
  ActivateSecondFlowProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ActivateSecondFlowMutation, ActivateSecondFlowMutationVariables, ActivateSecondFlowProps<TChildProps>>(ActivateSecondFlowDocument, {
      alias: 'activateSecondFlow',
      ...operationOptions
    });
};

/**
 * __useActivateSecondFlowMutation__
 *
 * To run a mutation, you first call `useActivateSecondFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSecondFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSecondFlowMutation, { data, loading, error }] = useActivateSecondFlowMutation({
 *   variables: {
 *   },
 * });
 */
export function useActivateSecondFlowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateSecondFlowMutation, ActivateSecondFlowMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateSecondFlowMutation, ActivateSecondFlowMutationVariables>(ActivateSecondFlowDocument, baseOptions);
      }
export type ActivateSecondFlowMutationHookResult = ReturnType<typeof useActivateSecondFlowMutation>;
export type ActivateSecondFlowMutationResult = ApolloReactCommon.MutationResult<ActivateSecondFlowMutation>;
export type ActivateSecondFlowMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateSecondFlowMutation, ActivateSecondFlowMutationVariables>;
export const ChangeEmailDocument = gql`
    mutation ChangeEmail($newEmail: String!) {
  changeEmail(newEmail: $newEmail) {
    errorCode
    error {
      message
    }
    isSuccess
  }
}
    `;
export type ChangeEmailMutationFn = ApolloReactCommon.MutationFunction<ChangeEmailMutation, ChangeEmailMutationVariables>;
export type ChangeEmailProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ChangeEmailMutation, ChangeEmailMutationVariables> & TChildProps;
export function withChangeEmail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeEmailMutation,
  ChangeEmailMutationVariables,
  ChangeEmailProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeEmailMutation, ChangeEmailMutationVariables, ChangeEmailProps<TChildProps>>(ChangeEmailDocument, {
      alias: 'changeEmail',
      ...operationOptions
    });
};

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      newEmail: // value for 'newEmail'
 *   },
 * });
 */
export function useChangeEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeEmailMutation, ChangeEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(ChangeEmailDocument, baseOptions);
      }
export type ChangeEmailMutationHookResult = ReturnType<typeof useChangeEmailMutation>;
export type ChangeEmailMutationResult = ApolloReactCommon.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeEmailMutation, ChangeEmailMutationVariables>;
export const LoginMutationDocument = gql`
    mutation LoginMutation($email: String!, $password: String!, $recaptchaResponse: String) {
  result: signIn(email: $email, password: $password, recaptchaResponse: $recaptchaResponse) {
    errorCode
    errorMessage {
      message
    }
    isSuccess
    jwtToken
  }
}
    `;
export type LoginMutationMutationFn = ApolloReactCommon.MutationFunction<LoginMutationMutation, LoginMutationMutationVariables>;
export type LoginMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LoginMutationMutation, LoginMutationMutationVariables> & TChildProps;
export function withLoginMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoginMutationMutation,
  LoginMutationMutationVariables,
  LoginMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LoginMutationMutation, LoginMutationMutationVariables, LoginMutationProps<TChildProps>>(LoginMutationDocument, {
      alias: 'loginMutation',
      ...operationOptions
    });
};

/**
 * __useLoginMutationMutation__
 *
 * To run a mutation, you first call `useLoginMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutationMutation, { data, loading, error }] = useLoginMutationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      recaptchaResponse: // value for 'recaptchaResponse'
 *   },
 * });
 */
export function useLoginMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutationMutation, LoginMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutationMutation, LoginMutationMutationVariables>(LoginMutationDocument, baseOptions);
      }
export type LoginMutationMutationHookResult = ReturnType<typeof useLoginMutationMutation>;
export type LoginMutationMutationResult = ApolloReactCommon.MutationResult<LoginMutationMutation>;
export type LoginMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutationMutation, LoginMutationMutationVariables>;
export const RestorePasswordMutationDocument = gql`
    mutation RestorePasswordMutation($email: String!) {
  restorePassword(email: $email) {
    errorCode
    isSuccess
  }
}
    `;
export type RestorePasswordMutationMutationFn = ApolloReactCommon.MutationFunction<RestorePasswordMutationMutation, RestorePasswordMutationMutationVariables>;
export type RestorePasswordMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RestorePasswordMutationMutation, RestorePasswordMutationMutationVariables> & TChildProps;
export function withRestorePasswordMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RestorePasswordMutationMutation,
  RestorePasswordMutationMutationVariables,
  RestorePasswordMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RestorePasswordMutationMutation, RestorePasswordMutationMutationVariables, RestorePasswordMutationProps<TChildProps>>(RestorePasswordMutationDocument, {
      alias: 'restorePasswordMutation',
      ...operationOptions
    });
};

/**
 * __useRestorePasswordMutationMutation__
 *
 * To run a mutation, you first call `useRestorePasswordMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestorePasswordMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restorePasswordMutationMutation, { data, loading, error }] = useRestorePasswordMutationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRestorePasswordMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestorePasswordMutationMutation, RestorePasswordMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<RestorePasswordMutationMutation, RestorePasswordMutationMutationVariables>(RestorePasswordMutationDocument, baseOptions);
      }
export type RestorePasswordMutationMutationHookResult = ReturnType<typeof useRestorePasswordMutationMutation>;
export type RestorePasswordMutationMutationResult = ApolloReactCommon.MutationResult<RestorePasswordMutationMutation>;
export type RestorePasswordMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<RestorePasswordMutationMutation, RestorePasswordMutationMutationVariables>;
export const ChangePasswordMutationDocument = gql`
    mutation ChangePasswordMutation($userId: String, $restoreToken: String!, $newPassword: String!) {
  changePassword(userId: $userId, restoreToken: $restoreToken, newPassword: $newPassword) {
    errorCode
    errorMessage {
      message
    }
    isSuccess
    jwtToken
  }
}
    `;
export type ChangePasswordMutationMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutationMutation, ChangePasswordMutationMutationVariables>;
export type ChangePasswordMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ChangePasswordMutationMutation, ChangePasswordMutationMutationVariables> & TChildProps;
export function withChangePasswordMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangePasswordMutationMutation,
  ChangePasswordMutationMutationVariables,
  ChangePasswordMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ChangePasswordMutationMutation, ChangePasswordMutationMutationVariables, ChangePasswordMutationProps<TChildProps>>(ChangePasswordMutationDocument, {
      alias: 'changePasswordMutation',
      ...operationOptions
    });
};

/**
 * __useChangePasswordMutationMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutationMutation, { data, loading, error }] = useChangePasswordMutationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      restoreToken: // value for 'restoreToken'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutationMutation, ChangePasswordMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutationMutation, ChangePasswordMutationMutationVariables>(ChangePasswordMutationDocument, baseOptions);
      }
export type ChangePasswordMutationMutationHookResult = ReturnType<typeof useChangePasswordMutationMutation>;
export type ChangePasswordMutationMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutationMutation>;
export type ChangePasswordMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutationMutation, ChangePasswordMutationMutationVariables>;
export const ResendValidationEmailMutationDocument = gql`
    mutation ResendValidationEmailMutation {
  resendValidationEmail {
    errorCode
    error {
      message
    }
    isSuccess
  }
}
    `;
export type ResendValidationEmailMutationMutationFn = ApolloReactCommon.MutationFunction<ResendValidationEmailMutationMutation, ResendValidationEmailMutationMutationVariables>;
export type ResendValidationEmailMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ResendValidationEmailMutationMutation, ResendValidationEmailMutationMutationVariables> & TChildProps;
export function withResendValidationEmailMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResendValidationEmailMutationMutation,
  ResendValidationEmailMutationMutationVariables,
  ResendValidationEmailMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ResendValidationEmailMutationMutation, ResendValidationEmailMutationMutationVariables, ResendValidationEmailMutationProps<TChildProps>>(ResendValidationEmailMutationDocument, {
      alias: 'resendValidationEmailMutation',
      ...operationOptions
    });
};

/**
 * __useResendValidationEmailMutationMutation__
 *
 * To run a mutation, you first call `useResendValidationEmailMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendValidationEmailMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendValidationEmailMutationMutation, { data, loading, error }] = useResendValidationEmailMutationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendValidationEmailMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendValidationEmailMutationMutation, ResendValidationEmailMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendValidationEmailMutationMutation, ResendValidationEmailMutationMutationVariables>(ResendValidationEmailMutationDocument, baseOptions);
      }
export type ResendValidationEmailMutationMutationHookResult = ReturnType<typeof useResendValidationEmailMutationMutation>;
export type ResendValidationEmailMutationMutationResult = ApolloReactCommon.MutationResult<ResendValidationEmailMutationMutation>;
export type ResendValidationEmailMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendValidationEmailMutationMutation, ResendValidationEmailMutationMutationVariables>;
export const LogoutMutationDocument = gql`
    mutation LogoutMutation {
  logout {
    errorCode
    error {
      message
    }
    isSuccess
  }
}
    `;
export type LogoutMutationMutationFn = ApolloReactCommon.MutationFunction<LogoutMutationMutation, LogoutMutationMutationVariables>;
export type LogoutMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LogoutMutationMutation, LogoutMutationMutationVariables> & TChildProps;
export function withLogoutMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LogoutMutationMutation,
  LogoutMutationMutationVariables,
  LogoutMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LogoutMutationMutation, LogoutMutationMutationVariables, LogoutMutationProps<TChildProps>>(LogoutMutationDocument, {
      alias: 'logoutMutation',
      ...operationOptions
    });
};

/**
 * __useLogoutMutationMutation__
 *
 * To run a mutation, you first call `useLogoutMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutationMutation, { data, loading, error }] = useLogoutMutationMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutationMutation, LogoutMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutationMutation, LogoutMutationMutationVariables>(LogoutMutationDocument, baseOptions);
      }
export type LogoutMutationMutationHookResult = ReturnType<typeof useLogoutMutationMutation>;
export type LogoutMutationMutationResult = ApolloReactCommon.MutationResult<LogoutMutationMutation>;
export type LogoutMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutationMutation, LogoutMutationMutationVariables>;
export const UpdateNotificationPreferencesDocument = gql`
    mutation UpdateNotificationPreferences($id: String!, $channel: NotifyChannels!, $isEnabled: Boolean!) {
  updateNotificationPreferences(id: $id, channel: $channel, isEnabled: $isEnabled) {
    errorCode
    error {
      message
    }
    isSuccess
  }
}
    `;
export type UpdateNotificationPreferencesMutationFn = ApolloReactCommon.MutationFunction<UpdateNotificationPreferencesMutation, UpdateNotificationPreferencesMutationVariables>;
export type UpdateNotificationPreferencesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateNotificationPreferencesMutation, UpdateNotificationPreferencesMutationVariables> & TChildProps;
export function withUpdateNotificationPreferences<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateNotificationPreferencesMutation,
  UpdateNotificationPreferencesMutationVariables,
  UpdateNotificationPreferencesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateNotificationPreferencesMutation, UpdateNotificationPreferencesMutationVariables, UpdateNotificationPreferencesProps<TChildProps>>(UpdateNotificationPreferencesDocument, {
      alias: 'updateNotificationPreferences',
      ...operationOptions
    });
};

/**
 * __useUpdateNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationPreferencesMutation, { data, loading, error }] = useUpdateNotificationPreferencesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      channel: // value for 'channel'
 *      isEnabled: // value for 'isEnabled'
 *   },
 * });
 */
export function useUpdateNotificationPreferencesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotificationPreferencesMutation, UpdateNotificationPreferencesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNotificationPreferencesMutation, UpdateNotificationPreferencesMutationVariables>(UpdateNotificationPreferencesDocument, baseOptions);
      }
export type UpdateNotificationPreferencesMutationHookResult = ReturnType<typeof useUpdateNotificationPreferencesMutation>;
export type UpdateNotificationPreferencesMutationResult = ApolloReactCommon.MutationResult<UpdateNotificationPreferencesMutation>;
export type UpdateNotificationPreferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotificationPreferencesMutation, UpdateNotificationPreferencesMutationVariables>;
export const SubmitSecondFlowDocument = gql`
    mutation SubmitSecondFlow($request: SecondFlowInput!) {
  submitSecondFlow(data: $request) {
    declineReason {
      message
    }
    inputErrors {
      field
      errorMessage {
        message
      }
    }
    isAccepted
    isValid
  }
}
    `;
export type SubmitSecondFlowMutationFn = ApolloReactCommon.MutationFunction<SubmitSecondFlowMutation, SubmitSecondFlowMutationVariables>;
export type SubmitSecondFlowProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SubmitSecondFlowMutation, SubmitSecondFlowMutationVariables> & TChildProps;
export function withSubmitSecondFlow<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmitSecondFlowMutation,
  SubmitSecondFlowMutationVariables,
  SubmitSecondFlowProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SubmitSecondFlowMutation, SubmitSecondFlowMutationVariables, SubmitSecondFlowProps<TChildProps>>(SubmitSecondFlowDocument, {
      alias: 'submitSecondFlow',
      ...operationOptions
    });
};

/**
 * __useSubmitSecondFlowMutation__
 *
 * To run a mutation, you first call `useSubmitSecondFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSecondFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSecondFlowMutation, { data, loading, error }] = useSubmitSecondFlowMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSubmitSecondFlowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitSecondFlowMutation, SubmitSecondFlowMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitSecondFlowMutation, SubmitSecondFlowMutationVariables>(SubmitSecondFlowDocument, baseOptions);
      }
export type SubmitSecondFlowMutationHookResult = ReturnType<typeof useSubmitSecondFlowMutation>;
export type SubmitSecondFlowMutationResult = ApolloReactCommon.MutationResult<SubmitSecondFlowMutation>;
export type SubmitSecondFlowMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitSecondFlowMutation, SubmitSecondFlowMutationVariables>;
export const SetAnswerToQuestionDocument = gql`
    mutation SetAnswerToQuestion($question: Float!, $answer: JSON!) {
  setAnswerToQuestion(question: $question, answer: $answer) {
    errorCode
    error {
      message
    }
    isSuccess
  }
}
    `;
export type SetAnswerToQuestionMutationFn = ApolloReactCommon.MutationFunction<SetAnswerToQuestionMutation, SetAnswerToQuestionMutationVariables>;
export type SetAnswerToQuestionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetAnswerToQuestionMutation, SetAnswerToQuestionMutationVariables> & TChildProps;
export function withSetAnswerToQuestion<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetAnswerToQuestionMutation,
  SetAnswerToQuestionMutationVariables,
  SetAnswerToQuestionProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetAnswerToQuestionMutation, SetAnswerToQuestionMutationVariables, SetAnswerToQuestionProps<TChildProps>>(SetAnswerToQuestionDocument, {
      alias: 'setAnswerToQuestion',
      ...operationOptions
    });
};

/**
 * __useSetAnswerToQuestionMutation__
 *
 * To run a mutation, you first call `useSetAnswerToQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAnswerToQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAnswerToQuestionMutation, { data, loading, error }] = useSetAnswerToQuestionMutation({
 *   variables: {
 *      question: // value for 'question'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useSetAnswerToQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetAnswerToQuestionMutation, SetAnswerToQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<SetAnswerToQuestionMutation, SetAnswerToQuestionMutationVariables>(SetAnswerToQuestionDocument, baseOptions);
      }
export type SetAnswerToQuestionMutationHookResult = ReturnType<typeof useSetAnswerToQuestionMutation>;
export type SetAnswerToQuestionMutationResult = ApolloReactCommon.MutationResult<SetAnswerToQuestionMutation>;
export type SetAnswerToQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<SetAnswerToQuestionMutation, SetAnswerToQuestionMutationVariables>;
export const ValidatePayoutWalletDocument = gql`
    mutation ValidatePayoutWallet($id: String!, $data: JSON!) {
  validatePayoutWallet(id: $id, data: $data) {
    errorCode
    error {
      message
    }
    isSuccess
    wallet {
      id
      createdAt
      data
      isConfirmed
      cardInfo
    }
  }
}
    `;
export type ValidatePayoutWalletMutationFn = ApolloReactCommon.MutationFunction<ValidatePayoutWalletMutation, ValidatePayoutWalletMutationVariables>;
export type ValidatePayoutWalletProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ValidatePayoutWalletMutation, ValidatePayoutWalletMutationVariables> & TChildProps;
export function withValidatePayoutWallet<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ValidatePayoutWalletMutation,
  ValidatePayoutWalletMutationVariables,
  ValidatePayoutWalletProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ValidatePayoutWalletMutation, ValidatePayoutWalletMutationVariables, ValidatePayoutWalletProps<TChildProps>>(ValidatePayoutWalletDocument, {
      alias: 'validatePayoutWallet',
      ...operationOptions
    });
};

/**
 * __useValidatePayoutWalletMutation__
 *
 * To run a mutation, you first call `useValidatePayoutWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidatePayoutWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validatePayoutWalletMutation, { data, loading, error }] = useValidatePayoutWalletMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useValidatePayoutWalletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ValidatePayoutWalletMutation, ValidatePayoutWalletMutationVariables>) {
        return ApolloReactHooks.useMutation<ValidatePayoutWalletMutation, ValidatePayoutWalletMutationVariables>(ValidatePayoutWalletDocument, baseOptions);
      }
export type ValidatePayoutWalletMutationHookResult = ReturnType<typeof useValidatePayoutWalletMutation>;
export type ValidatePayoutWalletMutationResult = ApolloReactCommon.MutationResult<ValidatePayoutWalletMutation>;
export type ValidatePayoutWalletMutationOptions = ApolloReactCommon.BaseMutationOptions<ValidatePayoutWalletMutation, ValidatePayoutWalletMutationVariables>;
export const AddPayoutWalletDocument = gql`
    mutation AddPayoutWallet($id: String!, $data: JSON!) {
  addPayoutWallet(id: $id, data: $data) {
    errorCode
    error {
      message
    }
    isSuccess
    wallet {
      id
      createdAt
      data
      isConfirmed
      cardInfo
    }
  }
}
    `;
export type AddPayoutWalletMutationFn = ApolloReactCommon.MutationFunction<AddPayoutWalletMutation, AddPayoutWalletMutationVariables>;
export type AddPayoutWalletProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddPayoutWalletMutation, AddPayoutWalletMutationVariables> & TChildProps;
export function withAddPayoutWallet<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddPayoutWalletMutation,
  AddPayoutWalletMutationVariables,
  AddPayoutWalletProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddPayoutWalletMutation, AddPayoutWalletMutationVariables, AddPayoutWalletProps<TChildProps>>(AddPayoutWalletDocument, {
      alias: 'addPayoutWallet',
      ...operationOptions
    });
};

/**
 * __useAddPayoutWalletMutation__
 *
 * To run a mutation, you first call `useAddPayoutWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPayoutWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPayoutWalletMutation, { data, loading, error }] = useAddPayoutWalletMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddPayoutWalletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPayoutWalletMutation, AddPayoutWalletMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPayoutWalletMutation, AddPayoutWalletMutationVariables>(AddPayoutWalletDocument, baseOptions);
      }
export type AddPayoutWalletMutationHookResult = ReturnType<typeof useAddPayoutWalletMutation>;
export type AddPayoutWalletMutationResult = ApolloReactCommon.MutationResult<AddPayoutWalletMutation>;
export type AddPayoutWalletMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPayoutWalletMutation, AddPayoutWalletMutationVariables>;
export const RequestPayoutDocument = gql`
    mutation RequestPayout($payout: PayoutInput!) {
  requestPayout(payout: $payout) {
    errorCode
    error {
      message
    }
    isSuccess
    payout {
      ...PayoutMut
    }
  }
}
    ${PayoutMutFragmentDoc}`;
export type RequestPayoutMutationFn = ApolloReactCommon.MutationFunction<RequestPayoutMutation, RequestPayoutMutationVariables>;
export type RequestPayoutProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RequestPayoutMutation, RequestPayoutMutationVariables> & TChildProps;
export function withRequestPayout<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RequestPayoutMutation,
  RequestPayoutMutationVariables,
  RequestPayoutProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RequestPayoutMutation, RequestPayoutMutationVariables, RequestPayoutProps<TChildProps>>(RequestPayoutDocument, {
      alias: 'requestPayout',
      ...operationOptions
    });
};

/**
 * __useRequestPayoutMutation__
 *
 * To run a mutation, you first call `useRequestPayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPayoutMutation, { data, loading, error }] = useRequestPayoutMutation({
 *   variables: {
 *      payout: // value for 'payout'
 *   },
 * });
 */
export function useRequestPayoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestPayoutMutation, RequestPayoutMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestPayoutMutation, RequestPayoutMutationVariables>(RequestPayoutDocument, baseOptions);
      }
export type RequestPayoutMutationHookResult = ReturnType<typeof useRequestPayoutMutation>;
export type RequestPayoutMutationResult = ApolloReactCommon.MutationResult<RequestPayoutMutation>;
export type RequestPayoutMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestPayoutMutation, RequestPayoutMutationVariables>;
export const UpdateNotificationKeyDocument = gql`
    mutation UpdateNotificationKey($subscription: JSON!) {
  updateNotificationKey(subscription: $subscription) {
    isSuccess
    errorCode
    error {
      message
    }
  }
}
    `;
export type UpdateNotificationKeyMutationFn = ApolloReactCommon.MutationFunction<UpdateNotificationKeyMutation, UpdateNotificationKeyMutationVariables>;
export type UpdateNotificationKeyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateNotificationKeyMutation, UpdateNotificationKeyMutationVariables> & TChildProps;
export function withUpdateNotificationKey<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateNotificationKeyMutation,
  UpdateNotificationKeyMutationVariables,
  UpdateNotificationKeyProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateNotificationKeyMutation, UpdateNotificationKeyMutationVariables, UpdateNotificationKeyProps<TChildProps>>(UpdateNotificationKeyDocument, {
      alias: 'updateNotificationKey',
      ...operationOptions
    });
};

/**
 * __useUpdateNotificationKeyMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationKeyMutation, { data, loading, error }] = useUpdateNotificationKeyMutation({
 *   variables: {
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useUpdateNotificationKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotificationKeyMutation, UpdateNotificationKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNotificationKeyMutation, UpdateNotificationKeyMutationVariables>(UpdateNotificationKeyDocument, baseOptions);
      }
export type UpdateNotificationKeyMutationHookResult = ReturnType<typeof useUpdateNotificationKeyMutation>;
export type UpdateNotificationKeyMutationResult = ApolloReactCommon.MutationResult<UpdateNotificationKeyMutation>;
export type UpdateNotificationKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotificationKeyMutation, UpdateNotificationKeyMutationVariables>;
export const CreateReportDocument = gql`
    mutation CreateReport($report: ReportNodeInput!) {
  createReport(report: $report) {
    isSuccess
    errorCode
    error {
      message
    }
  }
}
    `;
export type CreateReportMutationFn = ApolloReactCommon.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;
export type CreateReportProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateReportMutation, CreateReportMutationVariables> & TChildProps;
export function withCreateReport<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateReportMutation,
  CreateReportMutationVariables,
  CreateReportProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateReportMutation, CreateReportMutationVariables, CreateReportProps<TChildProps>>(CreateReportDocument, {
      alias: 'createReport',
      ...operationOptions
    });
};

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      report: // value for 'report'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, baseOptions);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = ApolloReactCommon.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($email: String!) {
  confirmEmail(email: $email) {
    isSuccess
    errorCode
    error {
      message
    }
  }
}
    `;
export type ConfirmEmailMutationFn = ApolloReactCommon.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export type ConfirmEmailProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ConfirmEmailMutation, ConfirmEmailMutationVariables> & TChildProps;
export function withConfirmEmail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables,
  ConfirmEmailProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ConfirmEmailMutation, ConfirmEmailMutationVariables, ConfirmEmailProps<TChildProps>>(ConfirmEmailDocument, {
      alias: 'confirmEmail',
      ...operationOptions
    });
};

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, baseOptions);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = ApolloReactCommon.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const RemovePayoutWalletDocument = gql`
    mutation RemovePayoutWallet($id: UUID!) {
  removePayoutWallet(id: $id) {
    isSuccess
    error {
      message
    }
  }
}
    `;
export type RemovePayoutWalletMutationFn = ApolloReactCommon.MutationFunction<RemovePayoutWalletMutation, RemovePayoutWalletMutationVariables>;
export type RemovePayoutWalletProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemovePayoutWalletMutation, RemovePayoutWalletMutationVariables> & TChildProps;
export function withRemovePayoutWallet<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemovePayoutWalletMutation,
  RemovePayoutWalletMutationVariables,
  RemovePayoutWalletProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemovePayoutWalletMutation, RemovePayoutWalletMutationVariables, RemovePayoutWalletProps<TChildProps>>(RemovePayoutWalletDocument, {
      alias: 'removePayoutWallet',
      ...operationOptions
    });
};

/**
 * __useRemovePayoutWalletMutation__
 *
 * To run a mutation, you first call `useRemovePayoutWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePayoutWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePayoutWalletMutation, { data, loading, error }] = useRemovePayoutWalletMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePayoutWalletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePayoutWalletMutation, RemovePayoutWalletMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePayoutWalletMutation, RemovePayoutWalletMutationVariables>(RemovePayoutWalletDocument, baseOptions);
      }
export type RemovePayoutWalletMutationHookResult = ReturnType<typeof useRemovePayoutWalletMutation>;
export type RemovePayoutWalletMutationResult = ApolloReactCommon.MutationResult<RemovePayoutWalletMutation>;
export type RemovePayoutWalletMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePayoutWalletMutation, RemovePayoutWalletMutationVariables>;
export const ReportMissingKeyDocument = gql`
    mutation ReportMissingKey($key: String!, $stack: String!, $fallback: String) {
  submitMissingLocaleKey(key: $key, description: $stack, fallback: $fallback) {
    isSuccess
  }
}
    `;
export type ReportMissingKeyMutationFn = ApolloReactCommon.MutationFunction<ReportMissingKeyMutation, ReportMissingKeyMutationVariables>;
export type ReportMissingKeyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReportMissingKeyMutation, ReportMissingKeyMutationVariables> & TChildProps;
export function withReportMissingKey<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportMissingKeyMutation,
  ReportMissingKeyMutationVariables,
  ReportMissingKeyProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReportMissingKeyMutation, ReportMissingKeyMutationVariables, ReportMissingKeyProps<TChildProps>>(ReportMissingKeyDocument, {
      alias: 'reportMissingKey',
      ...operationOptions
    });
};

/**
 * __useReportMissingKeyMutation__
 *
 * To run a mutation, you first call `useReportMissingKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportMissingKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportMissingKeyMutation, { data, loading, error }] = useReportMissingKeyMutation({
 *   variables: {
 *      key: // value for 'key'
 *      stack: // value for 'stack'
 *      fallback: // value for 'fallback'
 *   },
 * });
 */
export function useReportMissingKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportMissingKeyMutation, ReportMissingKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportMissingKeyMutation, ReportMissingKeyMutationVariables>(ReportMissingKeyDocument, baseOptions);
      }
export type ReportMissingKeyMutationHookResult = ReturnType<typeof useReportMissingKeyMutation>;
export type ReportMissingKeyMutationResult = ApolloReactCommon.MutationResult<ReportMissingKeyMutation>;
export type ReportMissingKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportMissingKeyMutation, ReportMissingKeyMutationVariables>;
export const SubmitEventDocument = gql`
    mutation SubmitEvent($data: String!) {
  trackEvent(event: $data) {
    isSuccess
  }
}
    `;
export type SubmitEventMutationFn = ApolloReactCommon.MutationFunction<SubmitEventMutation, SubmitEventMutationVariables>;
export type SubmitEventProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SubmitEventMutation, SubmitEventMutationVariables> & TChildProps;
export function withSubmitEvent<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmitEventMutation,
  SubmitEventMutationVariables,
  SubmitEventProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SubmitEventMutation, SubmitEventMutationVariables, SubmitEventProps<TChildProps>>(SubmitEventDocument, {
      alias: 'submitEvent',
      ...operationOptions
    });
};

/**
 * __useSubmitEventMutation__
 *
 * To run a mutation, you first call `useSubmitEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitEventMutation, { data, loading, error }] = useSubmitEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitEventMutation, SubmitEventMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitEventMutation, SubmitEventMutationVariables>(SubmitEventDocument, baseOptions);
      }
export type SubmitEventMutationHookResult = ReturnType<typeof useSubmitEventMutation>;
export type SubmitEventMutationResult = ApolloReactCommon.MutationResult<SubmitEventMutation>;
export type SubmitEventMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitEventMutation, SubmitEventMutationVariables>;
export const SubmitHelpRequestDocument = gql`
    mutation SubmitHelpRequest($data: HelpRequest!) {
  submitHelpRequest(request: $data) {
    isSuccess
    error {
      message
    }
  }
}
    `;
export type SubmitHelpRequestMutationFn = ApolloReactCommon.MutationFunction<SubmitHelpRequestMutation, SubmitHelpRequestMutationVariables>;
export type SubmitHelpRequestProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SubmitHelpRequestMutation, SubmitHelpRequestMutationVariables> & TChildProps;
export function withSubmitHelpRequest<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmitHelpRequestMutation,
  SubmitHelpRequestMutationVariables,
  SubmitHelpRequestProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SubmitHelpRequestMutation, SubmitHelpRequestMutationVariables, SubmitHelpRequestProps<TChildProps>>(SubmitHelpRequestDocument, {
      alias: 'submitHelpRequest',
      ...operationOptions
    });
};

/**
 * __useSubmitHelpRequestMutation__
 *
 * To run a mutation, you first call `useSubmitHelpRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitHelpRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitHelpRequestMutation, { data, loading, error }] = useSubmitHelpRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitHelpRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitHelpRequestMutation, SubmitHelpRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitHelpRequestMutation, SubmitHelpRequestMutationVariables>(SubmitHelpRequestDocument, baseOptions);
      }
export type SubmitHelpRequestMutationHookResult = ReturnType<typeof useSubmitHelpRequestMutation>;
export type SubmitHelpRequestMutationResult = ApolloReactCommon.MutationResult<SubmitHelpRequestMutation>;
export type SubmitHelpRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitHelpRequestMutation, SubmitHelpRequestMutationVariables>;
export const ReverseAccountRemovalDocument = gql`
    mutation ReverseAccountRemoval {
  reverseAccountRemoval {
    isSuccess
    error {
      message
    }
  }
}
    `;
export type ReverseAccountRemovalMutationFn = ApolloReactCommon.MutationFunction<ReverseAccountRemovalMutation, ReverseAccountRemovalMutationVariables>;
export type ReverseAccountRemovalProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReverseAccountRemovalMutation, ReverseAccountRemovalMutationVariables> & TChildProps;
export function withReverseAccountRemoval<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReverseAccountRemovalMutation,
  ReverseAccountRemovalMutationVariables,
  ReverseAccountRemovalProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReverseAccountRemovalMutation, ReverseAccountRemovalMutationVariables, ReverseAccountRemovalProps<TChildProps>>(ReverseAccountRemovalDocument, {
      alias: 'reverseAccountRemoval',
      ...operationOptions
    });
};

/**
 * __useReverseAccountRemovalMutation__
 *
 * To run a mutation, you first call `useReverseAccountRemovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReverseAccountRemovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reverseAccountRemovalMutation, { data, loading, error }] = useReverseAccountRemovalMutation({
 *   variables: {
 *   },
 * });
 */
export function useReverseAccountRemovalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReverseAccountRemovalMutation, ReverseAccountRemovalMutationVariables>) {
        return ApolloReactHooks.useMutation<ReverseAccountRemovalMutation, ReverseAccountRemovalMutationVariables>(ReverseAccountRemovalDocument, baseOptions);
      }
export type ReverseAccountRemovalMutationHookResult = ReturnType<typeof useReverseAccountRemovalMutation>;
export type ReverseAccountRemovalMutationResult = ApolloReactCommon.MutationResult<ReverseAccountRemovalMutation>;
export type ReverseAccountRemovalMutationOptions = ApolloReactCommon.BaseMutationOptions<ReverseAccountRemovalMutation, ReverseAccountRemovalMutationVariables>;
export const RemoveAccountDocument = gql`
    mutation RemoveAccount($reason: [String!]!) {
  removeAccount(reason: $reason) {
    isSuccess
    error {
      message
    }
  }
}
    `;
export type RemoveAccountMutationFn = ApolloReactCommon.MutationFunction<RemoveAccountMutation, RemoveAccountMutationVariables>;
export type RemoveAccountProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveAccountMutation, RemoveAccountMutationVariables> & TChildProps;
export function withRemoveAccount<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveAccountMutation,
  RemoveAccountMutationVariables,
  RemoveAccountProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveAccountMutation, RemoveAccountMutationVariables, RemoveAccountProps<TChildProps>>(RemoveAccountDocument, {
      alias: 'removeAccount',
      ...operationOptions
    });
};

/**
 * __useRemoveAccountMutation__
 *
 * To run a mutation, you first call `useRemoveAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountMutation, { data, loading, error }] = useRemoveAccountMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useRemoveAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAccountMutation, RemoveAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAccountMutation, RemoveAccountMutationVariables>(RemoveAccountDocument, baseOptions);
      }
export type RemoveAccountMutationHookResult = ReturnType<typeof useRemoveAccountMutation>;
export type RemoveAccountMutationResult = ApolloReactCommon.MutationResult<RemoveAccountMutation>;
export type RemoveAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAccountMutation, RemoveAccountMutationVariables>;
export const AddVkChannelDocument = gql`
    mutation AddVkChannel($data: String!) {
  addNotificationChannel(type: vk, data: $data) {
    isSuccess
    error {
      message
    }
    errorCode
  }
}
    `;
export type AddVkChannelMutationFn = ApolloReactCommon.MutationFunction<AddVkChannelMutation, AddVkChannelMutationVariables>;
export type AddVkChannelProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddVkChannelMutation, AddVkChannelMutationVariables> & TChildProps;
export function withAddVkChannel<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddVkChannelMutation,
  AddVkChannelMutationVariables,
  AddVkChannelProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddVkChannelMutation, AddVkChannelMutationVariables, AddVkChannelProps<TChildProps>>(AddVkChannelDocument, {
      alias: 'addVkChannel',
      ...operationOptions
    });
};

/**
 * __useAddVkChannelMutation__
 *
 * To run a mutation, you first call `useAddVkChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVkChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVkChannelMutation, { data, loading, error }] = useAddVkChannelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddVkChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVkChannelMutation, AddVkChannelMutationVariables>) {
        return ApolloReactHooks.useMutation<AddVkChannelMutation, AddVkChannelMutationVariables>(AddVkChannelDocument, baseOptions);
      }
export type AddVkChannelMutationHookResult = ReturnType<typeof useAddVkChannelMutation>;
export type AddVkChannelMutationResult = ApolloReactCommon.MutationResult<AddVkChannelMutation>;
export type AddVkChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<AddVkChannelMutation, AddVkChannelMutationVariables>;
export const RemoveVkChannelDocument = gql`
    mutation RemoveVkChannel($data: String!) {
  removeNotificationChannel(type: vk, data: $data) {
    isSuccess
    error {
      message
    }
    errorCode
  }
}
    `;
export type RemoveVkChannelMutationFn = ApolloReactCommon.MutationFunction<RemoveVkChannelMutation, RemoveVkChannelMutationVariables>;
export type RemoveVkChannelProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveVkChannelMutation, RemoveVkChannelMutationVariables> & TChildProps;
export function withRemoveVkChannel<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveVkChannelMutation,
  RemoveVkChannelMutationVariables,
  RemoveVkChannelProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveVkChannelMutation, RemoveVkChannelMutationVariables, RemoveVkChannelProps<TChildProps>>(RemoveVkChannelDocument, {
      alias: 'removeVkChannel',
      ...operationOptions
    });
};

/**
 * __useRemoveVkChannelMutation__
 *
 * To run a mutation, you first call `useRemoveVkChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVkChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVkChannelMutation, { data, loading, error }] = useRemoveVkChannelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveVkChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveVkChannelMutation, RemoveVkChannelMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveVkChannelMutation, RemoveVkChannelMutationVariables>(RemoveVkChannelDocument, baseOptions);
      }
export type RemoveVkChannelMutationHookResult = ReturnType<typeof useRemoveVkChannelMutation>;
export type RemoveVkChannelMutationResult = ApolloReactCommon.MutationResult<RemoveVkChannelMutation>;
export type RemoveVkChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveVkChannelMutation, RemoveVkChannelMutationVariables>;
export const LookupAddressDocument = gql`
    query LookupAddress($search: String!) {
  searchAddress(search: $search) {
    getZipCode {
      id
    }
    suggestions {
      value
      postal_code
      isPrecise
    }
    isValidZipCode
  }
}
    `;
export type LookupAddressProps<TChildProps = {}> = ApolloReactHoc.DataProps<LookupAddressQuery, LookupAddressQueryVariables> & TChildProps;
export function withLookupAddress<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LookupAddressQuery,
  LookupAddressQueryVariables,
  LookupAddressProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, LookupAddressQuery, LookupAddressQueryVariables, LookupAddressProps<TChildProps>>(LookupAddressDocument, {
      alias: 'lookupAddress',
      ...operationOptions
    });
};

/**
 * __useLookupAddressQuery__
 *
 * To run a query within a React component, call `useLookupAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupAddressQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useLookupAddressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LookupAddressQuery, LookupAddressQueryVariables>) {
        return ApolloReactHooks.useQuery<LookupAddressQuery, LookupAddressQueryVariables>(LookupAddressDocument, baseOptions);
      }
export function useLookupAddressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LookupAddressQuery, LookupAddressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LookupAddressQuery, LookupAddressQueryVariables>(LookupAddressDocument, baseOptions);
        }
export type LookupAddressQueryHookResult = ReturnType<typeof useLookupAddressQuery>;
export type LookupAddressLazyQueryHookResult = ReturnType<typeof useLookupAddressLazyQuery>;
export type LookupAddressQueryResult = ApolloReactCommon.QueryResult<LookupAddressQuery, LookupAddressQueryVariables>;
export const CurrentUserQueryDocument = gql`
    query CurrentUserQuery {
  currentUser {
    ...CurrentUser
    accounts {
      ...UserAccount
    }
  }
}
    ${CurrentUserFragmentDoc}
${UserAccountFragmentDoc}`;
export type CurrentUserQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<CurrentUserQueryQuery, CurrentUserQueryQueryVariables> & TChildProps;
export function withCurrentUserQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CurrentUserQueryQuery,
  CurrentUserQueryQueryVariables,
  CurrentUserQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CurrentUserQueryQuery, CurrentUserQueryQueryVariables, CurrentUserQueryProps<TChildProps>>(CurrentUserQueryDocument, {
      alias: 'currentUserQuery',
      ...operationOptions
    });
};

/**
 * __useCurrentUserQueryQuery__
 *
 * To run a query within a React component, call `useCurrentUserQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>(CurrentUserQueryDocument, baseOptions);
      }
export function useCurrentUserQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>(CurrentUserQueryDocument, baseOptions);
        }
export type CurrentUserQueryQueryHookResult = ReturnType<typeof useCurrentUserQueryQuery>;
export type CurrentUserQueryLazyQueryHookResult = ReturnType<typeof useCurrentUserQueryLazyQuery>;
export type CurrentUserQueryQueryResult = ApolloReactCommon.QueryResult<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications {
  currentUser {
    id
    notifications {
      id
      name {
        message
      }
      value {
        message
      }
      details
      red
      type
    }
  }
}
    `;
export type GetNotificationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetNotificationsQuery, GetNotificationsQueryVariables> & TChildProps;
export function withGetNotifications<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
  GetNotificationsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetNotificationsQuery, GetNotificationsQueryVariables, GetNotificationsProps<TChildProps>>(GetNotificationsDocument, {
      alias: 'getNotifications',
      ...operationOptions
    });
};

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
      }
export function useGetNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = ApolloReactCommon.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const ReportsQueryDocument = gql`
    query ReportsQuery {
  currentUser {
    id
    reports {
      total
      totalAmount {
        currencyType {
          type
        }
        value
      }
      totalApproved
      reports {
        id
        type
        outerOperationName {
          message
        }
        createdAt
        updatedAt
        amount
        status
        rejectionReason
      }
    }
  }
}
    `;
export type ReportsQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<ReportsQueryQuery, ReportsQueryQueryVariables> & TChildProps;
export function withReportsQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportsQueryQuery,
  ReportsQueryQueryVariables,
  ReportsQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ReportsQueryQuery, ReportsQueryQueryVariables, ReportsQueryProps<TChildProps>>(ReportsQueryDocument, {
      alias: 'reportsQuery',
      ...operationOptions
    });
};

/**
 * __useReportsQueryQuery__
 *
 * To run a query within a React component, call `useReportsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportsQueryQuery, ReportsQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportsQueryQuery, ReportsQueryQueryVariables>(ReportsQueryDocument, baseOptions);
      }
export function useReportsQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportsQueryQuery, ReportsQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportsQueryQuery, ReportsQueryQueryVariables>(ReportsQueryDocument, baseOptions);
        }
export type ReportsQueryQueryHookResult = ReturnType<typeof useReportsQueryQuery>;
export type ReportsQueryLazyQueryHookResult = ReturnType<typeof useReportsQueryLazyQuery>;
export type ReportsQueryQueryResult = ApolloReactCommon.QueryResult<ReportsQueryQuery, ReportsQueryQueryVariables>;
export const TasksQueryDocument = gql`
    query TasksQuery {
  currentUser {
    id
    tasks {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;
export type TasksQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<TasksQueryQuery, TasksQueryQueryVariables> & TChildProps;
export function withTasksQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TasksQueryQuery,
  TasksQueryQueryVariables,
  TasksQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, TasksQueryQuery, TasksQueryQueryVariables, TasksQueryProps<TChildProps>>(TasksQueryDocument, {
      alias: 'tasksQuery',
      ...operationOptions
    });
};

/**
 * __useTasksQueryQuery__
 *
 * To run a query within a React component, call `useTasksQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useTasksQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TasksQueryQuery, TasksQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<TasksQueryQuery, TasksQueryQueryVariables>(TasksQueryDocument, baseOptions);
      }
export function useTasksQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TasksQueryQuery, TasksQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TasksQueryQuery, TasksQueryQueryVariables>(TasksQueryDocument, baseOptions);
        }
export type TasksQueryQueryHookResult = ReturnType<typeof useTasksQueryQuery>;
export type TasksQueryLazyQueryHookResult = ReturnType<typeof useTasksQueryLazyQuery>;
export type TasksQueryQueryResult = ApolloReactCommon.QueryResult<TasksQueryQuery, TasksQueryQueryVariables>;
export const FaqQueryDocument = gql`
    query FaqQuery {
  faq {
    id
    name {
      message
    }
    text {
      message
    }
  }
}
    `;
export type FaqQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<FaqQueryQuery, FaqQueryQueryVariables> & TChildProps;
export function withFaqQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FaqQueryQuery,
  FaqQueryQueryVariables,
  FaqQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, FaqQueryQuery, FaqQueryQueryVariables, FaqQueryProps<TChildProps>>(FaqQueryDocument, {
      alias: 'faqQuery',
      ...operationOptions
    });
};

/**
 * __useFaqQueryQuery__
 *
 * To run a query within a React component, call `useFaqQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFaqQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FaqQueryQuery, FaqQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<FaqQueryQuery, FaqQueryQueryVariables>(FaqQueryDocument, baseOptions);
      }
export function useFaqQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FaqQueryQuery, FaqQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FaqQueryQuery, FaqQueryQueryVariables>(FaqQueryDocument, baseOptions);
        }
export type FaqQueryQueryHookResult = ReturnType<typeof useFaqQueryQuery>;
export type FaqQueryLazyQueryHookResult = ReturnType<typeof useFaqQueryLazyQuery>;
export type FaqQueryQueryResult = ApolloReactCommon.QueryResult<FaqQueryQuery, FaqQueryQueryVariables>;
export const GetLocationDocument = gql`
    query GetLocation {
  locationInfo {
    country {
      message
    }
    countryIsoCode
    isLocaleEnforced
    isAvailableToRegister
  }
}
    `;
export type GetLocationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLocationQuery, GetLocationQueryVariables> & TChildProps;
export function withGetLocation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLocationQuery,
  GetLocationQueryVariables,
  GetLocationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLocationQuery, GetLocationQueryVariables, GetLocationProps<TChildProps>>(GetLocationDocument, {
      alias: 'getLocation',
      ...operationOptions
    });
};

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, baseOptions);
      }
export function useGetLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, baseOptions);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = ApolloReactCommon.QueryResult<GetLocationQuery, GetLocationQueryVariables>;
export const PayoutsHistoryDocument = gql`
    query PayoutsHistory {
  currentUser {
    id
    payouts {
      ...PayoutFrag
    }
  }
}
    ${PayoutFragFragmentDoc}`;
export type PayoutsHistoryProps<TChildProps = {}> = ApolloReactHoc.DataProps<PayoutsHistoryQuery, PayoutsHistoryQueryVariables> & TChildProps;
export function withPayoutsHistory<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PayoutsHistoryQuery,
  PayoutsHistoryQueryVariables,
  PayoutsHistoryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PayoutsHistoryQuery, PayoutsHistoryQueryVariables, PayoutsHistoryProps<TChildProps>>(PayoutsHistoryDocument, {
      alias: 'payoutsHistory',
      ...operationOptions
    });
};

/**
 * __usePayoutsHistoryQuery__
 *
 * To run a query within a React component, call `usePayoutsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoutsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoutsHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayoutsHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayoutsHistoryQuery, PayoutsHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<PayoutsHistoryQuery, PayoutsHistoryQueryVariables>(PayoutsHistoryDocument, baseOptions);
      }
export function usePayoutsHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayoutsHistoryQuery, PayoutsHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayoutsHistoryQuery, PayoutsHistoryQueryVariables>(PayoutsHistoryDocument, baseOptions);
        }
export type PayoutsHistoryQueryHookResult = ReturnType<typeof usePayoutsHistoryQuery>;
export type PayoutsHistoryLazyQueryHookResult = ReturnType<typeof usePayoutsHistoryLazyQuery>;
export type PayoutsHistoryQueryResult = ApolloReactCommon.QueryResult<PayoutsHistoryQuery, PayoutsHistoryQueryVariables>;
export const PayoutsQueryDocument = gql`
    query PayoutsQuery {
  payoutMethods {
    id
    name {
      message
    }
    minimalPayout {
      currencyType {
        type
      }
      value
    }
    isDisabled
    fee
    ui {
      title
      formatter {
        type
        country
      }
      icon
      label
      placeholder
      description
    }
    currentWallet {
      id
      createdAt
      data
      isConfirmed
      cardInfo
    }
  }
}
    `;
export type PayoutsQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<PayoutsQueryQuery, PayoutsQueryQueryVariables> & TChildProps;
export function withPayoutsQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PayoutsQueryQuery,
  PayoutsQueryQueryVariables,
  PayoutsQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PayoutsQueryQuery, PayoutsQueryQueryVariables, PayoutsQueryProps<TChildProps>>(PayoutsQueryDocument, {
      alias: 'payoutsQuery',
      ...operationOptions
    });
};

/**
 * __usePayoutsQueryQuery__
 *
 * To run a query within a React component, call `usePayoutsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoutsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoutsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayoutsQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayoutsQueryQuery, PayoutsQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<PayoutsQueryQuery, PayoutsQueryQueryVariables>(PayoutsQueryDocument, baseOptions);
      }
export function usePayoutsQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayoutsQueryQuery, PayoutsQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayoutsQueryQuery, PayoutsQueryQueryVariables>(PayoutsQueryDocument, baseOptions);
        }
export type PayoutsQueryQueryHookResult = ReturnType<typeof usePayoutsQueryQuery>;
export type PayoutsQueryLazyQueryHookResult = ReturnType<typeof usePayoutsQueryLazyQuery>;
export type PayoutsQueryQueryResult = ApolloReactCommon.QueryResult<PayoutsQueryQuery, PayoutsQueryQueryVariables>;
export const RestoreTokenQueryDocument = gql`
    query RestoreTokenQuery($userId: String!, $restoreToken: String!) {
  restoreToken(userId: $userId, restoreToken: $restoreToken) {
    errorCode
    error {
      message
    }
    isSuccess
  }
}
    `;
export type RestoreTokenQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<RestoreTokenQueryQuery, RestoreTokenQueryQueryVariables> & TChildProps;
export function withRestoreTokenQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RestoreTokenQueryQuery,
  RestoreTokenQueryQueryVariables,
  RestoreTokenQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, RestoreTokenQueryQuery, RestoreTokenQueryQueryVariables, RestoreTokenQueryProps<TChildProps>>(RestoreTokenQueryDocument, {
      alias: 'restoreTokenQuery',
      ...operationOptions
    });
};

/**
 * __useRestoreTokenQueryQuery__
 *
 * To run a query within a React component, call `useRestoreTokenQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestoreTokenQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoreTokenQueryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      restoreToken: // value for 'restoreToken'
 *   },
 * });
 */
export function useRestoreTokenQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestoreTokenQueryQuery, RestoreTokenQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<RestoreTokenQueryQuery, RestoreTokenQueryQueryVariables>(RestoreTokenQueryDocument, baseOptions);
      }
export function useRestoreTokenQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestoreTokenQueryQuery, RestoreTokenQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestoreTokenQueryQuery, RestoreTokenQueryQueryVariables>(RestoreTokenQueryDocument, baseOptions);
        }
export type RestoreTokenQueryQueryHookResult = ReturnType<typeof useRestoreTokenQueryQuery>;
export type RestoreTokenQueryLazyQueryHookResult = ReturnType<typeof useRestoreTokenQueryLazyQuery>;
export type RestoreTokenQueryQueryResult = ApolloReactCommon.QueryResult<RestoreTokenQueryQuery, RestoreTokenQueryQueryVariables>;
export const GetLocaleDataQueryDocument = gql`
    query GetLocaleDataQuery($locale: Language) {
  getLocaleData(locale: $locale) {
    id
    key
    value
  }
}
    `;
export type GetLocaleDataQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLocaleDataQueryQuery, GetLocaleDataQueryQueryVariables> & TChildProps;
export function withGetLocaleDataQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLocaleDataQueryQuery,
  GetLocaleDataQueryQueryVariables,
  GetLocaleDataQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLocaleDataQueryQuery, GetLocaleDataQueryQueryVariables, GetLocaleDataQueryProps<TChildProps>>(GetLocaleDataQueryDocument, {
      alias: 'getLocaleDataQuery',
      ...operationOptions
    });
};

/**
 * __useGetLocaleDataQueryQuery__
 *
 * To run a query within a React component, call `useGetLocaleDataQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocaleDataQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocaleDataQueryQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetLocaleDataQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocaleDataQueryQuery, GetLocaleDataQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocaleDataQueryQuery, GetLocaleDataQueryQueryVariables>(GetLocaleDataQueryDocument, baseOptions);
      }
export function useGetLocaleDataQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocaleDataQueryQuery, GetLocaleDataQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocaleDataQueryQuery, GetLocaleDataQueryQueryVariables>(GetLocaleDataQueryDocument, baseOptions);
        }
export type GetLocaleDataQueryQueryHookResult = ReturnType<typeof useGetLocaleDataQueryQuery>;
export type GetLocaleDataQueryLazyQueryHookResult = ReturnType<typeof useGetLocaleDataQueryLazyQuery>;
export type GetLocaleDataQueryQueryResult = ApolloReactCommon.QueryResult<GetLocaleDataQueryQuery, GetLocaleDataQueryQueryVariables>;
export const GetNotificationSectionsDocument = gql`
    query GetNotificationSections {
  currentUser {
    id
    notificationSections {
      id
      when
      name {
        message
      }
      isSubscribedWithPushes
      isSubscribedWithEmail
    }
  }
}
    `;
export type GetNotificationSectionsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetNotificationSectionsQuery, GetNotificationSectionsQueryVariables> & TChildProps;
export function withGetNotificationSections<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNotificationSectionsQuery,
  GetNotificationSectionsQueryVariables,
  GetNotificationSectionsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetNotificationSectionsQuery, GetNotificationSectionsQueryVariables, GetNotificationSectionsProps<TChildProps>>(GetNotificationSectionsDocument, {
      alias: 'getNotificationSections',
      ...operationOptions
    });
};

/**
 * __useGetNotificationSectionsQuery__
 *
 * To run a query within a React component, call `useGetNotificationSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationSectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationSectionsQuery, GetNotificationSectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationSectionsQuery, GetNotificationSectionsQueryVariables>(GetNotificationSectionsDocument, baseOptions);
      }
export function useGetNotificationSectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationSectionsQuery, GetNotificationSectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationSectionsQuery, GetNotificationSectionsQueryVariables>(GetNotificationSectionsDocument, baseOptions);
        }
export type GetNotificationSectionsQueryHookResult = ReturnType<typeof useGetNotificationSectionsQuery>;
export type GetNotificationSectionsLazyQueryHookResult = ReturnType<typeof useGetNotificationSectionsLazyQuery>;
export type GetNotificationSectionsQueryResult = ApolloReactCommon.QueryResult<GetNotificationSectionsQuery, GetNotificationSectionsQueryVariables>;
export const ProfileDataQueryDocument = gql`
    query ProfileDataQuery {
  questionCategories {
    id
    code
    name {
      message
    }
    isRewardAffiliated
    isRewardGiven
    reward {
      value
    }
    unansweredQuestionsLength
    questionsLength
  }
  currentUser {
    id
    notificationSections {
      id
      when
      name {
        message
      }
      isSubscribedWithPushes
      isSubscribedWithEmail
    }
  }
}
    `;
export type ProfileDataQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<ProfileDataQueryQuery, ProfileDataQueryQueryVariables> & TChildProps;
export function withProfileDataQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProfileDataQueryQuery,
  ProfileDataQueryQueryVariables,
  ProfileDataQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ProfileDataQueryQuery, ProfileDataQueryQueryVariables, ProfileDataQueryProps<TChildProps>>(ProfileDataQueryDocument, {
      alias: 'profileDataQuery',
      ...operationOptions
    });
};

/**
 * __useProfileDataQueryQuery__
 *
 * To run a query within a React component, call `useProfileDataQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileDataQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileDataQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileDataQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileDataQueryQuery, ProfileDataQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<ProfileDataQueryQuery, ProfileDataQueryQueryVariables>(ProfileDataQueryDocument, baseOptions);
      }
export function useProfileDataQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileDataQueryQuery, ProfileDataQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProfileDataQueryQuery, ProfileDataQueryQueryVariables>(ProfileDataQueryDocument, baseOptions);
        }
export type ProfileDataQueryQueryHookResult = ReturnType<typeof useProfileDataQueryQuery>;
export type ProfileDataQueryLazyQueryHookResult = ReturnType<typeof useProfileDataQueryLazyQuery>;
export type ProfileDataQueryQueryResult = ApolloReactCommon.QueryResult<ProfileDataQueryQuery, ProfileDataQueryQueryVariables>;
export const VapidKeyDocument = gql`
    query VapidKey {
  vapidKey
}
    `;
export type VapidKeyProps<TChildProps = {}> = ApolloReactHoc.DataProps<VapidKeyQuery, VapidKeyQueryVariables> & TChildProps;
export function withVapidKey<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VapidKeyQuery,
  VapidKeyQueryVariables,
  VapidKeyProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, VapidKeyQuery, VapidKeyQueryVariables, VapidKeyProps<TChildProps>>(VapidKeyDocument, {
      alias: 'vapidKey',
      ...operationOptions
    });
};

/**
 * __useVapidKeyQuery__
 *
 * To run a query within a React component, call `useVapidKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useVapidKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVapidKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useVapidKeyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VapidKeyQuery, VapidKeyQueryVariables>) {
        return ApolloReactHooks.useQuery<VapidKeyQuery, VapidKeyQueryVariables>(VapidKeyDocument, baseOptions);
      }
export function useVapidKeyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VapidKeyQuery, VapidKeyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VapidKeyQuery, VapidKeyQueryVariables>(VapidKeyDocument, baseOptions);
        }
export type VapidKeyQueryHookResult = ReturnType<typeof useVapidKeyQuery>;
export type VapidKeyLazyQueryHookResult = ReturnType<typeof useVapidKeyLazyQuery>;
export type VapidKeyQueryResult = ApolloReactCommon.QueryResult<VapidKeyQuery, VapidKeyQueryVariables>;
export const QuestionCategoriesQueryDocument = gql`
    query QuestionCategoriesQuery {
  questionCategories {
    id
    code
    name {
      message
    }
    isRewardAffiliated
    isRewardGiven
    reward {
      value
    }
    unansweredQuestionsLength
    questionsLength
  }
}
    `;
export type QuestionCategoriesQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<QuestionCategoriesQueryQuery, QuestionCategoriesQueryQueryVariables> & TChildProps;
export function withQuestionCategoriesQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuestionCategoriesQueryQuery,
  QuestionCategoriesQueryQueryVariables,
  QuestionCategoriesQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, QuestionCategoriesQueryQuery, QuestionCategoriesQueryQueryVariables, QuestionCategoriesQueryProps<TChildProps>>(QuestionCategoriesQueryDocument, {
      alias: 'questionCategoriesQuery',
      ...operationOptions
    });
};

/**
 * __useQuestionCategoriesQueryQuery__
 *
 * To run a query within a React component, call `useQuestionCategoriesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionCategoriesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionCategoriesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionCategoriesQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QuestionCategoriesQueryQuery, QuestionCategoriesQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<QuestionCategoriesQueryQuery, QuestionCategoriesQueryQueryVariables>(QuestionCategoriesQueryDocument, baseOptions);
      }
export function useQuestionCategoriesQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuestionCategoriesQueryQuery, QuestionCategoriesQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuestionCategoriesQueryQuery, QuestionCategoriesQueryQueryVariables>(QuestionCategoriesQueryDocument, baseOptions);
        }
export type QuestionCategoriesQueryQueryHookResult = ReturnType<typeof useQuestionCategoriesQueryQuery>;
export type QuestionCategoriesQueryLazyQueryHookResult = ReturnType<typeof useQuestionCategoriesQueryLazyQuery>;
export type QuestionCategoriesQueryQueryResult = ApolloReactCommon.QueryResult<QuestionCategoriesQueryQuery, QuestionCategoriesQueryQueryVariables>;
export const QuestionCategoryQueryDocument = gql`
    query QuestionCategoryQuery($id: Float!) {
  questionCategory(id: $id) {
    id
    code
    name {
      message
    }
    isRewardAffiliated
    isRewardGiven
    reward {
      value
    }
    unansweredQuestionsLength
    questionsLength
    questions {
      ...QuestionFields
    }
  }
}
    ${QuestionFieldsFragmentDoc}`;
export type QuestionCategoryQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<QuestionCategoryQueryQuery, QuestionCategoryQueryQueryVariables> & TChildProps;
export function withQuestionCategoryQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuestionCategoryQueryQuery,
  QuestionCategoryQueryQueryVariables,
  QuestionCategoryQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, QuestionCategoryQueryQuery, QuestionCategoryQueryQueryVariables, QuestionCategoryQueryProps<TChildProps>>(QuestionCategoryQueryDocument, {
      alias: 'questionCategoryQuery',
      ...operationOptions
    });
};

/**
 * __useQuestionCategoryQueryQuery__
 *
 * To run a query within a React component, call `useQuestionCategoryQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionCategoryQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionCategoryQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuestionCategoryQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QuestionCategoryQueryQuery, QuestionCategoryQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<QuestionCategoryQueryQuery, QuestionCategoryQueryQueryVariables>(QuestionCategoryQueryDocument, baseOptions);
      }
export function useQuestionCategoryQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuestionCategoryQueryQuery, QuestionCategoryQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuestionCategoryQueryQuery, QuestionCategoryQueryQueryVariables>(QuestionCategoryQueryDocument, baseOptions);
        }
export type QuestionCategoryQueryQueryHookResult = ReturnType<typeof useQuestionCategoryQueryQuery>;
export type QuestionCategoryQueryLazyQueryHookResult = ReturnType<typeof useQuestionCategoryQueryLazyQuery>;
export type QuestionCategoryQueryQueryResult = ApolloReactCommon.QueryResult<QuestionCategoryQueryQuery, QuestionCategoryQueryQueryVariables>;
export const QuestionSuggestionDocument = gql`
    query QuestionSuggestion($question: Float!, $answer: JSON!) {
  questionSuggestion(question: $question, answer: $answer) {
    id
    text {
      message
    }
  }
}
    `;
export type QuestionSuggestionProps<TChildProps = {}> = ApolloReactHoc.DataProps<QuestionSuggestionQuery, QuestionSuggestionQueryVariables> & TChildProps;
export function withQuestionSuggestion<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuestionSuggestionQuery,
  QuestionSuggestionQueryVariables,
  QuestionSuggestionProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, QuestionSuggestionQuery, QuestionSuggestionQueryVariables, QuestionSuggestionProps<TChildProps>>(QuestionSuggestionDocument, {
      alias: 'questionSuggestion',
      ...operationOptions
    });
};

/**
 * __useQuestionSuggestionQuery__
 *
 * To run a query within a React component, call `useQuestionSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionSuggestionQuery({
 *   variables: {
 *      question: // value for 'question'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useQuestionSuggestionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QuestionSuggestionQuery, QuestionSuggestionQueryVariables>) {
        return ApolloReactHooks.useQuery<QuestionSuggestionQuery, QuestionSuggestionQueryVariables>(QuestionSuggestionDocument, baseOptions);
      }
export function useQuestionSuggestionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuestionSuggestionQuery, QuestionSuggestionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuestionSuggestionQuery, QuestionSuggestionQueryVariables>(QuestionSuggestionDocument, baseOptions);
        }
export type QuestionSuggestionQueryHookResult = ReturnType<typeof useQuestionSuggestionQuery>;
export type QuestionSuggestionLazyQueryHookResult = ReturnType<typeof useQuestionSuggestionLazyQuery>;
export type QuestionSuggestionQueryResult = ApolloReactCommon.QueryResult<QuestionSuggestionQuery, QuestionSuggestionQueryVariables>;
export const RemovalReasonsDocument = gql`
    query RemovalReasons {
  removalReasons {
    customReason
    description
    name
    id
  }
}
    `;
export type RemovalReasonsProps<TChildProps = {}> = ApolloReactHoc.DataProps<RemovalReasonsQuery, RemovalReasonsQueryVariables> & TChildProps;
export function withRemovalReasons<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemovalReasonsQuery,
  RemovalReasonsQueryVariables,
  RemovalReasonsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, RemovalReasonsQuery, RemovalReasonsQueryVariables, RemovalReasonsProps<TChildProps>>(RemovalReasonsDocument, {
      alias: 'removalReasons',
      ...operationOptions
    });
};

/**
 * __useRemovalReasonsQuery__
 *
 * To run a query within a React component, call `useRemovalReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemovalReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemovalReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRemovalReasonsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RemovalReasonsQuery, RemovalReasonsQueryVariables>) {
        return ApolloReactHooks.useQuery<RemovalReasonsQuery, RemovalReasonsQueryVariables>(RemovalReasonsDocument, baseOptions);
      }
export function useRemovalReasonsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RemovalReasonsQuery, RemovalReasonsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RemovalReasonsQuery, RemovalReasonsQueryVariables>(RemovalReasonsDocument, baseOptions);
        }
export type RemovalReasonsQueryHookResult = ReturnType<typeof useRemovalReasonsQuery>;
export type RemovalReasonsLazyQueryHookResult = ReturnType<typeof useRemovalReasonsLazyQuery>;
export type RemovalReasonsQueryResult = ApolloReactCommon.QueryResult<RemovalReasonsQuery, RemovalReasonsQueryVariables>;
export const ReferralProgrammQueryDocument = gql`
    query ReferralProgrammQuery {
  currentUser {
    id
    referralCode
    affiliateStats {
      surveysDone
      clicks
      registrations
      profitOverTime {
        value
      }
    }
  }
}
    `;
export type ReferralProgrammQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<ReferralProgrammQueryQuery, ReferralProgrammQueryQueryVariables> & TChildProps;
export function withReferralProgrammQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReferralProgrammQueryQuery,
  ReferralProgrammQueryQueryVariables,
  ReferralProgrammQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ReferralProgrammQueryQuery, ReferralProgrammQueryQueryVariables, ReferralProgrammQueryProps<TChildProps>>(ReferralProgrammQueryDocument, {
      alias: 'referralProgrammQuery',
      ...operationOptions
    });
};

/**
 * __useReferralProgrammQueryQuery__
 *
 * To run a query within a React component, call `useReferralProgrammQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralProgrammQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralProgrammQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralProgrammQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReferralProgrammQueryQuery, ReferralProgrammQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<ReferralProgrammQueryQuery, ReferralProgrammQueryQueryVariables>(ReferralProgrammQueryDocument, baseOptions);
      }
export function useReferralProgrammQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReferralProgrammQueryQuery, ReferralProgrammQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReferralProgrammQueryQuery, ReferralProgrammQueryQueryVariables>(ReferralProgrammQueryDocument, baseOptions);
        }
export type ReferralProgrammQueryQueryHookResult = ReturnType<typeof useReferralProgrammQueryQuery>;
export type ReferralProgrammQueryLazyQueryHookResult = ReturnType<typeof useReferralProgrammQueryLazyQuery>;
export type ReferralProgrammQueryQueryResult = ApolloReactCommon.QueryResult<ReferralProgrammQueryQuery, ReferralProgrammQueryQueryVariables>;
export const GetTermDocument = gql`
    query GetTerm($id: Float!) {
  termById(id: $id) {
    id
    header
    subheader
  }
}
    `;
export type GetTermProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTermQuery, GetTermQueryVariables> & TChildProps;
export function withGetTerm<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTermQuery,
  GetTermQueryVariables,
  GetTermProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTermQuery, GetTermQueryVariables, GetTermProps<TChildProps>>(GetTermDocument, {
      alias: 'getTerm',
      ...operationOptions
    });
};

/**
 * __useGetTermQuery__
 *
 * To run a query within a React component, call `useGetTermQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTermQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTermQuery, GetTermQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTermQuery, GetTermQueryVariables>(GetTermDocument, baseOptions);
      }
export function useGetTermLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTermQuery, GetTermQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTermQuery, GetTermQueryVariables>(GetTermDocument, baseOptions);
        }
export type GetTermQueryHookResult = ReturnType<typeof useGetTermQuery>;
export type GetTermLazyQueryHookResult = ReturnType<typeof useGetTermLazyQuery>;
export type GetTermQueryResult = ApolloReactCommon.QueryResult<GetTermQuery, GetTermQueryVariables>;
export const GetTaskDocument = gql`
    query GetTask($userId: String!) {
  task(userId: $userId) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export type GetTaskProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTaskQuery, GetTaskQueryVariables> & TChildProps;
export function withGetTask<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTaskQuery,
  GetTaskQueryVariables,
  GetTaskProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTaskQuery, GetTaskQueryVariables, GetTaskProps<TChildProps>>(GetTaskDocument, {
      alias: 'getTask',
      ...operationOptions
    });
};

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetTaskQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, baseOptions);
      }
export function useGetTaskLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, baseOptions);
        }
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = ApolloReactCommon.QueryResult<GetTaskQuery, GetTaskQueryVariables>;
export const TransactionHistoryDocument = gql`
    query TransactionHistory($transactionReportsAfter: String) {
  currentUser {
    id
    transactionReports(after: $transactionReportsAfter) {
      id
      transactionTime
      amount
      operationName
      status
      type
      transaction {
        id
        credited
        isRevertedReport
      }
    }
  }
}
    `;
export type TransactionHistoryProps<TChildProps = {}> = ApolloReactHoc.DataProps<TransactionHistoryQuery, TransactionHistoryQueryVariables> & TChildProps;
export function withTransactionHistory<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TransactionHistoryQuery,
  TransactionHistoryQueryVariables,
  TransactionHistoryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, TransactionHistoryQuery, TransactionHistoryQueryVariables, TransactionHistoryProps<TChildProps>>(TransactionHistoryDocument, {
      alias: 'transactionHistory',
      ...operationOptions
    });
};

/**
 * __useTransactionHistoryQuery__
 *
 * To run a query within a React component, call `useTransactionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionHistoryQuery({
 *   variables: {
 *      transactionReportsAfter: // value for 'transactionReportsAfter'
 *   },
 * });
 */
export function useTransactionHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransactionHistoryQuery, TransactionHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<TransactionHistoryQuery, TransactionHistoryQueryVariables>(TransactionHistoryDocument, baseOptions);
      }
export function useTransactionHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransactionHistoryQuery, TransactionHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TransactionHistoryQuery, TransactionHistoryQueryVariables>(TransactionHistoryDocument, baseOptions);
        }
export type TransactionHistoryQueryHookResult = ReturnType<typeof useTransactionHistoryQuery>;
export type TransactionHistoryLazyQueryHookResult = ReturnType<typeof useTransactionHistoryLazyQuery>;
export type TransactionHistoryQueryResult = ApolloReactCommon.QueryResult<TransactionHistoryQuery, TransactionHistoryQueryVariables>;
export const TransactionStatsDocument = gql`
    query TransactionStats {
  currentUser {
    id
    transactionStats {
      total
      totalAmount {
        currencyType {
          type
        }
        value
      }
      totalApproved
    }
  }
}
    `;
export type TransactionStatsProps<TChildProps = {}> = ApolloReactHoc.DataProps<TransactionStatsQuery, TransactionStatsQueryVariables> & TChildProps;
export function withTransactionStats<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TransactionStatsQuery,
  TransactionStatsQueryVariables,
  TransactionStatsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, TransactionStatsQuery, TransactionStatsQueryVariables, TransactionStatsProps<TChildProps>>(TransactionStatsDocument, {
      alias: 'transactionStats',
      ...operationOptions
    });
};

/**
 * __useTransactionStatsQuery__
 *
 * To run a query within a React component, call `useTransactionStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransactionStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransactionStatsQuery, TransactionStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<TransactionStatsQuery, TransactionStatsQueryVariables>(TransactionStatsDocument, baseOptions);
      }
export function useTransactionStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransactionStatsQuery, TransactionStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TransactionStatsQuery, TransactionStatsQueryVariables>(TransactionStatsDocument, baseOptions);
        }
export type TransactionStatsQueryHookResult = ReturnType<typeof useTransactionStatsQuery>;
export type TransactionStatsLazyQueryHookResult = ReturnType<typeof useTransactionStatsLazyQuery>;
export type TransactionStatsQueryResult = ApolloReactCommon.QueryResult<TransactionStatsQuery, TransactionStatsQueryVariables>;
export const OnAppUpdatedDocument = gql`
    subscription OnAppUpdated {
  onAppUpdated {
    errorCode
    error {
      message
    }
    isSuccess
  }
}
    `;
export type OnAppUpdatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnAppUpdatedSubscription, OnAppUpdatedSubscriptionVariables> & TChildProps;
export function withOnAppUpdated<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnAppUpdatedSubscription,
  OnAppUpdatedSubscriptionVariables,
  OnAppUpdatedProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnAppUpdatedSubscription, OnAppUpdatedSubscriptionVariables, OnAppUpdatedProps<TChildProps>>(OnAppUpdatedDocument, {
      alias: 'onAppUpdated',
      ...operationOptions
    });
};

/**
 * __useOnAppUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnAppUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAppUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAppUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAppUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnAppUpdatedSubscription, OnAppUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnAppUpdatedSubscription, OnAppUpdatedSubscriptionVariables>(OnAppUpdatedDocument, baseOptions);
      }
export type OnAppUpdatedSubscriptionHookResult = ReturnType<typeof useOnAppUpdatedSubscription>;
export type OnAppUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnAppUpdatedSubscription>;
export const OnAccountUpdatedDocument = gql`
    subscription OnAccountUpdated {
  onAccountUpdated {
    ...UserAccount
  }
}
    ${UserAccountFragmentDoc}`;
export type OnAccountUpdatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnAccountUpdatedSubscription, OnAccountUpdatedSubscriptionVariables> & TChildProps;
export function withOnAccountUpdated<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnAccountUpdatedSubscription,
  OnAccountUpdatedSubscriptionVariables,
  OnAccountUpdatedProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnAccountUpdatedSubscription, OnAccountUpdatedSubscriptionVariables, OnAccountUpdatedProps<TChildProps>>(OnAccountUpdatedDocument, {
      alias: 'onAccountUpdated',
      ...operationOptions
    });
};

/**
 * __useOnAccountUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnAccountUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAccountUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAccountUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAccountUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnAccountUpdatedSubscription, OnAccountUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnAccountUpdatedSubscription, OnAccountUpdatedSubscriptionVariables>(OnAccountUpdatedDocument, baseOptions);
      }
export type OnAccountUpdatedSubscriptionHookResult = ReturnType<typeof useOnAccountUpdatedSubscription>;
export type OnAccountUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnAccountUpdatedSubscription>;
export const OnNewNotificationDocument = gql`
    subscription OnNewNotification {
  onNewNotification {
    id
    name {
      message
    }
    value {
      message
    }
    details
    red
    type
  }
}
    `;
export type OnNewNotificationProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnNewNotificationSubscription, OnNewNotificationSubscriptionVariables> & TChildProps;
export function withOnNewNotification<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnNewNotificationSubscription,
  OnNewNotificationSubscriptionVariables,
  OnNewNotificationProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnNewNotificationSubscription, OnNewNotificationSubscriptionVariables, OnNewNotificationProps<TChildProps>>(OnNewNotificationDocument, {
      alias: 'onNewNotification',
      ...operationOptions
    });
};

/**
 * __useOnNewNotificationSubscription__
 *
 * To run a query within a React component, call `useOnNewNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnNewNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNewNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnNewNotificationSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnNewNotificationSubscription, OnNewNotificationSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnNewNotificationSubscription, OnNewNotificationSubscriptionVariables>(OnNewNotificationDocument, baseOptions);
      }
export type OnNewNotificationSubscriptionHookResult = ReturnType<typeof useOnNewNotificationSubscription>;
export type OnNewNotificationSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnNewNotificationSubscription>;
export const OnAnswerUpdatedDocument = gql`
    subscription OnAnswerUpdated {
  onAnswerUpdated {
    question {
      id
    }
    answer {
      id
    }
    answers {
      id
    }
    text
    date
  }
}
    `;
export type OnAnswerUpdatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnAnswerUpdatedSubscription, OnAnswerUpdatedSubscriptionVariables> & TChildProps;
export function withOnAnswerUpdated<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnAnswerUpdatedSubscription,
  OnAnswerUpdatedSubscriptionVariables,
  OnAnswerUpdatedProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnAnswerUpdatedSubscription, OnAnswerUpdatedSubscriptionVariables, OnAnswerUpdatedProps<TChildProps>>(OnAnswerUpdatedDocument, {
      alias: 'onAnswerUpdated',
      ...operationOptions
    });
};

/**
 * __useOnAnswerUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnAnswerUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnswerUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnswerUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnswerUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnAnswerUpdatedSubscription, OnAnswerUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnAnswerUpdatedSubscription, OnAnswerUpdatedSubscriptionVariables>(OnAnswerUpdatedDocument, baseOptions);
      }
export type OnAnswerUpdatedSubscriptionHookResult = ReturnType<typeof useOnAnswerUpdatedSubscription>;
export type OnAnswerUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnAnswerUpdatedSubscription>;
export const OnReportUpdatedDocument = gql`
    subscription OnReportUpdated {
  onReportUpdated {
    id
    type
    outerOperationName {
      message
    }
    createdAt
    updatedAt
    amount
    status
    rejectionReason
  }
}
    `;
export type OnReportUpdatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnReportUpdatedSubscription, OnReportUpdatedSubscriptionVariables> & TChildProps;
export function withOnReportUpdated<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnReportUpdatedSubscription,
  OnReportUpdatedSubscriptionVariables,
  OnReportUpdatedProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnReportUpdatedSubscription, OnReportUpdatedSubscriptionVariables, OnReportUpdatedProps<TChildProps>>(OnReportUpdatedDocument, {
      alias: 'onReportUpdated',
      ...operationOptions
    });
};

/**
 * __useOnReportUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnReportUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnReportUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnReportUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnReportUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnReportUpdatedSubscription, OnReportUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnReportUpdatedSubscription, OnReportUpdatedSubscriptionVariables>(OnReportUpdatedDocument, baseOptions);
      }
export type OnReportUpdatedSubscriptionHookResult = ReturnType<typeof useOnReportUpdatedSubscription>;
export type OnReportUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnReportUpdatedSubscription>;
export const OnGivenAnswerUpdatedDocument = gql`
    subscription OnGivenAnswerUpdated {
  onAnswerUpdated {
    ...GivenAnswerFieldsSub
  }
}
    ${GivenAnswerFieldsSubFragmentDoc}`;
export type OnGivenAnswerUpdatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnGivenAnswerUpdatedSubscription, OnGivenAnswerUpdatedSubscriptionVariables> & TChildProps;
export function withOnGivenAnswerUpdated<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnGivenAnswerUpdatedSubscription,
  OnGivenAnswerUpdatedSubscriptionVariables,
  OnGivenAnswerUpdatedProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnGivenAnswerUpdatedSubscription, OnGivenAnswerUpdatedSubscriptionVariables, OnGivenAnswerUpdatedProps<TChildProps>>(OnGivenAnswerUpdatedDocument, {
      alias: 'onGivenAnswerUpdated',
      ...operationOptions
    });
};

/**
 * __useOnGivenAnswerUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnGivenAnswerUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnGivenAnswerUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnGivenAnswerUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnGivenAnswerUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnGivenAnswerUpdatedSubscription, OnGivenAnswerUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnGivenAnswerUpdatedSubscription, OnGivenAnswerUpdatedSubscriptionVariables>(OnGivenAnswerUpdatedDocument, baseOptions);
      }
export type OnGivenAnswerUpdatedSubscriptionHookResult = ReturnType<typeof useOnGivenAnswerUpdatedSubscription>;
export type OnGivenAnswerUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnGivenAnswerUpdatedSubscription>;
export const OnUserUpdatedDocument = gql`
    subscription OnUserUpdated {
  onUserUpdated {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export type OnUserUpdatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnUserUpdatedSubscription, OnUserUpdatedSubscriptionVariables> & TChildProps;
export function withOnUserUpdated<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnUserUpdatedSubscription,
  OnUserUpdatedSubscriptionVariables,
  OnUserUpdatedProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnUserUpdatedSubscription, OnUserUpdatedSubscriptionVariables, OnUserUpdatedProps<TChildProps>>(OnUserUpdatedDocument, {
      alias: 'onUserUpdated',
      ...operationOptions
    });
};

/**
 * __useOnUserUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnUserUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUserUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUserUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnUserUpdatedSubscription, OnUserUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnUserUpdatedSubscription, OnUserUpdatedSubscriptionVariables>(OnUserUpdatedDocument, baseOptions);
      }
export type OnUserUpdatedSubscriptionHookResult = ReturnType<typeof useOnUserUpdatedSubscription>;
export type OnUserUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnUserUpdatedSubscription>;