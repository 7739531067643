import { useCurrentUserQueryQuery } from 'queries/queries';
import { useEffect, useRef } from 'react';
import { pushDataLayer } from '../tracking/gtm';
import useTokenManager from './useTokenManager';

export default function useUser() {
  const { token } = useTokenManager();

  const initialRef = useRef<boolean>(false);
  const resp = useCurrentUserQueryQuery({
    skip: token === null,
  });

  useEffect(() => {
    if (resp?.data?.currentUser) {
      pushDataLayer('customEvent', {
        user: resp.data.currentUser,
      });
    }
  }, [resp.data?.currentUser?.id]);
  const updateUserState = (newState) => {
    if (resp && typeof resp.updateQuery === 'function') {
      try {
        resp.updateQuery((previousQueryResult) => ({
          ...previousQueryResult,
          currentUser: {
            ...previousQueryResult.currentUser,
            ...newState,
          },
        }));
      } catch (e) {
        // IDK why it doesn't work sometimes, but result gets updated by subscription most time
      }
    }
  };

  useEffect(() => {
    if (initialRef.current) {
      resp.refetch();
    } else {
      initialRef.current = true;
    }
  }, [token]);

  if (token === null) {
    return null;
  }

  if (resp.loading) {
    return false;
  }

  if (resp.data && resp.data.currentUser) {
    return { ...resp.data.currentUser, updateUserState, refetch: resp.refetch };
  }

  return null;
}
