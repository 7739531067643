import styled from './styled';

export const WithSpinner = styled.div<{ show: boolean }>`
  @keyframes spinner {
    to { transform: rotate(360deg); }
  }
  
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: ${({ show }) => (show ? '50%' : '-250%')};
    left: -25px;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: -0.625rem;
    margin-left: -0.625rem;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, .25);
    border-top-color: white;
    transition: 0.2s top cubic-bezier(0.165, 0.84, 0.44, 1);
    animation: spinner .6s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
  }
`;
