// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AiHFm{background:#fff;background:var(--white-background);border:1px solid #F3F1EE;border:1px solid var(--task-border-color);box-sizing:border-box;box-shadow:1px 1px 0.9375rem rgba(0, 0, 0, 0.03);box-shadow:var(--box-shadow);border-radius:2px;padding:16px 18px;display:flex;flex-direction:column}@media (max-width: 767.98px){.AiHFm{box-shadow:none}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AiHFm`
};
export default ___CSS_LOADER_EXPORT___;
