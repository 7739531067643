import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { RetryLink } from 'apollo-link-retry';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { getMainDefinition } from 'apollo-utilities';
import { OperationDefinitionNode } from 'graphql';
import { onceTokenUpdated, getToken } from 'utils/token';
import { getSessionId } from '../tracking/middleware';
import { WebSocketDynamicLink } from './WebSocketDynamicLink';

declare global {
  interface Window { yt: any; }
}

if (!process.env.BROWSER) {
  console.warn('Do not include apollo client on server');
}

function getFromContext() {
  if (typeof window !== 'undefined' && (window as any).yt) {
    return (window as any).yt.backendUrl;
  }

  return null;
}

function getEventsUrlFromContext() {
  if (typeof window !== 'undefined' && (window as any).yt) {
    return (window as any).yt.eventsUrl;
  }

  return null;
}

const httpParams = {
  uri: getFromContext() || process.env.GRAPHQL_ENDPOINT || `${process.env.BACKEND_URL}`,
};

const httpLink = new HttpLink(httpParams);

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 2,
    retryIf: (error: any) => !!error,
  },
});

const errorLink = onError((error: any) => {
  const { graphQLErrors, networkError, ...props } = error;
  if (graphQLErrors) {
    graphQLErrors.forEach(
      ({
        message,
        locations,
        path,
      }: {
        message: string;
        locations: string;
        path: string;
      }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          props,
        );
        if (message === 'notAuthenticated') {
          window.location.reload();
        }
      },
    );
  }
  if (networkError) {
    console.error('[Network error]:', props);
    console.error(networkError, {
      body: networkError.bodyText,
      status: networkError.statusCode,
    });
  }
});

const tryToGetLocale = () => (window.yt && window.yt.locale ? window.yt.locale : null);

const authLink = setContext(async (_, { headers }) => {
  // get the authorization token if exists
  const token = getToken();
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
      'x-session-id': await getSessionId(),
      'x-locale': tryToGetLocale() || navigator.language.slice(0, 2),
    },
  };
});

const yt = typeof window !== 'undefined' ? (window as any).yt : {};
const cache = new InMemoryCache().restore(
  yt.apolloState ? yt.apolloState : null,
);

const wsEndpoint = getEventsUrlFromContext() || process.env.WS_ENDPOINT || httpParams.uri;

let client: SubscriptionClient | null = null;

const initializeSubscriptionClient = () => {
  if (getToken()) {
    client = new SubscriptionClient(wsEndpoint, {
      reconnect: true,
      timeout: 120000,
      connectionParams: () => ({
        // Not a good idea, since we can consume real react state
        authorization: `Bearer ${getToken()}`,
      }),
    });
  } else {
    client = null;
  }
};

// Because token was changed, we have to re-connect
onceTokenUpdated(() => {
  if (client) {
    client.close(false);
  }

  initializeSubscriptionClient();
});

initializeSubscriptionClient();

const wsLink = new WebSocketDynamicLink(() => client);

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(
      query,
    ) as OperationDefinitionNode;
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  authLink.concat(ApolloLink.from([retryLink, errorLink, httpLink])),
);

const apolloClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: __DEV__,
});

export { apolloClient };

export default function createApolloClient() {
  return apolloClient;
}
