import { useCallback, useContext } from 'react';
import { ActionTypes, NotificationStore, NotificationType } from 'store/notifications';

const ANIMATION_TIMEOUT = 200;

// todo: we should create dedicated managers for where we show and where we add.
// Otherwise it would trigger re-renders which are not needed
export default function useNotificationsManager() {
  const { state, dispatch } = useContext(NotificationStore);

  const forceRemoveNotification = useCallback(notification => {
    dispatch({
      type: ActionTypes.REMOVE,
      payload: notification,
    });
  }, []);

  // Remove allows to play all animation whilst force removes without any waiting.
  const removeNotification = useCallback(notification => {
    if (notification.isClosing) {
      return;
    }

    dispatch({
      type: ActionTypes.UPDATE,
      payload: { ...notification, isClosing: true },
    });

    setTimeout(() => forceRemoveNotification(notification), ANIMATION_TIMEOUT);
  }, []);

  const addNotification = useCallback(
    (
      text,
      type: NotificationType = NotificationType.Success,
      duration = 3000,
      buttons: any[] = [],
    ) => {
      const notification: any = {
        id: +new Date(),
        isClosing: false,
        text,
        type,
        buttons,
        duration,
      };

      // Helper to make controlled notification
      notification.close = () => removeNotification(notification);

      dispatch({
        type: ActionTypes.ADD,
        payload: notification,
      });

      return notification;
    },
    [],
  );

  const updateNotification = useCallback(notification => {
    dispatch({
      type: ActionTypes.UPDATE,
      payload: notification,
    });
  }, []);

  return {
    notifications: state, addNotification, removeNotification, updateNotification,
  };
}
