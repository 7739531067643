// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HnF0n{position:relative}.HnF0n,.bSLhQ{display:inline-block}.bSLhQ{font-size:0.9rem;font-size:var(--small-size);font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;font-family:var(--system-font);background:#262627;background:var(--darken-grey-color);color:#EEF2F7;color:var(--white-color);padding:10px 15px;box-shadow:1px 1px 0.9375rem rgba(0, 0, 0, 0.03);box-shadow:var(--box-shadow);border-radius:5px;max-width:248px;font-weight:600;width:100%;width:-moz-fit-content;width:fit-content;box-sizing:border-box;line-height:1.3;z-index:-1;top:0;right:50%;transition:transform .15s ease-in-out, opacity .1s ease-in-out;opacity:0;transform:var(--extra-transform) translateY(45px)}.bSLhQ,.bSLhQ:after{position:absolute;left:50%}.bSLhQ:after{content:"";bottom:100%;width:0;height:0;border-left:8px solid transparent;border-bottom:8px solid #262627;border-bottom:8px solid var(--tooltip-background,var(--darken-grey-color));border-right:8px solid transparent;transform:translateX(var(--x-after-padding))}.YjtIZ,.HqlgM,._7Otj{transform:var(--extra-transform) translateY(30px)}.YjtIZ,.HqlgM{opacity:1;z-index:1}.YjtIZ{transition:none}.sdsSC{z-index:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HnF0n`,
	"tooltip": `bSLhQ`,
	"state-entered": `YjtIZ`,
	"state-entering": `HqlgM`,
	"state-exited": `_7Otj`,
	"state-exiting": `sdsSC`
};
export default ___CSS_LOADER_EXPORT___;
