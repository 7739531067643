import React from 'react';
import styled from 'ui/styled';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg<{ checked: boolean }>`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  visibility: ${props => (props.checked ? 'visible' : 'hidden')}
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  
  &:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 22px;
  height: 22px;
  background: ${({ checked, theme }) => (checked ? theme.primary.middle : 'transparent')};
  border: 1px solid ${({ theme }) => theme.primary.middle};
  border-radius: 3px;
  transition: all 150ms ease-out;
`;

const Checkbox = ({ className, checked, ...props }: any) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox type="checkbox" checked={checked} {...props} value="" />
    <StyledCheckbox checked={checked}>
      <Icon checked={checked} viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
